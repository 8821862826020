import axios from 'axios'
import dayjs from 'dayjs'
import { makeQueryString } from '@/services/utility/query-parameters.utility'

export default {
  GetStoreByCode ({ apiRoot, code }) {
    return axios.get(`${apiRoot}/api/v1/store/${code}`)
  },

  GetStoreByName ({ apiRoot, name }) {
    return axios.get(`${apiRoot}/api/v1/store/name?name=${name}`)
  },

  GetStoresByPostcode ({ apiRoot, payload }) {
    const query = `?postcode=${payload.searchQuery}&includeTradingException=${payload.includeTradingException}`

    return axios.get(`${apiRoot}/api/v1/store/schedule/postcode${query}`)
  },

  GetStoresBySuburbName ({ apiRoot, payload }) {
    const query = `?search=${payload.searchQuery || ''}&includeTradingException=${payload.includeTradingException || ''}&suburb=${payload.suburb || ''}&postcode=${payload.postcode || ''}&state=${payload.state || ''}`

    return axios.get(`${apiRoot}/api/v1/store/schedule/locate${query}`)
  },

  GetStoresByGeolocation ({ apiRoot, payload }) {
    let query
    if (payload.radius) {
      query = `?Longitude=${payload.longitude}&Latitude=${payload.latitude}&isPickup=${payload.isPickup}&radius=${payload.radius}`
    } else {
      query = `?Longitude=${payload.longitude}&Latitude=${payload.latitude}&isPickup=${payload.isPickup}`
    }

    return axios.get(`${apiRoot}/api/v1/store/geolocation${query}`)
  },

  GetAddressesByQuery ({ apiRoot, query }) {
    const payload = `<?xml version="1.0" encoding="UTF-8"?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header><ond:QAQueryHeader /></soapenv:Header><soapenv:Body><ond:QASearch><ond:Country>AUG</ond:Country><ond:Engine Flatten="true" Intensity="Close" PromptSet="Default" Threshold="25" Timeout="10000">Intuitive</ond:Engine><ond:Layout></ond:Layout><ond:Search>${query}</ond:Search></ond:QASearch></soapenv:Body></soapenv:Envelope>`

    return axios.post(apiRoot, payload, {
      headers: {
        'Content-type': 'text/xml',
        'Auth-Token': '36dc4498-bc31-4e15-ae19-cbbbc3ad5d47', // @TODO Update with environment specific token from discover api once present
        SoapAction: 'http://www.qas.com/OnDemand-2011-03/DoSearch'
      }
    })
  },

  GetAddressByMoniker ({ apiRoot, moniker }) {
    const payload = `<?xml version="1.0" encoding="UTF-8"?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header><ond:QAQueryHeader xmlns="http://www.qas.com/OnDemand-2011-03"></ond:QAQueryHeader></soapenv:Header><soapenv:Body><QAGetAddress Threshold="50" xmlns="http://www.qas.com/OnDemand-2011-03"><Moniker>${moniker}</Moniker><Layout>APAC PizzaHut AUG</Layout></QAGetAddress></soapenv:Body></soapenv:Envelope>`

    return axios.post(apiRoot, payload, {
      headers: {
        'Content-type': 'text/xml',
        'Auth-Token': '36dc4498-bc31-4e15-ae19-cbbbc3ad5d47', // @TODO Update with environment specific token from discover api once present
        SoapAction: 'http://www.qas.com/OnDemand-2011-03/DoGetAddress'
      }
    })
  },

  SubmitContactForm ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/storeform/contact`, payload)
  },

  SubmitFranchiseeForm ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/storeform/franchise`, payload)
  },

  SubmitCareersForm ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/storeform/careers`, payload)
  },

  // @TODO Deprecate this and use GetStoreByCode instead
  getStoreByCode ({ apiRoot, storeCode }) {
    return axios.get(`${apiRoot}/api/v1/store/${storeCode}`)
  },
  getStoreByName ({ apiRoot, storeName }) {
    return axios.get(`${apiRoot}/api/v1/store/name?name=${storeName}`)
  },
  GetStoreList ({ apiRoot, includeComingSoon }) {
    return axios.get(`${apiRoot}/api/v1/store?includeComingSoon=${includeComingSoon}`)
  },
  GetValidateGeolocation ({ apiRoot, payload }) {
    const orderDueAt = dayjs(payload.orderDueAt).utcOffset(payload.orderDueAt).format('YYYY-MM-DDTHH:mm')
    const params = makeQueryString({
      ...payload,
      orderDueAt: encodeURIComponent(orderDueAt)
    })
    return axios.get(`${apiRoot}/api/v1/store/${payload.storeId}/validate/geolocation?${params}`)
  }
  // // OLD API
  // getTradingStoresStates ({ store }) {
  //   const endpoint = `${store.getters['discover/uris'].STORE_URI}/api/services/app/store/GetTradingStoresStates`

  //   return axios.post(endpoint)
  // },
  // GetNearbyStoresWithGeoLocation ({ store, params }) {
  //   const endpoint = `${store.getters['discover/uris'].API_WEB}/api/services/app/store/GetNearbyStoresWithGeoLocation`

  //   return axios.post(endpoint, params)
  // },
  // GetStoreSchedule ({ store, params }) {
  //   const endpoint = `${store.getters['discover/uris'].API_WEB}/api/services/app/store/GetStoreSchedule`

  //   return axios.post(endpoint, params)
  // },
}
