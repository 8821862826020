import { curry } from '@/services/utility/fp.utility'
/**
 * Coerce a value (including `undefined`) into a Number
 *
 * @param {number|*} value - value to convert
 * @return {number|NaN} - return a number or `NaN` if value truly cannot be represented as a number (e.g. "abc")
 * */
export const safeNumber = value => value === undefined ? 0 : Number(value)

/**
 * Check if a number is between a min and max value
 *
 * @func
 * @curried
 * @param {Number} min - minimum value
 * @param {Number} max - maximum value
 * @param {Numbe} num - value to be checked
 * @return {Boolean}
 * */
export const between = curry((min, max, num) => {
  num = safeNumber(num)
  return num > min && num < max
})

/**
 * Add two numbers over time
 *
 * @func
 * @curried
 * @param {Number}
 * @param {Number}
 * @return {Number}
 *
 * @example
 * const add1 = add(1) // fn
 * const sum = add1(2) // -> 3
 * */
export const add = curry((a, b) => a + b)
