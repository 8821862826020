import { Capacitor } from '@capacitor/core'
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics'
import * as StackTrace from 'stacktrace-js'
import { useUserStore } from '@/stores/user'
import { useCartStore } from '@/stores/cart'

export const crashlyticsForceCrash = async function (
  message: string = 'Force Crash from crashlytics utility'
): Promise<void> {
  if (!Capacitor.isNativePlatform()) return
  await FirebaseCrashlytics.crash({ message })
}

export const setCrashlyticsUserId = async function (userId: string | undefined): Promise<void> {
  if (!Capacitor.isNativePlatform() || !userId) return
  await FirebaseCrashlytics.setUserId({ userId })
}

export const setCrashlyticsCustomKey = async function (
  key: string,
  value: string | boolean | number,
  type: 'string' | 'boolean' | 'long' | 'double' | 'int' | 'float' = 'string'
): Promise<void> {
  if (!Capacitor.isNativePlatform() || !key || value === undefined) return
  await FirebaseCrashlytics.setCustomKey({
    key,
    value,
    type
  })
}

export const setCrashlyticsLogMessage = async function (message: string): Promise<void> {
  if (!Capacitor.isNativePlatform() || !message) return
  await FirebaseCrashlytics.log({
    message
  })
}

export const crashlyticsRecordExceptionMessage = async function (message: string): Promise<void> {
  if (!Capacitor.isNativePlatform() || !message) return
  await FirebaseCrashlytics.recordException({
    message
  })
}

export const crashlyticsRecordExceptionWithStacktrace = async function (
  error: Error,
  message: string
): Promise<void> {
  if (!Capacitor.isNativePlatform() || !error || !message) return

  const stacktrace = await StackTrace.fromError(error)
  await FirebaseCrashlytics.recordException({
    message,
    stacktrace
  })
}

export const setCrashlyticsUserIdAndBasketId = async function (): Promise<void> {
  const { userId } = useUserStore()
  const { basketId } = useCartStore()

  if (userId) {
    await setCrashlyticsUserId(userId)
  }
  if (basketId) {
    await setCrashlyticsCustomKey('basketId', basketId)
  }
}

export const enableCrashlytics = async () => {
  if (!Capacitor.isNativePlatform()) return
  await FirebaseCrashlytics.setEnabled({
    enabled: true
  })
}
