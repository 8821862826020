// import store from '@/store/'
import { addBreadcrumb } from '@/services/utility/sentry.utility.js'

import { useUiStore } from '@/stores/ui'

export const UiController = {
  showAlert: function (options) {
    addBreadcrumb({
      category: 'UI',
      message: 'Show Alert',
      data: options
    })

    useUiStore().triggerAlert(options)
  },
  hideAlert: function () {
    addBreadcrumb({
      category: 'UI',
      message: 'Hide Alert'
    })

    useUiStore().deactivateAlert()
  },
  showSnackbar: function (options) {
    addBreadcrumb({
      category: 'UI',
      message: 'Show Snackbar',
      data: options
    })

    useUiStore().triggerSnackbar(options)
  },
  showSpinner: function (options) {
    addBreadcrumb({
      category: 'UI',
      message: 'Show Spinner',
      data: options
    })

    if (options === undefined) {
      useUiStore().triggerSpinner({
        loading: true
      })
    } else {
      if (options.loading === undefined) {
        options.loading = true
      }

      useUiStore().triggerSpinner(options)
    }
  },
  showMinimumSpendModal: function (options) {
    addBreadcrumb({
      category: 'UI',
      message: 'Show Minimum Spend Modal',
      data: options
    })

    useUiStore().triggerMinimumSpendModal(options)
  }
}
