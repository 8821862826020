<template>
  <div>
    <div v-if="!connection" class="no-connection">
      <div class="text-center">
        <font-awesome-icon :icon="['far', 'wifi-slash']" />
        <h3>Check your connection and try again.</h3>
        <button class="btn btn-primary" @click="retry()">Retry</button>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import { metaDefaults } from '@/services/helpers/meta.helper'
import { isCapacitorNativePlatform } from '@/services/utility/capacitor.utility.js'

export default {
  name: 'FatalRoot',
    props: {
    failure: {
      type: Error,
      default: null
    }
  },
  data () {
    return {
      connection: true
    }
  },
  head () {
    return {
      ...metaDefaults
    }
  },
  created () {
    this.logError()
    this.destroyAppNode()

    if (isCapacitorNativePlatform()) {
      this.connection = window.navigator.onLine
    }
  },
  methods: {
    logError () {
      // @TODO Log failure to GA properly when GA is hooked with the app.
      console.log(`%c ${this.failure}`, 'color: red; font-weight: bold;')
    },
    destroyAppNode () {
      if (document.getElementById('app-root')) {
        document.getElementById('app-root').remove()
      }
    },
    retry () {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.no-connection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .fa-wifi-slash {
    font-size: 140px;
  }

  h3 {
    margin: 20px 0;
  }
}
</style>
