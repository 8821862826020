export const toProduct = (product, purchaseMethod = 'main', totalPrice) => ({
  id: product.slug,
  name: product.name,
  purchaseMethod,
  category: product.productType.toLowerCase(),
  brand: product.taxonomy,
  value: toValue(totalPrice || product.totalPrice)
})

export const toVariant = (variant, product, totalPrice) => {
  const type = variant.sizeName ? 'size-crust' : variant.productType
  return {
    id: type === 'size-crust' ? `${product.slug}-${variant.size}-${variant.productId}` : variant.slug || variant.productId,
    name: type === 'size-crust' ? `${product.name} ${variant.sizeName} ${variant.name}` : variant.name,
    type,
    value: toValue(totalPrice || variant.totalPrice)
  }
}

export const toProductVariant = product => {
  if (product.sizes?.length && product.bases?.length) {
    const crustSize = {
      size: product.sizes[0].sizeId,
      sizeName: product.sizes[0].name,
      productId: product.bases[0].productId,
      name: product.bases[0].name
    }
    return toVariant(crustSize, product)
  }
}

export const toBundleItems = (offerItems = [], quantity = 1) => {
  return offerItems.map(offerItem => {
    const basketItem = offerItem.fulfilledProduct
    const product = offerItem.products.find(p => basketItem.productId === p.productId)
    const totalPrice = basketItem.basketItemParts.reduce((total, item) => total + item.totalPrice, basketItem.surcharge?.price || 0)
    return {
      ...toProduct(product, 'deal', totalPrice),
      quantity: basketItem.quantity * quantity,
      variants: toVariants({ basketItem })
    }
  })
}

export const basketItemsToBundleItems = (basketItems = [], quantity = 1) => {
  return basketItems.map(basketItem => ({
    ...toProduct(basketItem, 'deal'),
    quantity: basketItem.quantity * quantity,
    variants: toVariants({ basketItem })
  }))
}

export const toVariants = ({ basketItem, product }) => {
  if (basketItem) {
    const surchargeVariant = basketItem.surcharge ? { id: basketItem.surcharge.productId, type: 'Surcharge', name: basketItem.surcharge.name } : null
    return basketItem.basketItemParts.reduce((variants, part) => [...variants, toVariant(part, basketItem)], surchargeVariant ? [surchargeVariant] : [])
  } else if (product) {
    const productVariant = toProductVariant(product)
    return productVariant ? [productVariant] : []
  }
  return []
}

export const toValue = (total = 0, tax = 0) => ({
  displayGross: total,
  displayTax: tax
})

export const toTax = () => ({
  type: 'percentage',
  name: 'GST',
  size: 0
})

export const toCartLines = totalBasketItems => {
  return totalBasketItems.map(item => ({
    id: item.groupedBasketItemId || item.basketItemId,
    product: {
      ...toProduct(item),
      ...(item.basketItemParts && { variants: toVariants({ basketItem: item }) }),
      quantity: item.quantity,
      tax: toTax(),
      ...(item.basketItems && { bundleItems: basketItemsToBundleItems(item.basketItems, item.quantity) })
    }
  }))
}

export const toModule = offerItem => ({
  id: offerItem.id,
  name: offerItem.stepTopic,
  type: 'merchandising',
  placement: 'item-selection-box',
  items: offerItem.products.map((product, index) => ({ id: product.slug, type: 'product', position: index }))
})
