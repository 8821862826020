import { defineStore } from 'pinia';

export const useAttributionStore = defineStore('attribution', {
  state: () => ({
    customerInfo: {
      utmCampaign: '',
      utmMedium: '',
      utmSource: '',
      utmTerm: '',
      utmContent: '',
      referringDomain: ''
    },
    appVersion: '',
    appType: ''
  }),
  getters: {
    attributions: (state) => {
      return {
        customerInfo: state.customerInfo,
        appVersion: state.appVersion,
        appType: state.appType
      }
    }
  },
  actions: {
    setCustomerInfoAttributions(customerInfo) {
      this.customerInfo = customerInfo;
    },
    setAppVersion(appVersion) {
      this.appVersion = appVersion;
    },
    setAppType(appType) {
      this.appType = appType;
    },
    setAttributions(attributions) {
      const { customerInfo, appVersion, appType } = attributions;
      this.setCustomerInfoAttributions(customerInfo);
      this.setAppVersion(appVersion);
      this.setAppType(appType);
    }
  }
});
