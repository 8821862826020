export const payment = {
  paymentType: 'Cash',
  reference: '',
  maskedCard: '',
  expiry: '',
  response: ''
}

export const customer = {
  emailAddress: '',
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  phoneNumber: '',
  customerId: '00000000-0000-0000-0000-000000000000',
  userId: '00000000-0000-0000-0000-000000000000'
}

export const cart = {
  basketId: null,
  createdAt: '',
  channelType: '',
  channelName: '',
  externalReference: '',
  serviceType: '',
  orderDueAt: '',
  storeInfo: {},
  customerInfo: {},
  basketItems: [],
  groupedBasketItems: [],
  serviceCharges: [],
  basketTotal: 0,
  discount: {},
  paymentInfo: payment,
  orderNote: '',
  tags: {},
  isAsap: true,
  upsells: {
    cart: {
      cart: []
    },
    upsellProduct: {},
    upsellPoster: {}
  }
}
