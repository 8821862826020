import router from '@/router/'
import { dictionary } from '@/config/dictionary.config'
import { ProductController } from '@/controllers/product.controller'
import { UiController } from '@/controllers/ui.controller'
import { TrackingController } from '@/controllers/tracking.controller'
import { UpsellController } from '@/controllers/upsell.controller'
import { CacheController } from '@/controllers/cache.controller'
import { ContentController } from '@/controllers/content.controller'
import { isExpired } from '@/services/utility/date.utility'
import { capitalize, trimRemoveLineBreaks, removeNonNumericExceptPlus } from '@/services/utility/string.utility'
import { path, pathOr } from '@/services/utility/object.utility'
import { create as createBasket, getAddressInfo, getCustomerInfo } from '@/services/helpers/customer-info.helper'
import { setTags, sentryException } from '@/services/utility/sentry.utility.js'
import Bus from '@/services/events/event.bus'
import { GA4_EVENT, PRODUCT } from '@/config/constants'
import { toCurrency } from '@/services/utility/currency.utility.js'
import { isiOsApp } from '@/services/utility/capacitor.utility.js'
import { Braze } from '@pizzahutau/capacitor-plugin-braze'
import cloneDeep from 'lodash/cloneDeep'

import { useCacheStore } from '@/stores/cache'
import { useCartStore } from '@/stores/cart'
import { useCustomerStore } from '@/stores/customer'
import { useLocalisationStore } from '@/stores/localisation'
import { useOrderStore } from '@/stores/order'
import { useProductStore } from '@/stores/product'
import { useSidebarStore } from '@/stores/sidebar'
import { useUiStore } from '@/stores/ui'
import { useUserStore } from '@/stores/user'
import { useSurveyStore } from '@/stores/survey'
import { useTrackingStore } from '@/stores/tracking'
import { useDiscoverStore } from '@/stores/discover'

export const BasketController = {
  saveSession: function ({
    payload,
    validSession = null,
    silent = false,
    resetCache = false,
  }) {
    if (validSession === null) {
      return !useCartStore().validBasket
        ? this.createSession(payload, silent)
        : this.updateSession(payload, silent)
    } else if (validSession) {
      return this.updateSession(payload, silent, resetCache)
    } else {
      return this.createSession(payload, silent, resetCache)
    }
  },

  createSession: function (payload, silent) {
    UiController.showSpinner({
      loading: true,
    })

    return useCartStore()
      .createBasket({
        ...payload,
        serviceType: capitalize(payload.serviceType),
      })
      .then(() => this.onSessionSaveSuccess(!silent))
      .catch((error) => this.onSessionSaveError(error))
      .finally(() => {
        UiController.showSpinner({
          loading: false,
        })
      })
  },

  updateSession: function (payload, silent, resetCache) {
    return useCartStore()
      .updateBasket({
        basket: {
          ...payload,
          serviceType: capitalize(payload.serviceType),
        },
        basketId: useCartStore().basketId,
      })
      .then(() => this.onSessionSaveSuccess(!silent, resetCache))
      .catch((error) => this.onSessionSaveError(error))
  },

  clearSession: async function (silent, resetCache) {
    useCartStore().setCartErrors({})
    useLocalisationStore().setRedirectState('')
    await useCartStore()
      .clearBasket()
      .then(() => this.onSessionSaveSuccess(!silent, resetCache))
      .catch((error) => this.onSessionSaveError(error))
      .finally(() => {
        UiController.showSpinner({
          loading: false,
        })
      })
  },

  onSessionSaveSuccess: function (notifyUser) {
    useCacheStore().invalidateCache()

    if (CacheController.isCacheExpired(useCacheStore().categories.expires_at)) {
      ProductController.getCategories()
    }

    if (CacheController.isCacheExpired(useCacheStore().nav_items.expires_at)) {
      ProductController.getNavigationItems()
    }

    if (CacheController.isCacheExpired(useCacheStore().upsells.expires_at)) {
      UpsellController.updateUpsellProducts()
    }

    if (CacheController.isCacheExpired(useCacheStore().spinner.expires_at)) {
      ContentController.getSpinner()
    }

    if (CacheController.isCacheExpired(useCacheStore().splash_screen.expires_at)) {
      ContentController.getSplashScreen()
    }

    if (CacheController.isCacheExpired(useCacheStore().dictionary.expires_at)) {
      ContentController.getDictionary()
    }

    if (CacheController.isCacheExpired(useCacheStore().filter_icons.expires_at)) {
      ContentController.getFilterIcons()
    }

    Bus.$emit('getContent', {})
    useLocalisationStore().resetDatetimeSelectorState()
    if (useCartStore().validBasket) {
      useLocalisationStore().invokeLocatorSuccessCallback()
    }

    if (!useLocalisationStore().locatorEditMode) {
      useLocalisationStore().setLocatorState({
        tabIndex: +useCartStore().isPickup, // 'Unary Plus' converts bool to int
      })
    }

    if (useProductStore().voucherInFlight) {
      this.handleVoucherInFlight()
    }

    if (router.currentRoute.value.name === 'landing') {
      const redirect = useLocalisationStore().redirect
      router.replace(redirect).catch(() => {})
      useLocalisationStore().setRedirectState('')
    }

    if (useCartStore().isContactless) {
      useCartStore().setContactless(true)
    }

    if (notifyUser) {
      this.handleShowSnackbar({
        active: true,
        message: useCartStore().isDelivery
          ? ContentController.dictionaryToMessage(
              'DISPOSITION.SAVE_SUCCESS_DELIVERY',
            )
          : ContentController.dictionaryToMessage(
              'DISPOSITION.SAVE_SUCCESS_PICKUP',
            ),
        theme: 'success',
        hasCloseIcon: true,
      })
    }

    const basketMessages = useCartStore().basketMessages
    if (basketMessages?.length) {
      const code = basketMessages[0].code
      if (code === 'ChangeDisposition') {
        this.handleShowSnackbar({ active: true })
      }
    }

    // Sentry Basket Id
    setTags({
      basketId: useCartStore().basketId,
    })
  },

  onSessionSaveError: function (error) {
    console.error(error)
    switch (error?.response?.status) {
      case 406:
        UiController.showSnackbar({
          active: true,
          message: ContentController.dictionaryToMessage(
            'DISPOSITION.ONLINE_ORDER_OFF',
          ),
          theme: 'danger',
          hasCloseIcon: true,
        })
        break
      case 410:
        break
      case 412:
        break
      default:
        sentryException(error)
        UiController.showSnackbar({
          active: true,
          message: ContentController.dictionaryToMessage(
            'DISPOSITION.SAVE_ERROR',
          ),
          theme: 'danger',
          hasCloseIcon: true,
        })
        break
    }

    // @GTM DATALAYER GA4
    TrackingController.trackGA4Event(
      GA4_EVENT.LOCALISATION_ERROR,
      router.currentRoute.value,
      {
        error: {
          code: error.response?.status,
          type: 'localisation',
          message: error.response?.data?.message || error?.message,
        },
      },
    )
  },

  computeProductBasketItemParts: function (state) {
    // reduce list of basket item parts:
    // create list with crust & sauce details as starting point
    // iterate over ingredient edits list and map data to expected format
    const seed = []
    if (state.properties.IsCustomisable) {
      seed.push({
        productId: state.chosenBase,
        quantity: 1,
        size: state.chosenSize,
        category: 'Base',
        name: state.sizes
          .find((x) => x.sizeId === state.chosenSize)
          ?.bases?.find((x) => x.productId === state.chosenBase)?.name,
      })

      if (state.chosenSauce?.defaultQuantity === 0) {
        seed.push({
          productId: state.chosenSauce.productId,
          quantity: 1,
          size: 0,
          name: state.chosenSauce.name,
        })
      }
    }

    const basketItemParts = state.ingredientEdits.reduce(
      (basketItemParts, basketItemPart) => {
        basketItemParts.push({
          productId: basketItemPart.productId,
          quantity: basketItemPart.isDefaultIngredient
            ? basketItemPart.chosenQuantity - basketItemPart.defaultQuantity
            : basketItemPart.chosenQuantity,
          size: 0,
          name: basketItemPart.name,
          category: basketItemPart.ingredientCategoryCode,
        })

        return basketItemParts
      },
      seed,
    )

    return {
      productId: state.productId,
      quantity: state.chosenQuantity,
      basketItemParts,
    }
  },

  computeMealBasketItem: function (meal) {
    const { productId, quantity } = meal
    const basketItems = meal.offeredItems.map((item) => {
      return {
        ...item.fulfilledProduct,
        basketItemParts: item.fulfilledProduct.basketItemParts.map((bip) => ({
          ...bip,
          size: meal.properties.IsHalfnHalf
            ? String(bip.size).match(/H$/)
              ? String(bip.size)
              : `${bip.size}H`
            : String(bip.size),
        })),
      }
    })

    return {
      productId,
      quantity,
      basketItems,
    }
  },

  onCartApiCallError: function (error) {
    throw error
  },

  addBasketItem: async function (payload) {
    const { basketItem, product, isAnUpsellProduct } = payload
    if (product && !isAnUpsellProduct) {
      try {
        return new Promise((resolve, reject) => {
          const callback = (payload) => {
            UiController.showSpinner({ loading: true })
            useCartStore()
              .addBasketItem(payload)
              .then((res) => resolve(res))
              .catch((err) => reject(err))
              .finally(() => {
                UiController.showSpinner({ loading: false })
              })
          }
          const trigger =
            product.productType === 'Meals' ? 'adddealtocart' : 'addtocart'
          UpsellController.tryIngredientUpsell({
            product,
            trigger,
            basketItem,
            callback,
          }).then((ingredientUpsell) => {
            if (!ingredientUpsell) {
              callback(basketItem)
            } else {
              UiController.showSpinner({ loading: false })
            }
          })
        })
      } catch (err) {
        console.error(err)
        throw err
      }
    }

    return useCartStore().addBasketItem(basketItem)
  },

  onAddBasketItemSuccess: async function ({
    sizeName = '',
    product,
    isAnUpsellProduct,
    quantity,
    trackData,
  }) {
    this.handleShowSnackbar({
      active: true,
      message: dictionary.PRODUCT.ADD_TO_BASKET_SUCCESS(
        `${sizeName !== 'Standard' ? sizeName : ''} ${product.name}`,
      ).text,
      theme: 'success',
      autoCloseDuration: 5000,
    })

    if (!isAnUpsellProduct) {
      await UpsellController.tryUpsell({ product })
    }

    const basketItems = () => {
      const items = cloneDeep(useCartStore().basketItems)
      const i = items.find((item) => item.productId === product.productId)

      if (!i) {
        return []
      }

      return i.basketItemParts.map((item) => ({
        ...item,
        price: 0,
        id: item.productId,
        category: item.productType,
        brand: item.taxonomy,
      }))
    }

    const groupedBasketItems = () => {
      const cartGroupedBasketItems = cloneDeep(useCartStore().groupedBasketItems)
      const groupedBasketItems = cartGroupedBasketItems.find(
        (item) => item.productId === product.productId,
      )

      const groupedBasketItemsParts = []

      groupedBasketItems.basketItems.forEach((items) => {
        if (items.basketItemParts.length > 0) {
          items.basketItemParts.forEach((item) => {
            groupedBasketItemsParts.push(item)
          })
        }
      })

      return groupedBasketItemsParts.map((item) => ({
        ...item,
        price: 0,
        id: item.productId,
        category: item.productType,
        brand: item.taxonomy,
      }))
    }

    const getBasketItemParts = () => {
      if (typeof product.offeredItems === 'undefined') {
        return basketItems()
      } else {
        return groupedBasketItems()
      }
    }

    // @GTM DATALAYER
    TrackingController.trackLegacyEvent('addToCart', {
      ecommerce: {
        currencyCode: 'AUD',
        add: {
          products: [
            {
              id: product.productId,
              slug: product.slug,
              name: product.name,
              price: product.computedPrice,
              category: product.productType,
              brand: product.taxonomy,
              quantity,
            },
            ...getBasketItemParts(),
          ],
        },
      },
    })

    // @GTM DATALAYER GA4
    TrackingController.trackGA4Event(GA4_EVENT.ADD_TO_CART, trackData.view, {
      ecommerce: {
        items: [
          {
            item_id: product.productId,
            slug: product.slug,
            item_name: product.name,
            size: sizeName,
            coupon: trackData.voucherCode,
            currency: 'AUD',
            discount: 0,
            index: 0,
            price:
              product.computedPrice || trackData.assets.value?.displayGross,
            item_category: product.productType,
            item_list_id: trackData.list, // product list || meal
            item_list_name: trackData.list, // product list || meal
            brand: product.taxonomy,
            quantity: trackData.quantity || 1,
          },
        ],
      },
    })
  },

  onAddBasketItemError: function ({ sizeName = '', product, error }) {
    console.error(error)
    const maxAlcoholUnitCount = useCartStore().maxAlcoholUnitCount
    if (error?.response?.data?.message !== 410) {
      let message
      if (error?.response?.data?.code === 'InvalidAlcohol') {
        message = dictionary.PRODUCT.ALCOHOL.MAX(maxAlcoholUnitCount)?.text
      } else if (error?.response?.data?.code === 'StockPromoLimitReached') {
        // StockPromoLimitReached gets error message from storyblok dictionary, or local dictionary if no storyblok msg found
        message = ContentController.dictionaryToMessage('BASKET.STOCK_PROMO_LIMIT_REACHED') ?? dictionary.BASKET.STOCK_PROMO_LIMIT_REACHED
      } else {
        const defaultMessage =
          error?.response?.data?.message ||
          dictionary.PRODUCT.ADD_TO_BASKET_ERROR(
            `${sizeName !== 'Standard' ? sizeName : ''} ${product.name}`,
          ).text
        message = ContentController.codeToMessage(
          error?.response?.data?.code,
          defaultMessage,
        )
      }
      this.handleShowSnackbar({
        active: true,
        message,
        theme: 'danger',
        autoCloseDuration: 5000,
      })
    }
  },

  onUpdateBasketItemSuccess: function ({ sizeName = '', product }) {
    this.handleShowSnackbar({
      active: true,
      message: dictionary.PRODUCT.UPDATE_IN_BASKET_SUCCESS(
        `${sizeName !== 'Standard' ? sizeName : ''} ${product.name}`,
      ).text,
      theme: 'success',
      autoCloseDuration: 5000,
    })
  },

  onUpdateBasketItemError: function ({ sizeName = '', product, error }) {
    console.error(error)
    const maxAlcoholUnitCount = useCartStore().maxAlcoholUnitCount
    if (error?.response?.data?.message !== 410) {
      let message
      if (error?.response?.data?.code === 'InvalidAlcohol') {
        message = dictionary.PRODUCT.ALCOHOL.MAX(maxAlcoholUnitCount)?.text
      } else if (error?.response?.data?.code === 'StockPromoLimitReached') {
        // StockPromoLimitReached gets error message from storyblok dictionary, or local dictionary if no storyblok msg found
        message = ContentController.dictionaryToMessage('BASKET.STOCK_PROMO_LIMIT_REACHED') ?? dictionary.BASKET.STOCK_PROMO_LIMIT_REACHED
      } else {
        const defaultMessage =
          error?.response?.data?.message ||
          dictionary.PRODUCT.UPDATE_IN_BASKET_ERROR(
            `${sizeName !== 'Standard' ? sizeName : ''} ${product.name}`,
          ).text
        message = ContentController.codeToMessage(
          error?.response?.data?.code,
          defaultMessage,
        )
      }
      this.handleShowSnackbar({
        active: true,
        message,
        theme: 'danger',
        autoCloseDuration: 5000,
      })
    }
  },

  onAddBasketItemFinally: function ({ context }) {
    context.loading = false
  },

  onDeleteBasketItemSuccess: function ({ sizeName = '', product }) {
    this.handleShowSnackbar({
      active: true,
      message: dictionary.PRODUCT.DELETE_FROM_BASKET_SUCCESS(
        `${sizeName !== 'Standard' ? sizeName : ''} ${product.name}`,
      ).text,
      theme: 'success',
      autoCloseDuration: 5000,
    })

    TrackingController.reinvoke({ view: router.currentRoute.value })
  },

  onDeleteBasketItemError: function ({ sizeName = '', product, error }) {
    if (error?.response?.data?.message !== 410) {
      const message =
        error?.response?.data?.message ||
        dictionary.PRODUCT.DELETE_FROM_BASKET_ERROR(
          `${sizeName !== 'Standard' ? sizeName : ''} ${product.name}`,
        ).text
      this.handleShowSnackbar({
        active: true,
        message,
        theme: 'danger',
        autoCloseDuration: 5000,
      })
    }
  },

  onDeleteBasketItemFinally: function ({ context }) {
    context.loading = false
  },

  handleShowSnackbar: function (snackbar) {
    if (useCartStore().basketMessages?.length) {
      const snackbarMessage = snackbar.message
      snackbar.message = pathOr(
        snackbar.message,
        ['message'],
        useCartStore().basketMessages[0],
      )
      snackbar.theme = pathOr(
        snackbar.theme,
        ['type'],
        useCartStore().basketMessages[0],
      )

      const code = useCartStore().basketMessages[0].code
      if (code === 'AlcoholLimit') {
        const maxAlcoholUnitCount = useCartStore().maxAlcoholUnitCount
        snackbar.message =
          dictionary.PRODUCT.ALCOHOL.MAX(maxAlcoholUnitCount)?.text
      } else if (
        code === 'ChangeDisposition' &&
        useCartStore().basketMessages[0].type === 'warning'
      ) {
        snackbar.autoClose = false
      } else if (code === 'VoucherAutoRemove') {
        snackbar.message = snackbarMessage
        const voucherMessage = ContentController.codeToMessage(
          code,
          useCartStore().basketMessages[0].message,
        )
        UiController.showAlert({
          active: true,
          alertType: 'alert',
          headerBgVariant: 'success',
          title: 'Voucher',
          okTitle: 'OK',
          okVariant: 'success',
          bodyText: voucherMessage,
          okCallback: function () {
            UiController.hideAlert()
          },
        })
      }
    }

    if (
      router.currentRoute.value.name === 'checkout' &&
      snackbar.theme !== 'success'
    ) {
      snackbar = {
        ...snackbar,
        blocking: true,
        autoClose: false,
        hasCloseIcon: false,
        okayButton: {
          active: true,
          text: 'Review Cart',
          theme: 'success',
          callback: () => {
            router.push('/offers')
            useSidebarStore().toggleSidebar(true)
            UiController.showSnackbar({
              active: false,
            })
          },
        },
        cancelButton: {
          active: true,
          text: 'Continue',
          theme: 'secondary',
          callback: () => {
            UiController.showSnackbar({
              active: false,
            })
          },
        },
      }
    }
    UiController.showSnackbar(snackbar)
  },

  onDeleteBasket: function (basketId) {
    const self = this

    UiController.showAlert({
      ref: 'delete-basket-confirm',
      active: true,
      alertType: 'confirm',
      headerBgVariant: 'danger',
      title: 'Empty Cart?',
      okTitle: 'Empty Cart',
      okVariant: 'danger',
      bodyText: ContentController.dictionaryToMessage(
        'BASKET.DELETE_BASKET_CONFIRM',
      ),
      okCallback: function () {
        UiController.hideAlert()
        self.doDeleteBasket(basketId)
      },
    })
  },

  doDeleteBasket: function (basketId) {
    const self = this

    UiController.showSpinner({
      message: ContentController.dictionaryToMessage('BASKET.BASKET_RESETTING'),
    })

    useCartStore()
      .deleteBasket(basketId)
      .then(() => {
        self.onDeleteBasketSuccess()
      })
      .catch((error) => {
        self.onDeleteBasketError(error)
      })
      .finally(() => {
        UiController.showSpinner({
          loading: false,
        })
      })
  },

  onDeleteBasketSuccess() {
    useCartStore().setCartErrors({})
    this.handleShowSnackbar({
      active: true,
      message: ContentController.dictionaryToMessage(
        'BASKET.BASKET_RESET_SUCCESS',
      ),
      theme: 'success',
    })

    TrackingController.reinvoke({ view: router.currentRoute.value })
  },

  onDeleteBasketError(error) {
    if (error.response.status !== 410) {
      this.handleShowSnackbar({
        active: true,
        message: ContentController.dictionaryToMessage(
          'BASKET.BASKET_RESET_ERROR',
        ),
        theme: 'danger',
      })
    }
  },

  doValidateBasket: async function () {
    const basket = useCartStore().basket
    UiController.showSpinner({
      message: dictionary.BASKET.BASKET_VALIDATING_ORDER.text,
    })
    return await useCartStore()
      .validateBasket(basket.basketId)
      .then(() => {
        return true
      })
      .catch((error) => {
        this.onOrderBasketError(error, {}, '', false)
        return false
      })
      .finally(() => {
        UiController.showSpinner({
          loading: false,
        })
      })
  },

  resolveGroupedBasketItemErrors: function ({
    product,
    groupedBasketItemId,
  }) {
    let cartErrors = useCartStore().cartErrors
    // remove groupedBasketItemId from errors
    if (groupedBasketItemId) {
      cartErrors = {
        ...cartErrors,
        groupedBasketItems: cartErrors.groupedBasketItems.filter(
          (meal) => meal.groupedBasketItemId !== groupedBasketItemId,
        ),
      }
    }
    // if all errors are resolved cleanup all cart errors and save
    if (
      cartErrors.basketItems.length === 0 &&
      cartErrors.groupedBasketItems.length === 0
    ) {
      useCartStore().setCartErrors({})
      // show dialog to confirm if user need to continue checkout or browse
      this.handleContinueCheckout(product.name, 'updated in')
    } else {
      useCartStore().setCartErrors(cartErrors)
      // handle next meal/product OOS error
      this.handleBasketItemNotFoundError(cartErrors)
    }
  },

  handleIncompleteMealError: async function (products) {
    const basket = useCartStore().basket
    // get list of product names in a string
    const productNames =
      products.length > 1
        ? products.reduce(
            (accumulator, currentValue, index, array) =>
              accumulator.name +
              (index < array.length - 1 ? ', ' : ' and ') +
              currentValue.name,
          )
        : products[0].name
    useUiStore().triggerAlert({
      ref: 'debug-confirm-test',
      active: true,
      alertType: 'confirm',
      headerBgVariant: 'warning',
      title: 'Oops!',
      okTitle: 'Continue',
      cancelTitle: 'Remove from Cart',
      okVariant: 'success',
      cancelVariant: 'warning',
      bodyText: ContentController.replaceKeysFromDictionaryCode(
        'BASKET.INCOMPLETE_MEAL_ERROR',
        {
          productNames,
        },
      ),
      okCallback: async () => {
        useUiStore().deactivateAlert()
        // patch request to un-bundle the meal
        await useCartStore().updateBasket({
          basket: {
            isAsap: basket.isAsap,
            orderDueAt: basket.orderDueAt,
            serviceType: basket.serviceType,
            storeId: basket.storeInfo.storeId,
          },
          basketId: basket.basketId,
        })
        this.handleContinueCheckout(productNames, 'unbundled in')
      },
      cancelCallback: async () => {
        useUiStore().deactivateAlert()
        // remove meals from cart
        if (products.length > 0) {
          // remove all meals that is unable to resolve from cart
          await Promise.all(
            products.map(async (item) => {
              await useCartStore().deleteGroupedBasketItem({
                groupedBasketItemId: item.groupedBasketItemId,
                basketId: basket.basketId,
              })
            }),
          ).then(() => {
            const emptyBasket = useCartStore().emptyBasket
            if (!emptyBasket) {
              useCartStore().setCartErrors({})
              this.handleContinueCheckout(productNames, 'removed from')
            }
          })
        }
      },
    })
  },

  showOOSError: function (message) {
    useUiStore().triggerAlert({
      ref: 'oos-error-modal',
      active: true,
      alertType: 'alert',
      headerBgVariant: 'warning',
      title: 'Heads Up',
      okTitle: 'Continue',
      okVariant: 'success',
      okOnly: true,
      bodyText: `<p> ${message} </p>`,
      okCallback: () => {
        useUiStore().deactivateAlert()
      },
    })
  },

  handleContinueCheckout: function (productNames, status) {
    useUiStore().triggerAlert({
      ref: 'continue-checkout-modal',
      active: true,
      alertType: 'alert',
      okOnly: true,
      headerBgVariant: 'success',
      title: 'Success',
      okTitle: 'I acknowledge',
      okVariant: 'success',
      bodyText: ContentController.replaceKeysFromDictionaryCode(
        'BASKET.ERROR_RESOLVE_IN_BASKET_SUCCESS',
        {
          productNames,
          status,
          cartTotal: toCurrency(useCartStore().basketTotal),
        },
      ),
      okCallback: () => {
        router.push('/cart')
        useUiStore().deactivateAlert()
      },
    })
  },

  resolveBasketItemErrors: async function () {
    const cartErrors = useCartStore().cartErrors
    const basketId = useCartStore().basketId
    if (cartErrors.basketItems.length > 0) {
      // remove all à la carte items which is out of stock from cart
      await cartErrors.basketItems.forEach((item) => {
        useCartStore().deleteBasketItem({
          basketItemId: item.basketItemId,
          basketId,
        })
      })
    }
  },

  async placeOrder({
    orderInfo,
    apiEndpoint,
    redirect = true,
    validateBasket = true,
    showSpinner = true,
    replaceRoute = false,
    isRegister = false,
  }) {
    try {
      useUiStore().dismissSnackbar()
      orderInfo.customerInfo = this.checkCustomerDetails(orderInfo.customerInfo)

      if (validateBasket) {
        if (showSpinner) {
          UiController.showSpinner({
            message: ContentController.dictionaryToMessage(
              'BASKET.BASKET_VALIDATING_ORDER',
            ),
          })
        }

        await this.validateOrder(orderInfo).catch((error) => {
          const disposition = useCartStore().disposition
          useCartStore().updateBasket({
            basket: { ...disposition },
            basketId: useCartStore().basketId,
          })
          this.onCartApiCallError(error)
        })
      }

      if (showSpinner) {
        UiController.showSpinner({
          message: ContentController.dictionaryToMessage(
            'BASKET.BASKET_PLACING_ORDER',
          ),
        })
      }

      useCartStore().clear3dsData()
      useCartStore().setFlexCardVerificationNumber(
        orderInfo.paymentInfo?.cardVerificationNumber ?? '',
      )

      let orderRegister = 'order-guest'
      if (isRegister) {
        orderRegister = 'order-register'
      } else if (useCustomerStore().isAuth) {
        orderRegister = 'order-customer'
      }

      // Fix mutating state
      orderInfo.customerInfo = {
        ...orderInfo.customerInfo,
        orderRegister,
      }

      const response = await this.orderBasket({
        orderInfo,
        apiEndpoint,
        redirect,
        replaceRoute,
      })

      // Update Recent Orders if customer is logged in
      if (useCustomerStore().isAuth) {
        useOrderStore()
          .getRecentOrders()
          .then((response) => {
            if (response && response.length > 0) {
              // Persists last order basket
              useOrderStore().setLastOrderBasket(response[0].basket)
            }
          })
          .catch((error) => {
            // Error
            console.error(error)
          })

        // Update preferences
        const customerProfile = useCustomerStore().profile
        useCustomerStore().setCustomerProfile({
          // if null it means that it comes from express pay payment and should use the save customer profile optin values
          optinEmail:
            orderInfo.customerInfo.optinEmail === null
              ? customerProfile.optinEmail
              : orderInfo.customerInfo.optinEmail,
          optinSms:
            orderInfo.customerInfo.optinSms === null
              ? customerProfile.optinSms
              : orderInfo.customerInfo.optinSms,
          optinSupercarsFan: orderInfo.customerInfo.optinSupercarsFan,
        })
      }

      if (useCartStore().auth3dsData.use3DS) {
        redirect = false
      }

      if (redirect) {
        await this.createFreshBasket()

        if (useCustomerStore().callCenter.mode) {
          useCartStore().clearBasket()
        }
      }

      return response
    } catch (error) {
      console.error(error)
      await this.onOrderBasketError(error, orderInfo, apiEndpoint)
    } finally {
      useUiStore().triggerSpinner({
        loading: false,
      })
    }
  },

  checkCustomerDetails(customerInfo) {
    let { firstName, lastName, phoneNumber } = customerInfo

    if (!firstName) {
      firstName = 'firstName'
    }
    if (!lastName) {
      lastName = 'lastName'
    }

    return {
      ...customerInfo,
      firstName: trimRemoveLineBreaks(firstName),
      lastName: trimRemoveLineBreaks(lastName),
      phoneNumber: removeNonNumericExceptPlus(phoneNumber),
    }
  },

  async validateOrder ({ customerInfo }) {
    // make sure that no other userId/customerId will be used here if user is not logged in
    const isAuth = useCustomerStore().isAuth
    if (!isAuth) {
      customerInfo.userId = '00000000-0000-0000-0000-000000000000'
      customerInfo.customerId = '00000000-0000-0000-0000-000000000000'
    }

    const response = await useCartStore().validateOrder({
      ...getCustomerInfo(customerInfo),
      ...TrackingController.getUtm(),
    })

    useUserStore().setUser(response?.customerInfo?.userId)
    useUserStore().setUserStatus(response?.customerInfo?.isNewUser)
    return response
  },

  async handleBasketItemNotFoundError(missingItems, message = '') {
    if (missingItems) {
      const firstMealWithError = missingItems.groupedBasketItems[0]
      const firstItemWithError = missingItems.basketItems[0]
      const mealProductType =
        firstMealWithError && firstMealWithError.productType
      const itemProductType =
        firstItemWithError && firstItemWithError.productType

      // invalidate product cache for the categories
      const missingInMeals =
        missingItems.groupedBasketItems
          ?.reduce((prev, curr) => prev.concat(curr.basketItems), [])
          .reduce((prev, curr) => prev.concat(curr.categories), [])
          .map((category) => category.code) || []
      const missingInItems =
        missingItems.basketItems
          ?.reduce((prev, curr) => prev.concat(curr.categories), [])
          .map((category) => category.code) || []
      await useCacheStore().initProductsCache(
        [...new Set([...missingInMeals, ...missingInItems])].map((cat) => {
          return { code: cat }
        }),
        {
          root: true,
        },
      )

      let resolveUrl

      // resolve meal/deal errors on priority
      if (
        [PRODUCT.PRODUCT_TYPE.MEALS, PRODUCT.PRODUCT_TYPE.DEALS].includes(
          mealProductType,
        )
      ) {
        if (!firstMealWithError.ableToSwap) {
          this.handleIncompleteMealError(missingItems.groupedBasketItems)
        } else {
          resolveUrl = `/pizza-meals/${firstMealWithError.slug}/${firstMealWithError.groupedBasketItemId}`
        }
      } else {
        // show snackbar warning
        if (message) {
          UiController.showSnackbar({
            active: true,
            message,
            theme: 'danger',
            autoClose: false,
          })
        }
        if (itemProductType !== PRODUCT.PRODUCT_TYPE.MAIN) {
          const navItem = useCacheStore().nav_items.cache.find(
            (nav) => nav.name === itemProductType,
          )?.category.code
          resolveUrl = `/menu/${navItem || 'offers'}/`
        } else if (firstItemWithError.slug.includes('schnitzza')) {
          resolveUrl = '/menu/schnitzza/'
        } else if (firstItemWithError.slug.includes('unreal')) {
          resolveUrl = '/menu/unreal/'
        } else {
          // fallback to offers
          resolveUrl = '/menu/offers/'
        }
      }
      // remove out of stock à la carte items from cart and redirect to the first item category page
      await this.resolveBasketItemErrors()
      if (resolveUrl) {
        // groupedBasketItem errors will be resolved from bundle builder page
        if (router.currentRoute.value.path === resolveUrl) {
          router.go(0)
        } else {
          router.push(resolveUrl)
        }
      }
    }
  },

  validateBasket() {
    const basketId = useCartStore().basket.basketId
    return useCartStore().validateBasket(basketId)
  },

  async orderBasket({
    apiEndpoint,
    orderInfo,
    redirect = true,
    replaceRoute = false,
  }) {
    const response = await useCartStore().orderBasket({ apiEndpoint, orderInfo })

    // Update Saved Disposition
    const basket = useCartStore().basket
    if (useCustomerStore().isAuth) {
      const dispositionPreference = useCustomerStore().dispositionPreference
      if (useCartStore().isPickup && !dispositionPreference.storeId) {
        useCustomerStore().updatePickupStore({
          data: {
            storeId: basket.storeInfo.storeId,
          },
          silent: true,
        })
      } else if (
        useCartStore().isDelivery &&
        !dispositionPreference?.address?.address
      ) {
        useCustomerStore().updateDeliveryAddress({
          data: getAddressInfo(basket),
          silent: true,
        })
      }
    }

    if (response?.orderId) {
      TrackingController.trackEvent({
        event: 'cart.interact.manual.submit.orderSubmit',
        cartId: response.orderId,
      })

      useTrackingStore().setTransactionEventTracked(false)

      // Delete any Flex API token
      if (
        !useCartStore().flexSaveCard &&
        !useCartStore().flexCardVerificationNumber &&
        useCartStore().flexApiToken
      ) {
        useCartStore().deleteFlexApiToken(useCartStore().flexApiToken)
      }

      // Start iOS Live Activity
      if (isiOsApp()) {
        ContentController.getFeatureFlag(
          'liveActivities',
          basket.storeInfo.storeId,
        ).then((enabled) => {
          console.log('Got Live Activity Flag', enabled)
          if (!enabled) {
            return false
          }

          console.log('Starting live activity', response.orderId)
          const activityId = response.orderId
          Braze.startLiveActivity({
            attributes: {
              serviceType: basket.serviceType.toLowerCase(),
              orderUrl: `${useDiscoverStore().uris.URI_WEB_SECONDARY}/order/${activityId}`,
            },
            state: {
              status: response.fulfilmentStatus.toLowerCase(),
              estimatedCompletionTime: 0, // TODO: Make this property optional
            },
            pushTokenTag: activityId,
          })
        })
      }
    }

    if (redirect && response?.orderId) {
      // Get Survey
      useSurveyStore().getSurvey({
        surveyId: import.meta.env.VITE_SURVEY_ID,
        orderId: response.orderId
      })

      if (window.irpidparameter) window.irpidparameter = ''

      router[replaceRoute ? 'replace' : 'push']({
        name: 'order',
        params: {
          orderId: response.orderId,
        },
      })
    }

    if (response?.orderId) {
      useTrackingStore().setTransactionEventTracked(false)
    }
    return response
  },

  createFreshBasket() {
    const basket = useCartStore().basket
    const payload = createBasket(basket)

    if (isExpired(payload.orderDueAt)) {
      payload.orderDueAt = new Date().toISOString()
      payload.isAsap = true
    }

    return this.createSession(payload, true)
  },

  async onOrderBasketError(error, orderInfo, apiEndpoint, notifyError = true) {
    const httpErrorCode = error?.response?.status
    if (httpErrorCode && httpErrorCode !== 410) {
      const message = path(['response', 'data', 'message'], error)
      const code = path(['response', 'data', 'code'], error)

      // for 404 errors there will be an error dialog shown
      if (httpErrorCode !== 404) {
        // don't show snackbar if error is below minimum spend
        const minimumDeliveryRegex =
          /\b(\w+)\s+below\s+(\w+)\s+minimum\s+delivery\b/
        if (!minimumDeliveryRegex.test(message)) {
          UiController.showSnackbar({
            active: true,
            message:
              ContentController.codeToMessage(code, message) ||
              dictionary.BASKET.VALIDATE_BASKET_ERROR.text,
            theme: 'danger',
            autoClose: false,
          })
        }
      }

      if (!message && notifyError) {
        const errorDetail = {
          errorMessage: error?.response?.data?.message || error?.message,
          orderInfo,
          apiEndpoint,
          response: error?.response?.data,
        }
        await useCartStore().notifyOrderError({
          action: 'PlaceOrder-Order',
          statusCode: error?.response?.status,
          errorCode: error?.response?.data?.code,
          message: JSON.stringify(errorDetail),
        })
      }

      const basket = useCartStore().basket

      if (code && code === 'VoucherAlreadyRedeemed') {
        useCartStore().updateBasket({
          basket: {
            isAsap: basket.isAsap,
            orderDueAt: basket.orderDueAt,
            serviceType: basket.serviceType,
            storeId: basket.storeInfo.storeId,
          },
          basketId: basket.basketId,
        })
        router.push('/cart', () => router.go(0))
      }

      if (httpErrorCode === 404 && code && code === 'BasketItemNotFound') {
        const cartError = path(['response', 'data', 'errors', 0], error)
        const message = cartError.message

        if (cartError) {
          // sort missing items so meals that are not resolvable would come last in the process
          const missingItems = {
            ...cartError.missingItems,
            groupedBasketItems: this.sortMealErrorsInCart(
              cartError.missingItems.groupedBasketItems,
            ),
          }
          await useCartStore().setCartErrors(missingItems)

          // handle the first error
          this.handleBasketItemNotFoundError(missingItems, message)
        }
      }
    }
    throw error
  },
  sortMealErrorsInCart(errors) {
    return errors.sort((a, b) => {
      return b.ableToSwap - a.ableToSwap
    })
  },
  authenticateCard() {
    router.replace({
      name: 'card-auth',
    })
  },

  async applyVoucher({ voucherCode, payload }, isVoucher = false) {
    if (!useCartStore().validBasket) {
      this.handleGuestVoucherInput(voucherCode, isVoucher)

      return
    }

    try {
      const response = await useCartStore().applyVoucher({ voucherCode, payload })

      if (response.isDealBuilder) {
        this.handleVoucherOfDealbuilderType(response.coupon)
      } else {
        UiController.showSnackbar({
          active: true,
          message: response.message,
          theme: 'success',
        })

        if (window.abtests['cctt-7'] === 1) {
          useCartStore().handleDismissCouponModal()
        }

        if (isVoucher) {
          router.replace('/offers')
        }
      }

      // Make all successful vouchers then-able
      return response
    } catch (error) {
      if (error?.response?.status !== 410) {
        const message =
          error?.response?.data?.message ||
          ContentController.dictionaryToMessage('BASKET.APPLY_VOUCHER_ERROR')
        switch (error?.response?.data?.code) {
          case 'VoucherUnavailableForFulfillmentType': {
            const isDelivery = useCartStore().isDelivery
            this.showVoucherLocateSnackbar({
              voucherCode,
              message: ContentController.codeToMessage(
                error.response.data.code,
                message,
              ),
              okayBtnText: `Change to ${isDelivery ? 'Pickup' : 'Delivery'}`,
              okayBtnCallback: () => {
                useLocalisationStore().setLocatorState({
                  tabIndex: +isDelivery,
                  editMode: true,
                })
                router.replace('/')
              },
            })
            break
          }
          case 'VoucherUnavailableForStore':
            this.showVoucherLocateSnackbar({
              voucherCode,
              message: ContentController.codeToMessage(
                error.response.data.code,
                message,
              ),
              okayBtnText: 'Change Location',
              okayBtnCallback: () => {
                useLocalisationStore().setLocatorState({
                  editMode: true,
                })
                router.replace('/')
              },
            })
            break
          case 'VoucherUnavailableForFulfillmentDate':
            this.showVoucherLocateSnackbar({
              voucherCode,
              message: ContentController.codeToMessage(
                error.response.data.code,
                message,
              ),
              okayBtnText: 'Change Time',
              okayBtnCallback: () => {
                useLocalisationStore().setDatetimeSelectorState({
                  active: true,
                  showWarning: false,
                  disableCloseOption: false,
                })
              },
            })
            break
          case 'BasketHasExpired':
            // clear session first
            this.clearSession(true, true)

            // let the customer input again the store for new basket id
            this.handleGuestVoucherInput(voucherCode, isVoucher)
            break
          default:
            // Alert user of voucher error
            UiController.showSnackbar({
              active: true,
              blocking: window.abtests['cctt-7'] === 1,
              message: ContentController.codeToMessage(
                error.response.data.code,
                message,
              ),
              theme: 'danger',
              autoClose: false,
            })
            break
        }
      }

      // Throw error back to callee to handle
      throw error
    }
  },

  removeVoucher: function ({
    basketId,
    groupedBasketItemId,
    successCallback = null,
    errorCallback = null,
    finalCallback = null,
  }) {
    useCartStore()
      .removeVoucher({
        basketId,
        groupedBasketItemId,
      })
      .then((response) => {
        if (response.basket?.messages?.length) {
          const messageObject = response.basket.messages[0]
          if (messageObject.code === 'VoucherAutoRemove') {
            const voucherMessage = ContentController.codeToMessage(
              messageObject.code,
              messageObject.message,
            )
            UiController.showAlert({
              active: true,
              alertType: 'alert',
              headerBgVariant: 'success',
              title: 'Voucher',
              okTitle: 'OK',
              okVariant: 'success',
              bodyText: voucherMessage,
              okCallback: function () {
                UiController.hideAlert()
              },
            })
          }
        }
        UiController.showSnackbar({
          active: true,
          message: response.message,
          theme: 'success',
        })

        if (successCallback) {
          successCallback()
        }
      })
      .catch((error) => {
        UiController.showSnackbar({
          active: true,
          message: ContentController.codeToMessage(
            error.response.data.code,
            error.response.data.message,
          ),
          theme: 'danger',
          autoClose: false,
        })

        if (errorCallback) {
          errorCallback()
        }
      })
      .finally(() => {
        if (finalCallback) {
          finalCallback()
        }
      })
  },

  showVoucherLocateSnackbar: function ({
    voucherCode,
    message,
    okayBtnText,
    okayBtnCallback,
  }) {
    UiController.showSnackbar({
      active: true,
      blocking: true,
      hasCloseIcon: false,
      autoClose: false,
      message,
      theme: 'danger',
      okayButton: {
        active: true,
        text: okayBtnText,
        theme: 'success',
        callback: () => {
          useProductStore().setVoucherInFlight(voucherCode)
          okayBtnCallback()
          UiController.showSnackbar({
            active: false,
          })
        },
      },
      cancelButton: {
        active: true,
        text: 'Cancel',
        theme: 'danger',
        callback: () => {
          UiController.showSnackbar({
            active: false,
          })
        },
      },
    })
  },

  handleGuestVoucherInput: async function (voucherCode) {
    await useLocalisationStore().setLocalisationModal({ active: true })
    await useProductStore().setVoucherInFlight(voucherCode)
  },

  async handleVoucherInFlight() {
    const voucherCode = useProductStore().voucherInFlight

    UiController.showSpinner({
      loading: true,
      message: `Applying voucher code ${voucherCode}`,
    })

    const payload = {
      customerId: useCustomerStore().customerId,
    }

    await this.applyVoucher({ voucherCode, payload })

    useProductStore().resetVoucherInFlight()

    UiController.showSpinner({
      loading: false,
    })
  },

  handleVoucherOfDealbuilderType: function (coupon) {
    useProductStore().setProductRouteState({
      mealId: coupon.mealDetail.productId,
      meal: coupon.mealDetail,
      voucherCode: coupon.voucherCode,
      voucherId: coupon.voucherId,
    })

    router.replace({
      name: 'meal',
      params: {
        slug: coupon.mealDetail.slug.toLowerCase(),
      },
    })
  },

  getPayloadWithCustomerDisposition: function (fulfilmentType) {
    if (!useCustomerStore().isAuth) {
      return
    }

    // Check if we should overwrite current disposition
    if (window.location.pathname === '/checkout') {
      return
    }

    const basket = useCartStore().basket

    const { serviceType, address, storeId } =
      useCustomerStore().dispositionPreference
    let payload = {
      serviceType: fulfilmentType || serviceType,
      isAsap: basket.isAsap,
      orderDueAt: basket.orderDueAt,
    }

    // Check if customer dispo is already saved dispo
    const basketAddress = getAddressInfo(basket)
    const areSame =
      payload.serviceType?.toLowerCase() === 'delivery'
        ? !Object.keys(basketAddress).filter(
            (key) => address[key] !== basketAddress[key],
          ).length
        : storeId === basket.storeInfo.storeId
    if (areSame) {
      return
    }

    // Generate payload based on service type
    if (payload.serviceType?.toLowerCase() === 'pickup') {
      payload.storeId = storeId
    } else if (payload.serviceType?.toLowerCase() === 'delivery') {
      payload = { ...payload, ...address }
    } else {
      return
    }

    return payload
  },

  async reorder(payload) {
    try {
      const response = await useCartStore().addPreviousOrder(payload)

      this.onReorderBasketSuccess()

      return response
    } catch (err) {
      this.onReorderBasketError(err)
      throw err
    }
  },

  async copyBasket(payload) {
    try {
      const response = await useCartStore().copyBasket(payload)

      if (
        response.basketItems.length >= 1 ||
        response.groupedBasketItems.length >= 1
      ) {
        this.onCopyBasketSuccess()
      }

      return response
    } catch (err) {
      this.onCopyBasketError(err)
      throw err
    }
  },

  onReorderBasketSuccess() {
    this.handleShowSnackbar({
      active: true,
      message: dictionary.PRODUCT.ADD_TO_BASKET_SUCCESS(
        'your most recent order',
      ).text,
      theme: 'success',
      ...(useCartStore().basketMessages?.length
        ? { autoClose: false }
        : { autoCloseDuration: 5000 }), // Auto-close if no basket messages present
    })
  },

  onReorderBasketError(error) {
    if (error?.response?.status !== 410) {
      this.handleShowSnackbar({
        active: true,
        message: dictionary.PRODUCT.ADD_TO_BASKET_ERROR(
          'your most recent order',
        ).text,
        theme: 'danger',
        autoClose: false,
      })
    }
  },

  onCopyBasketSuccess() {
    this.handleShowSnackbar({
      active: true,
      message: dictionary.PRODUCT.ADD_TO_BASKET_SUCCESS().text,
      theme: 'success',
      ...(useCartStore().basketMessages?.length
        ? { autoClose: false }
        : { autoCloseDuration: 5000 }), // Auto-close if no basket messages present
    })
  },

  onCopyBasketError(error) {
    if (error?.response?.status !== 410) {
      this.handleShowSnackbar({
        active: true,
        message: dictionary.PRODUCT.ADD_TO_BASKET_ERROR().text,
        theme: 'danger',
        autoClose: false,
      })
    }
  },
}
