export default {
  GetSpace () {
    return window.Storyblok.get('cdn/spaces/me')
  },
  GetContentBySlug (slug) {
    return window.Storyblok.get(`cdn/stories/content/${slug}`)
  },
  GetContentCampaignBySlug (slug) {
    return window.Storyblok.get(`cdn/stories/content/campaign/${slug}`)
  },
  GetCampaignBySlug ({ slug, query }) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: `campaigns/${slug}`,
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetCokeCampaign ({ query }) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: 'campaigns/coke-campaign',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetBlogPosts (options) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: 'blog',
      ...options
    })
  },
  GetBlogPostBySlug (slug) {
    return window.Storyblok.get(`cdn/stories/blog/${slug}`, {
      resolve_relations: 'ContentCarousel.carousel,post.related_posts'
    })
  },
  GetOffers (query) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: 'carousels/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetCoupons (query) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: 'coupons/',
      sort_by: '',
      filter_query: query
    })
  },
  GetBanner (query) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'banners/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetBannerSecondary (query) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'banners-secondary/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetBannerSub (query) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'banners-sub/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetSnippet ({ path, query }) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: `snippets/${path}/`,
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetNavigationMenuBySlug (slug) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: `navigation/${slug}`,
      resolve_links: 'url'
    })
  },
  GetStore ({ query }) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'stores/',
      filter_query: query
    })
  },
  GetSpinner (query) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'spinners/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetSplashScreen (query) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'splash_screens/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetSpinTheWheel (query) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'spin-to-win/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetStory (fullSlug) {
    return window.Storyblok.get(`cdn/stories/${fullSlug}`)
  },
  GetBlackListedUserAgents (slug) {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: slug
    })
  },
  GetBlackListedIRPID (slug) {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: slug
    })
  },
  GetDictionary () {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: 'dictionary',
      per_page: 500
    })
  },
  GetOffersCategories (slug) {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: slug
    })
  },
  GetFeatureFlags () {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: 'feature-flag'
    })
  },
  GetMenuCategories ({ path, query }) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: `menu-categories/${path}/`,
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
}
