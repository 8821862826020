import { UiController } from '@/controllers/ui.controller'
import { ContentController } from '@/controllers/content.controller'
import { dictionary } from '@/config/dictionary.config.js'
import { PRODUCT } from '@/config/constants'
import { getTotalAlcoholUnits } from '@/services/helpers/product.helper'
import dayjs from 'dayjs'

import { useCartStore } from '@/stores/cart'

export const validateMinimumSpend = (currencyFn) => {
  const minimumSpend = useCartStore().minimumSpend
  const meetsMinimum = useCartStore().meetsMinimumSpend
  const hasItems = useCartStore().hasItems
  const serviceType = useCartStore().basketServiceType
  const basketTotal = useCartStore().basketTotal
  const result = meetsMinimum && hasItems
  if (!result) {
    const orderMinimum = currencyFn(minimumSpend)

    // const message = !meetsMinimum && hasItems
    //   ? dictionary.BASKET.BASKET_MINIMUM_WARNING(orderMinimum, serviceType.toLowerCase()).text
    //   : ContentController.dictionaryToMessage("BASKET.BASKET_EMPTY_WARNING")

    if (!meetsMinimum && hasItems) {
      UiController.showMinimumSpendModal({
        active: true,
        orderMinimum,
        minimumSpend,
        totalOrder: basketTotal,
        serviceType: serviceType.toLowerCase()
      })
    } else {
      UiController.showSnackbar({
        message: ContentController.dictionaryToMessage('BASKET.BASKET_EMPTY_WARNING'),
        active: true,
        blocking: true,
        theme: 'danger',
        autoClose: false
      })
    }
  }
  return result
}

export const validateMaximumAlcohol = () => {
  const maxAlcoholUnitCount = useCartStore().maxAlcoholUnitCount
  const totalAlcoholUnits = getTotalAlcoholUnits()
  const validTotalAlcohol = totalAlcoholUnits <= maxAlcoholUnitCount

  if (!validTotalAlcohol) {
    UiController.showSnackbar({
      message: dictionary.PRODUCT.ALCOHOL.EXCEEDED(maxAlcoholUnitCount)?.text,
      active: true,
      blocking: true,
      theme: 'danger',
      autoClose: false
    })
  }
  return validTotalAlcohol
}

export const validateAlcoholWithFood = () => {
  const hasAlcohol = getTotalAlcoholUnits() > 0
  if (!hasAlcohol) return true

  const { PIZZAS, WINGS, WINGSSTREET, MAIN, MEATBALLS } = PRODUCT.PRODUCT_TYPE
  const findFood = basketItems => basketItems.some(bi => [PIZZAS, WINGS, WINGSSTREET, MAIN, MEATBALLS].includes(bi.productType))
  const hasFood = findFood(useCartStore().basketItems) || useCartStore().groupedBasketItems.some(gbi => findFood(gbi.basketItems))

  if (!hasFood) {
    UiController.showSnackbar({
      message: dictionary.PRODUCT.ALCOHOL.NO_FOOD.text,
      active: true,
      blocking: true,
      theme: 'danger',
      autoClose: false
    })
  }

  return hasFood
}

export const validatePaymentTypeOption = () => {
  const basketItems = useCartStore().basketItems
  const groupedBasketItems = useCartStore().groupedBasketItems
  const types = []
  const combinedItems = [...basketItems, ...groupedBasketItems]
  combinedItems.forEach((item) => {
    if (item && item.paymentType && types.indexOf(item.paymentType) === -1) {
      types.push(item.paymentType)
    }
  })
  if (types.length > 1) {
    UiController.showSnackbar({
      message: 'Payment Option Clash! Cart contained exclusive items.',
      active: true,
      blocking: true,
      theme: 'danger',
      autoClose: false
    })
  }
  return types.length <= 1
}

/**
 * Validates the Coke Studio minimum spend based on the provided currency function, route, and basket.
 *
 * @param {function} currencyFn - The currency function for converting amounts.
 * @param {object} route - The route object containing information about the current route.
 * @param {object} basket - The basket object containing information about the items in the basket.
 * @return {object} An object containing information about whether to show a modal and a message, if applicable.
 */
export const validateCokeCampaignMinimumSpend = async (currencyFn, route, basket) => {
  try {
    const cokeCampaign = await ContentController.getCokeCampaign()

    // Do not show modal if no campaign
    if (!cokeCampaign.slug) return { showModal: false }

    const basketTotal = basket.basketTotal ?? basket.basket.basketTotal

    const startingAmount = Number(cokeCampaign.starting_amount)
    const minimumAmount = Number(cokeCampaign.minimum_amount)

    // Check if we already have coke in the basket
    const cokeInBasket = basket.basketItems.concat(
      basket.groupedBasketItems
        .flatMap(
          groupedItem => groupedItem.basketItems.map(
            basketItem => ({ ...basketItem })
          )
        )
    ).filter(item => cokeCampaign.coke_product_ids.data.includes(item.productId)).length

    // If we are on order confirmation page check if we need to show the form
    if (route.name === 'order') {
      return {
        showModal: basketTotal >= minimumAmount && cokeInBasket === 1,
        campaignSlug: cokeCampaign.slug
      }
    }

    // Check if message should be shown
    if (!cokeCampaign.show_message) return { showModal: false }

    // Check conditions for showing message
    if (basketTotal < minimumAmount || cokeInBasket === 0) {
      const needToSpend = currencyFn(minimumAmount - basketTotal)

      // Let's do this way for now. Marketing might change this conditions
      let message
      if (basketTotal > startingAmount && basketTotal < minimumAmount && cokeInBasket === 0) {
        // Between Starting Amount to Minimum Amount and has no Coke in the cart
        message = cokeCampaign.start_min_no_coke
      } else if (basketTotal > startingAmount && basketTotal < minimumAmount && cokeInBasket >= 1) {
        // Between Starting Amount to Minimum Amount and has Coke in the cart
        message = cokeCampaign.start_min_has_coke.replace('$:needToSpend:', needToSpend)
      } else if (basketTotal <= startingAmount && cokeInBasket === 0) {
        // Less than Starting Amount and has no Coke in the cart
        message = cokeCampaign.less_start_no_coke.replace('$:needToSpend:', needToSpend)
      } else if (basketTotal <= startingAmount && cokeInBasket >= 1) {
        // Less than Starting Amount and has Coke in cart
        message = message = cokeCampaign.less_start_has_coke.replace('$:needToSpend:', needToSpend)
      } else if (basketTotal >= minimumAmount && cokeInBasket === 0) {
        // More than Minimum Amount and has no Coke in the cart
        message = cokeCampaign.more_min_no_coke
      }

      return { message, showModal: true }
    }

    return { showModal: false }
  } catch (err) {
    return { showModal: false }
  }
}

export const addBasketSession = (basketId, sessionId) => {
  const basketSessions = {
    [basketId]: { sid: sessionId, created: new Date() }
  }
  return basketSessions
}

export const setFulfilmentDateTime = (basket) => {
  // Not localised
  if (!basket.id && !basket.orderDueAt) {
    return dayjs().format()
  }

  return dayjs(basket.orderDueAt).utcOffset(basket.orderDueAt).format()
}
