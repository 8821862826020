import { defineStore } from 'pinia'

export const useTrackingStore = defineStore('tracking', {
  state: () => ({
    tracking: {
      events: [],
      cart: {},
      screen: {},
      platform: {}
    },
    errors: [],
    transactionEventTracked: true
  }),

  getters: {

  },

  actions: {
    resetTracking () {
      this.tracking = {
        events: [],
        cart: {},
        screen: {},
        platform: {}
      }
    },
    setTracking (payload) {
      this.tracking = {
        ...this.tracking,
        ...payload
      }

      if (window) {
        window.PHA_DL = this.tracking
      }
    },
    setEvent (payload) {
      this.tracking.events = {
        ...this.tracking.events,
        ...payload
      }

      if (window) {
        window.PHA_DL = this.tracking
      }
    },
    setTransactionEventTracked (payload = true) {
      this.transactionEventTracked = payload
    },
    setError (payload) {
      this.errors.push(payload)
    },
    resetErrors () {
      this.errors = []
    }
  }
})
