import { useContentStore } from '@/stores/content'

export const isWebView = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return isIOS() // Check if iOS
    ? !window.navigator.standalone && !/safari/.test(userAgent)// Check if iOS webview
    : userAgent.includes('wv') // Check if android webview
}

export const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipod|ipad|macintosh/.test(userAgent) && !/safari/.test(userAgent)
}

export const isIOSWebView = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return !window.navigator.standalone && !/safari/.test(userAgent) && isIOS()
}

export const isSafari = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const vendor = window.navigator.vendor.toLowerCase()
  return /safari/i.test(userAgent) && /apple computer/.test(vendor) && !/android/i.test(userAgent)
}

export const isAndroid = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /android/.test(userAgent)
}

export const isAndroidWebView = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return userAgent.includes('wv') && isAndroid()
}

export const isBlacklistedUserAgent = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const BlackListAgents = useContentStore().blacklistedUserAgents
  return BlackListAgents.some(agents => userAgent.includes(agents.value))
}

export const iOSversion = () => {
  const agent = window.navigator.userAgent
  const start = agent.indexOf('OS ')
  if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
    return window.Number(agent.substring(start + 3, start + 5).replace('_', '.'))
  }

  return 0
}

// Mobile device detection using Feature detection: See more - https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
export const isMobileWeb = () => {
  let isMobile = false
  if ('maxTouchPoints' in navigator) {
    isMobile = navigator.maxTouchPoints > 0
  } else if ('msMaxTouchPoints' in navigator) {
    isMobile = navigator.msMaxTouchPoints > 0
  } else {
    const mQ = matchMedia?.('(pointer:coarse)')
    if (mQ?.media === '(pointer:coarse)') {
      isMobile = !!mQ.matches
    } else if ('orientation' in window) {
      isMobile = true // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent
      isMobile =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    }
  }
  return isMobile
}

export const getBrowser = () => {
  const { userAgent } = navigator
  const match = userAgent.match(/(opera|chrome|safari|firefox|msie(?=\/))\/?\s*(\d+)/i) || []

  if (match.length === 0) {
    return 'Safari'
  }

  if (match[1] === 'Chrome') {
    const opera = userAgent.match(/\b(OPR|opera)\/(\d+)/)
    if (opera !== null) {
      return 'Opera'
    }

    const edge = userAgent.match(/\b(Edg)\/(\d+)/)
    if (edge !== null) {
      return 'Edge'
    }
  }

  return match[1]
}
