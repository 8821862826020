import GAuth from '@/services/helpers/auth/gAuth.helper'
import { FacebookLogin } from '@pizzahutau/capacitor-plugin-facebook-login'
import { PROVIDER } from '@/config/provider.config'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import AmazonAuth from '@/services/helpers/auth/amazonAuth.helper'

export const getToken = (provider) => {
  const tokenHandler = {
    [PROVIDER.GOOGLE]: () => GAuth.getToken(),
    [PROVIDER.FACEBOOK]: async () => {
      const res = await FacebookLogin.getCurrentAccessToken()
      return res?.accessToken?.token
    },
    [PROVIDER.APPLE]: () => {},
    [PROVIDER.PAYPAL]: () => {},
    [PROVIDER.AMAZON]: () => {}
  }

  return tokenHandler[provider]?.()
}

export const logout = (provider) => {
  const logoutHandler = {
    [PROVIDER.GOOGLE]: () => GoogleAuth.signOut(),
    [PROVIDER.FACEBOOK]: () => FacebookLogin.logout(),
    [PROVIDER.APPLE]: () => {},
    [PROVIDER.PAYPAL]: () => {},
    [PROVIDER.AMAZON]: () => AmazonAuth.logout()
  }

  return logoutHandler[provider]?.()
}
