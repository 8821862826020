import dayjs from 'dayjs'
// import store from '@/store/'
import sortby from 'lodash/sortBy'
import { config } from '@/config/global.config'

import { useCartStore } from '@/stores/cart'
import { useProductStore } from '@/stores/product'

import camelCase from 'camelcase'

export const ProductController = {
  getCategories: function () {

    const basket = useCartStore().basket
    const validBasket = useCartStore().validBasket

    useProductStore().getCategories({
      storeCode: validBasket ? basket.storeInfo.storeId : config.NEW_SESSION_STORE_ID,
      fulfilmentDateTime: validBasket ? basket.orderDueAt : new Date().toISOString(),
      fulfilmentType: validBasket ? basket.serviceType : 'Delivery'
    })
  },

  sortCategories: function (categories) {
    return categories.sort((a, b) => parseFloat(a.sortOrder) - parseFloat(b.sortOrder))
  },

  getProduct: function ({ context, payload }) {
    return useProductStore().getProduct({
      slug: payload.slug,
      storeCode: payload.storeCode,
      fulfilmentDateTime: payload.fulfilmentDateTime,
      fulfilmentType: payload.fulfilmentType
    })
      .then((product) => {
        return product
      })
      .finally(() => {
        if (context.loading) {
          context.loading = false
        }
      })
  },

  subcategorizeProducts: function (products) {
    const subcategories = []

    products.forEach(product => {
      const thisProduct = product

      product.categories.forEach((category, index, categoriesArray) => {
        const subcategory = subcategories.find(subcat => subcat.code === category.code)

        if (categoriesArray.length > 1 && (!category.isNavigation || category.isHomePage)) {
          return
        }

        if (subcategory) {
          subcategory.products.push(thisProduct)
          subcategory.products = sortby(subcategory.products, ['sortOrder'])
          subcategory.products = sortby(subcategory.products, ['isOutOfStock'])
        } else {
          subcategories.push({
            id: category.id,
            code: category.code,
            name: category.name,
            isNavigation: category.isNavigation,
            isHomePage: category.isHomePage,
            isHidden: category.isHidden,
            slug: category.slug,
            products: [],
            sortOrder: category.sortOrder
          })

          subcategories.find(subcat => subcat.code === category.code).products.push(thisProduct)
          subcategories.products = sortby(subcategories.products, ['sortOrder'])
          subcategories.products = sortby(subcategories.products, ['isOutOfStock'])
        }
      })
    })

    return sortby(subcategories, ['sortOrder'])
  },

  defineProductFilters: function (products) {
    const filters = []

    products.forEach(product => {
      if (product.tags) {
        product.tags.forEach(tag => {
          const foundFilters = filters.find(filter => {
            return filter.id === camelCase(tag.name)
          })

          if (tag.tagType === 'Tag' && !foundFilters) {
            filters.push({
              type: camelCase(tag.tagType),
              name: tag.name,
              id: camelCase(tag.name),
              active: false
            })
          }
        }, this)
      }
    }, this)

    return filters.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1))
  },

  getIngredientsByTopic: function ({ topic, ingredients }) {
    const filteredIngredients = ingredients.filter(ingredient => {
      return ingredient.ingredientCategoryCode === topic
    })

    return filteredIngredients
  },

  getNavigationItems: function () {
    const basket = useCartStore().basket
    const validBasket = useCartStore().validBasket

    return useProductStore().getNavigationItems({
      // storeCode: validBasket ? basket.storeInfo.storeId : config.NEW_SESSION_STORE_ID,
      fulfilmentDateTime: validBasket ? dayjs(basket.orderDueAt).utcOffset(basket.orderDueAt) : new Date()
      // fulfilmentType: validBasket ? basket.serviceType : 'Delivery'
    })
  },

  filterProducts: function (array, filters) {
    const filterKeys = Object.keys(filters)

    return array.filter(item => {
      return filterKeys.every(key => {
        if (typeof filters[key] !== 'function') {
          return true
        }

        if (!item[key]) {
          return false
        }

        return filters[key](item[key])
      })
    })
  }
}
