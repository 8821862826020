import { computed } from 'vue'
import { ContentController } from '@/controllers/content.controller'
import { CacheController } from '@/controllers/cache.controller'

import { useCacheStore } from '@/stores/cache'

export function useSnippets (snippetPath) {
  const cacheStore = useCacheStore()
  const snippets = computed(() => cacheStore.snippets)

  if (!snippetPath) {
    return
  }

  const getFilteredSnippets = () => {
    return Object.entries(snippets.value).filter(([key]) =>
      key.includes(snippetPath)
    )
  }

  const getExpiredSnippet = () => {
    return getFilteredSnippets().find(([, val]) =>
      CacheController.isCacheExpired(val.expires_at)
    )
  }

  const fetchSnippets = () => {
    ContentController.getSnippets({ path: snippetPath })
  }

  const checkAndFetchSnippets = () => {
    if (!getFilteredSnippets().length) {
      fetchSnippets()
    } else {
      const expiredSnippet = getExpiredSnippet()
      if (expiredSnippet) {
        fetchSnippets()
      }
    }
  }

  return { checkAndFetchSnippets }
}
