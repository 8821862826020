/**
 * Return a random integer between a given min and max
 *
 * @func
 * @param {Number} [min = 0]
 * @param {Number} [max = 10]
 * @return {Number}
 * */
export function randomInteger (min = 0, max = 10) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

/**
 * Return a random hex string
 *
 * @func
 * @param {Number} length - number of bytes
 * @return {String}
 * */
export function randomHexString (length) {
  const min = Math.pow(16, Math.min(length, 8) - 1)
  const max = Math.pow(16, Math.min(length, 8)) - 1
  let rand = (Math.floor(Math.random() * (max - min + 1)) + min).toString(16)

  while (rand.length < length) {
    rand = rand + randomHexString(length - 8)
  }

  return rand
}
