export const footerDefaults = [
  {
    items: [
      {
        title: 'Our Menu',
        url: {
          story: {
            url: '/menu/pizza-meals'
          }
        },
        items: [
          {
            title: 'Pizza',
            url: {
              story: {
                url: '/menu/pizza'
              }
            },
            items: [
              {
                title: 'Classics',
                url: {
                  story: {
                    url: '/menu/pizza/classics-range'
                  }
                }
              },
              {
                title: 'Favourites',
                url: {
                  story: {
                    url: '/menu/pizza/favourites-range'
                  }
                }
              },
              {
                title: 'Supreme',
                url: {
                  story: {
                    url: '/menu/pizza/supreme-range'
                  }
                }
              },
              {
                title: 'Premium',
                url: {
                  story: {
                    url: '/menu/pizza/premium-range'
                  }
                }
              },
              {
                title: 'Personal Pan Lunch Pizza',
                url: {
                  story: {
                    url: '/lunchpizza'
                  }
                }
              }
            ]
          },
          {
            title: 'WingsStreet',
            url: {
              story: {
                url: '/menu/WingsStreet'
              }
            },
            items: []
          },
          {
            title: 'Sides',
            url: {
              story: {
                url: '/menu/sides'
              }
            },
            items: []
          },
          {
            title: 'Desserts',
            url: {
              story: {
                url: '/menu/desserts'
              }
            },
            items: []
          },
          {
            title: 'Drinks',
            url: {
              story: {
                url: '/menu/drinks'
              }
            },
            items: []
          },
          {
            title: 'Meals',
            url: {
              story: {
                url: '/menu/pizza-meals'
              }
            },
            items: []
          }
        ]
      }
    ]
  }, {
    items: [
      {
        title: 'About Us',
        url: {
          story: {
            url: '/about-us'
          }
        },
        items: []
      },
      {
        title: 'Our Store Directory',
        url: {
          story: {
            url: '/stores'
          }
        },
        items: []
      },
      {
        title: 'Blog',
        url: {
          story: {
            url: '/blog'
          }
        },
        items: []
      },
      {
        title: 'Nutrition Information',
        url: {
          story: {
            url: '/nutrition-information'
          }
        },
        items: []
      },
      {
        title: 'Give Us Feedback',
        url: {
          story: {
            url: '/feedback'
          }
        },
        items: []
      }
    ]
  }, {
    items: [
      {
        title: 'Franchisees',
        url: {
          story: {
            url: '/franchise'
          }
        },
        items: []
      },
      {
        title: 'Careers',
        url: {
          story: {
            url: '/careers-apply-now'
          }
        },
        items: []
      },
      {
        title: 'Sitemap',
        url: {
          story: {
            url: '/sitemap'
          }
        },
        items: []
      },
      {
        title: 'FAQs',
        url: {
          story: {
            url: '/faq'
          }
        },
        items: []
      },
      {
        title: 'Privacy Policy',
        url: {
          story: {
            url: '/privacy'
          }
        },
        items: []
      },
      {
        title: 'Terms &amp; Conditions',
        url: {
          story: {
            url: '/terms'
          }
        },
        items: []
      }
    ]
  }
]
