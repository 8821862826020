import isEmpty from 'lodash/isEmpty'
import { defineStore } from 'pinia'
import { has } from '@/services/utility/object.utility'
import { localisationDefaults as LOCALISATION_DEFAULTS } from '@/stores/helpers/localisation.defaults'

export const useLocalisationStore = defineStore('localisation', {
  state: () => ({
    datetimeSelector: structuredClone(LOCALISATION_DEFAULTS.datetimeSelector),
    locator: structuredClone(LOCALISATION_DEFAULTS.locator),
    disposition: structuredClone(LOCALISATION_DEFAULTS.disposition),
    redirect: '',
    successCallback: () => {},
    successCallbackParam: {},
    pickupModal: false,
    cancelLocatorChange: false,
    localisedModal: structuredClone(LOCALISATION_DEFAULTS.localisationModal),
    showPostcodeError: false
  }),

  getters: {
    datetimeSelectorActive: state => state.datetimeSelector.active,
    datetimeSelectorStore: state => state.datetimeSelector.store,
    datetimeSelectorSchedule: state => state.datetimeSelector.schedule,
    datetimeSelectorFulfilmentType: state => state.datetimeSelector.fulfilmentType,
    datetimeSelectorAddress: state => state.datetimeSelector.address,
    datetimeSelectorCurrentlyTrading: state => state.datetimeSelector.currentlyTrading,
    datetimeSelectorShowWarning: state => state.datetimeSelector.showWarning,
    datetimeSelectorDisableCloseOption: state => state.datetimeSelector.disableCloseOption,
    datetimeSelectorValidSession: state => state.datetimeSelector.validSession,
    datetimeSelectorDisableNotifications: state => state.datetimeSelector.disableNotifications,
    datetimeSelectorRequiresRefetching: state => isEmpty(state.datetimeSelector.store),
    datetimeSelectorAddressEmpty: state => isEmpty(state.datetimeSelector.address),
    datetimeSelectorMessage: state => state.datetimeSelector.message,
    locatorEditMode: state => state.locator.editMode,
    locatorTabIndex: state => state.locator.tabIndex,
    locatorShowValidationMessage: state => state.locator.showValidationMessage,
    dispositionOrderDueAt: state => state.disposition.orderDueAt,
    showLocalisedModal: state => state.localisedModal.active
  },

  actions: {
    setDatetimeSelectorState (payload) {
      this.datetimeSelector = {
        ...this.datetimeSelector,
        ...payload
      }
    },
    resetDatetimeSelectorState () {
      this.datetimeSelector = {
        ...this.datetimeSelector,
        ...LOCALISATION_DEFAULTS.datetimeSelector
      }
    },
    activateDatetimeSelectorModal(payload) {
      this.datetimeSelector = {
        ...this.datetimeSelector,
        active: true,
        disableCloseOption: !has('disableCloseOption', payload) ? false : payload.disableCloseOption
      }
    },
    deactivateDatetimeSelectorModal () {
      this.datetimeSelector = {
        ...this.datetimeSelector,
        ...LOCALISATION_DEFAULTS.datetimeSelector
      }
    },
    setLocatorState (payload) {
      this.locator = {
        ...this.locator,
        ...payload
      }
    },
    resetLocatorState ()  {
      this.locator = {
        ...this.locator,
        ...LOCALISATION_DEFAULTS.locator,
        tabIndex: this.locatorTabIndex
      }
    },
    invokeLocatorSuccessCallback (payload) {
      const callback = this.successCallback
      const callbackParam = this.successCallbackParam
      if (callback) {
        if (callbackParam) {
          callback(callbackParam)
        } else {
          callback(payload)
        }
      }

      this.successCallback = () => {}
    },
    setSuccessCallback (payload) {
      this.successCallback = payload
    },
    setDispositionState (payload) {
      this.disposition = {
        ...this.disposition,
        ...payload
      }
    },
    resetDispositionState () {
      this.disposition = {
        ...this.disposition,
        ...LOCALISATION_DEFAULTS.disposition
      }
    },
    setRedirectState (payload) {
      this.redirect = payload
    },
    setSuccessCallbackParam (payload) {
      this.successCallbackParam = payload
    },
    setPickupModal (payload = true) {
      this.pickupModal = payload
    },
    setCancelLocatorChange (payload = true) {
      this.cancelLocatorChange = payload
    },
    setLocalisationModal (payload) {
      this.localisedModal = {
        ...this.localisedModal,
        ...payload
      }
    },
    resetLocalisationModal () {
      this.localisedModal = {
        ...this.localisedModal,
        ...LOCALISATION_DEFAULTS.localisationModal
      }
    },
    setShowPostcodeError (payload) {
      this.showPostcodeError = payload
    }
  }
})
