import MobileSpinner from '@/MobileSpinner.vue'
import capitalize from 'lodash/capitalize'
import { App } from '@capacitor/app'
import { AppUpdate, AppUpdateAvailability, AppUpdateResultCode } from '@capawesome/capacitor-app-update'
import { Braze } from '@pizzahutau/capacitor-plugin-braze'
import { Browser } from '@capacitor/browser'
import { Deploy } from 'cordova-plugin-ionic'
import { Preferences } from '@capacitor/preferences'
import { createApp, h } from 'vue'
import { dictionary } from '@/config/dictionary.config'
import { isAndroidApp, isiOsApp } from '@/services/utility/capacitor.utility'
import { isValidUrl } from '@/services/utility/string.utility'

export const DEFAULT_APPFLOW_CHANNEL = capitalize(import.meta.env.VITE_ENV)

/**
 * Checks for available app updates and triggers the update process if an update is available.
 *
 * @return {Promise<void>} Resolves when the update process is complete, or if no update is available.
 */
export async function inAppUpdates (): Promise<void> {
  try {
    const result = await AppUpdate.getAppUpdateInfo()

    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return
    }

    if (result.immediateUpdateAllowed && isAndroidApp()) {
      const performImmediateUpdate = await AppUpdate.performImmediateUpdate()

      if (
        performImmediateUpdate.code === AppUpdateResultCode.CANCELED ||
        performImmediateUpdate.code === AppUpdateResultCode.FAILED
      ) {
        await AppUpdate.openAppStore()
      }
    }

    if (isiOsApp()) {
      await AppUpdate.openAppStore({
        country: 'au'
      })
    }
  } catch (err) {
    console.error(err)
  }
}

/**
 * Triggers live updates for the application by checking for available updates,
 * downloading and extracting the update, and then reloading the application.
 *
 * @param {string} channel - The channel to use for live updates (defaults to DEFAULT_APPFLOW_CHANNEL)
 * @return {Promise<void>}
 */
export async function liveUpdates (channel: string = DEFAULT_APPFLOW_CHANNEL, preventReload: boolean | null = null): Promise<void> {
  console.log('preventReload', preventReload)

  if (preventReload === true) {
    return
  }

  try {
    await Deploy.configure({
      appId: '03cc38e9',
      updateMethod: 'none',
      channel
    })


    const update = await Deploy.checkForUpdate()
    console.log('update', update)
    if (!update.available) {
      await handleSavedUrl()

      return
    }

    await saveUrl()

    createApp({
      render () {
        return h(MobileSpinner)
      }
    }).mount('#app-root')

    const { value: downloadInProgress } = await Preferences.get({ key: dictionary.CAPACITOR.PREFERENCES.DOWNLOAD_IN_PROGRESS })
    console.log('downloadInProgress', downloadInProgress)
    if (JSON.parse(downloadInProgress || 'false') && preventReload !== null) {
      return
    }

    await Deploy.downloadUpdate(async (progress: number | undefined) => {
      console.log(`Downloading ${progress}%`)
      await Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.DOWNLOAD_IN_PROGRESS, value: JSON.stringify(true) })
    })

    await Deploy.extractUpdate((progress: number | undefined) => {
      console.log(`Extracting ${progress}%`)
    })

    await Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.DOWNLOAD_IN_PROGRESS, value: JSON.stringify(false) })

    await Deploy.reloadApp()
  } catch (err) {
    console.error(err)
  }
}

/**
 * Checks for an app update while the app is in the background, and triggers the update process if an update is available.
 *
 * @return {Promise<void>} Resolves when the update process is complete, or if no update is available.
 */
export async function backgroundUpdates(): Promise<void> {
  await setPreventLiveUpdateReload(false)

  App.addListener('pause', async () => {
    const { value } = await Preferences.get({ key: dictionary.CAPACITOR.PREFERENCES.PREVENT_RELOAD_IN_BACKGROUND })
    await liveUpdates(DEFAULT_APPFLOW_CHANNEL, JSON.parse(value || 'false'))
  })
}

/**
 * Sets whether the app should reload in the background if an update is available.
 *
 * This is primarily used to prevent the app from reloading when it is in the background,
 * and the user is not actively using it.
 *
 * @param {boolean} value Whether the app should reload in the background.
 *
 * @return {Promise<void>} Resolves when the setting is saved.
 */
export async function setPreventLiveUpdateReload (value: boolean): Promise<void> {
  await Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.PREVENT_RELOAD_IN_BACKGROUND, value: JSON.stringify(value) })
}

/**
 * Saves the current URL path so it is preserved after reload.
 *
 * @return {Promise<void>} Resolves when the URL has been saved.
 */
async function saveUrl (): Promise<void> {
  const saveUrl = window.location.pathname + window.location.search
  console.log('saveUrl', saveUrl)
  await Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.APP_INIT_URL, value: saveUrl })
}

/**
 * Retrieves and redirects to a saved URL if it exists, then removes the saved URL.
 *
 * @return {Promise<void>} Resolves when the redirect is complete, or if no saved URL exists.
 */
async function handleSavedUrl (): Promise<void> {
  const initUrl = await Preferences.get({ key: dictionary.CAPACITOR.PREFERENCES.APP_INIT_URL })
  await Preferences.remove({ key: dictionary.CAPACITOR.PREFERENCES.APP_INIT_URL })
  console.log('initUrl', initUrl)
  if (initUrl.value && initUrl.value !== '/') {
    window.location.replace(initUrl.value)
  }
}

/**
 * Handle App Deep-linking - Uses https domain for deep-linking (e.g. https://pizzahut.com.au)
 * Will open External Browser if not match is made.
 * @param {string} uriWebSecondary - Host Url from discovery api URI_WEB_SECONDARY
 */
export function deepLink (uriWebSecondary: string) {
  const handleDeeplink = (linkUrl: string, hostUrl: string) => {
    if (new URL(linkUrl).hostname === new URL(hostUrl).hostname && isValidUrl(linkUrl)) {
      const redirectUrl = `${new URL(linkUrl).pathname}${new URL(linkUrl).search}`
      console.log(`handleDeeplink.DeepLink - Matched ${linkUrl} with ${hostUrl} - Got redirectUrl: ${redirectUrl}`)

      window.location.replace(redirectUrl)
    } else {
      // Open external url
      console.log('handleDeeplink.ExternalLink', linkUrl)
      Browser.open({ url: linkUrl })
    }
  }

  App.addListener('appUrlOpen', (event: {url: string }) => handleDeeplink(event.url, uriWebSecondary))
  // @ts-ignore
  Braze.addListener('goToUri', (event: { uri: string}) => handleDeeplink(event.uri, uriWebSecondary))
}