import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import calendar from 'dayjs/plugin/calendar'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'

const matchOffset = /Z|[+-]\d\d:?\d\d/gi // +00:00 -00:00 +0000 -0000 or Z
const chunkOffset = /([+-]|\d\d)/gi

function offsetFromString (matcher, string) {
  const matches = (string || '').match(matcher)

  if (matches === null) {
    return null
  }

  const chunk = matches[matches.length - 1] || []
  const parts = (chunk + '').match(chunkOffset) || ['-', 0, 0]
  const minutes = +(parts[1] * 60) + Number(parts[2])

  return minutes === 0 ? 0 : parts[0] === '+' ? minutes : -minutes
}

const utcOffsetStrPlugin = (_o, c) => {
  const proto = c.prototype
  const oldUtcOffset = proto.utcOffset
  proto.utcOffset = function (offsetStr) {
    if (typeof offsetStr === 'string') {
      const offset = offsetFromString(matchOffset, offsetStr)
      return oldUtcOffset.call(this, offset, arguments[1])
    } else {
      return oldUtcOffset.call(this, offsetStr, arguments[1])
    }
  }
}

function isFunction (input) {
  return (
    (typeof Function !== 'undefined' && input instanceof Function) ||
    Object.prototype.toString.call(input) === '[object Function]'
  )
}

const calendarExtPlugin = (_o, c) => {
  const proto = c.prototype
  const oldCalendar = proto.calendar
  proto.calendar = function (date, format) {
    const newFormat = {}
    Object.keys(format).forEach(key => {
      newFormat[key] = isFunction(format[key]) ? format[key].call(this) : format[key]
    })
    return oldCalendar.call(this, date, newFormat)
  }
}

dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.extend(calendar)
dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(utcOffsetStrPlugin)
dayjs.extend(calendarExtPlugin)
