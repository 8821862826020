import axios from 'axios'

export default {
  CreateBasket ({ apiRoot, disposition }) {
    return axios.post(`${apiRoot}/api/v1/baskets`, disposition)
  },
  GetBasketById ({ apiRoot, basketId }) {
    return axios.get(`${apiRoot}/api/v1/baskets/${basketId}`)
  },
  UpdateBasket ({ apiRoot, payload }) {
    return axios.patch(`${apiRoot}/api/v1/baskets/${payload.basketId}`, payload.basket)
  },
  DeleteBasket ({ apiRoot, basketId }) {
    return axios.delete(`${apiRoot}/api/v1/baskets/${basketId}/basketItems`)
  },
  ExpireBasket ({ apiRoot, basketId }) {
    return axios.patch(`${apiRoot}/api/v1/baskets/${basketId}/expire`)
  },

  AddBasketItem ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${payload.basketId}/basketItems`, payload.basketItem)
  },
  DeleteBasketItem ({ apiRoot, payload }) {
    return axios.delete(`${apiRoot}/api/v1/baskets/${payload.basketId}/basketItems/${payload.basketItemId}`)
  },
  UpdateBasketItem ({ apiRoot, payload }) {
    return axios.patch(`${apiRoot}/api/v1/baskets/${payload.basketId}/basketItems/${payload.basketItem.basketItemId}`, payload.basketItem)
  },
  UpdateBasketItems ({ apiRoot, payload }) {
    return axios.patch(`${apiRoot}/api/v1/baskets/${payload.basketId}/basketItems/`, payload.basketItems)
  },
  SwapBasketItemParts ({ apiRoot, payload }) {
    return axios.patch(`${apiRoot}/api/v1/baskets/${payload.basketId}/basketItems/${payload.basketItemId}/swap`, {
      addBasketItemParts: payload.addBasketItemParts,
      removeProductIds: payload.removeProductIds,
      updateSingle: payload.updateSingle
    })
  },
  AddGroupedBasketItem ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${payload.basketId}/groupedBasketItems`, {
      ...payload.groupedBasketItem,
      isVoucher: payload.isVoucher,
      ...(payload.isVoucher && {
        voucherCode: payload.voucherCode,
        voucherId: payload.voucherId
      })
    })
  },
  UpdateGroupedBasketItem ({ apiRoot, payload }) {
    return axios.patch(`${apiRoot}/api/v1/baskets/${payload.basketId}/groupedBasketItems/${payload.groupedBasketItemId}`, {
      ...payload.groupedBasketItem,
      groupedBasketItemId: payload.groupedBasketItemId,
      isVoucher: payload.isVoucher,
      ...(payload.isVoucher && {
        voucherCode: payload.voucherCode,
        voucherId: payload.voucherId
      })
    })
  },
  DeleteGroupedBasketItem ({ apiRoot, payload }) {
    return axios.delete(`${apiRoot}/api/v1/baskets/${payload.basketId}/groupedBasketItems/${payload.groupedBasketItemId}`)
  },
  ValidateBasket ({ apiRoot, basketId }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/validate`)
  },
  ValidateOrder ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/validateOrder`, payload)
  },
  ApplyVoucher ({ apiRoot, basketId, voucherCode, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/applyvoucher/${voucherCode}`, payload)
  },
  RemoveVoucher ({ apiRoot, basketId, groupedBasketItemId }) {
    return axios.delete(`${apiRoot}/api/v1/baskets/${basketId}/groupedBasketItems/${groupedBasketItemId}/removevoucher`)
  },
  RemoveCartVoucher ({ apiRoot, basketId, voucherCode }) {
    return axios.delete(`${apiRoot}/api/v1/baskets/${basketId}/removeCartVoucher/${voucherCode}`)
  },
  OrderBasket ({ apiRoot, basketId, payload, apiEndpoint }) {
    axios.interceptors.response.use((res) => {
      return Promise.resolve(res)
    }, (err) => {
      if (err.response && err.response.status === 302) {
        return Promise.resolve(err.response)
      }

      return Promise.reject(err)
    })

    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/${apiEndpoint}`, payload)
  },
  InitialiseVco ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/initialiseVCO`, payload)
  },
  InitialiseFlex ({ apiRoot, basketId }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/initialiseFlex`)
  },
  InitialiseFlexApi ({ apiRoot, basketId }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/initialiseFlexApi`)
  },
  DeleteFlexApiToken ({ apiRoot, basketId, token }) {
    return axios.delete(`${apiRoot}/api/v1/baskets/${basketId}/token/${token}`)
  },
  VerifyFlex ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/verifyFlex`, payload)
  },
  InitialiseApplePay ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/initialiseApplePay`, payload)
  },
  InitialiseZipPay ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/initialiseZipPay`, payload)
  },
  CompleteApplePay ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/completeApplePay`, payload)
  },
  UpdatePayment ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/paymentInfo`, payload)
  },
  Authenticate3DS ({ url, payload }) {
    return axios.post(url, payload)
  },
  AddPreviousOrder ({ apiRoot, basketId, customerId, disposition, options }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/customers/${customerId}/reorder`, disposition, options)
  },
  CopyBasket ({ apiRoot, basketId, disposition }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/copyBasket`, disposition)
  },
  InitialisePayPal ({ apiRoot, basketId }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/InitialisePaypal`)
  },
  GetCustomerToken ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/customerToken`, payload)
  },
  PayerAuthEnroll ({ apiRoot, basketId, payload, referenceID }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/payerAuth/${referenceID}`, payload)
  },
  NotifyOrderError ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/notifyOrderError`, payload)
  },
  GetAfterpayOptions ({ apiRoot, basketId }) {
    return axios.get(`${apiRoot}/api/v1/baskets/${basketId}/apOptions`)
  },
  NotifyAfterpay ({ apiRoot, payload }) {
    return axios.patch(`${apiRoot}/api/v1/baskets/${payload.basketId}/apNotify`, payload)
  },
  NotifyZipPay ({ apiRoot, basketId }) {
    return axios.patch(`${apiRoot}/api/v1/baskets/${basketId}/zipNotify`)
  },
  InitialiseClickToPay ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/initialiseClickToPay`, payload)
  },
  CompleteClickToPay ({ apiRoot, basketId, payload }) {
    return axios.post(`${apiRoot}/api/v1/baskets/${basketId}/completeClickToPay`, payload)
  }
}
