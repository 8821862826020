import type { DiscoverResponse } from '@/types/dto/discover'
import axios, { type AxiosResponse } from 'axios'

const DISCOVER_API_URL = import.meta.env.VITE_API_DISCOVER

export default {
  getConfiguration (): Promise<AxiosResponse<DiscoverResponse>> {
    return axios.get(DISCOVER_API_URL)
  }
}
