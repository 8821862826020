import { useDiscoverStore } from '@/stores/discover'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

export const useTheme = () => {
  const discoverStore = useDiscoverStore()
  const { settings } = storeToRefs(discoverStore)
  
  const defaultTheme = settings.value?.WEBSITE_THEME === "NONE" ? undefined : settings.value?.WEBSITE_THEME
  const currentTheme = ref<string | undefined>(defaultTheme)

  watch(currentTheme, (val) => {
    if (val) {
      document.body.setAttribute('data-theme', val)
    }
  }, { immediate: true })

  return {
    currentTheme
  }
}