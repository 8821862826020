import axios from 'axios'

export default {
  GetSurvey ({ apiRoot, surveyId, orderId }) {
    return axios.get(`${apiRoot}/api/v1/survey/surveys/${surveyId}?orderId=${orderId}`)
  },

  SendResponse ({ apiRoot, surveyId, questionId, payload }) {
    return axios.post(`${apiRoot}/api/v1/survey/surveys/${surveyId}/questions/${questionId}/response`, payload)
  }
}
