import OrderApi from '@/services/api/order.api'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'

import { useCartStore } from '@/stores/cart'
import { useCustomerStore } from '@/stores/customer'
import { useDiscoverStore } from '@/stores/discover'

export const useOrderStore = defineStore('order', {
  state: () => ({
    pollInterval: null,
    pollStartDate: null,
    isPolling: false,
    recentOrders: [],
    isMostRecentOrder: true,
    contactless: false,
    lastOrderBasket: null,
    recentTrackOrders: []
  }),

  getters: {
    mostRecentOrder: state => state.recentOrders[0],
    showMostRecentOrder: state => state.recentOrders.length && state.isMostRecentOrder,
  },

  actions: {
    startPolling (payload) {
      if (!this.pollStartDate) {
        this.pollStartDate = dayjs()
      }

      this.pollInterval = setInterval(() => {
        payload.callback()
      }, payload.interval)

      this.isPolling = true
    },

    stopPolling () {
      clearInterval(this.pollInterval)
      this.pollInterval = null
      this.isPolling = false

      this.pollStartDate = null
    },

    async getOrderStatusByReference (orderReference) {
      const apiRoot = useDiscoverStore().uris.API_ORDER

      const response = await OrderApi.GetOrderStatusByReference({ apiRoot, orderReference })

      return response.data
    },

    async getOrderStatusById (orderId) {
      const apiRoot = useDiscoverStore().uris.API_ORDER

      const response = await OrderApi.GetOrderStatusById({ apiRoot, orderId })

      return response.data
    },

    async mockGetOrderStatusById (orderId, status = 'Pending', trackingType = 'Micros') {
      const apiRoot = useDiscoverStore().uris.API_ORDER

      const response = await OrderApi.MockGetOrderStatusById({ apiRoot, orderId, status, trackingType })

      return response.data
    },

    async trackOrderDetailsById (orderId) {
      try {
          const apiRoot = useDiscoverStore().uris.API_ORDER

        let sessionId = null
        const basketSessions = useCartStore().basketSessions
        if (basketSessions != null && basketSessions[orderId] != null) {
          sessionId = basketSessions[orderId].sid
        }
        const response = await OrderApi.TrackOrderDetailsById({ apiRoot, orderId, sessionId })
        return response.data
      } catch (error) {
        return null
      }
    },

    async getRecentOrders () {
      const apiRoot = useDiscoverStore().uris.API_ORDER

      const options = {
        headers: useCustomerStore().authHeader
      }

      const response = await OrderApi.GetRecentOrders({
        apiRoot,
        customerId: useCustomerStore().customerId,
        options
      })

      this.recentOrders = response.data

      return response.data
    },

    setRecentOrders (payload) {
      this.recentOrders = payload
    },

    setMostRecentOrderDisplay (payload) {
      this.isMostRecentOrder = payload
    },

    setContactless (payload) {
      this.contactless = payload
    },

    setLastOrderBasket (payload) {
      this.lastOrderBasket = payload
    },

    async trackOrdersByUserId (payload) {
      const apiRoot = useDiscoverStore().uris.API_ORDER

      const response = await OrderApi.TrackOrdersByUserId({
        apiRoot,
        userId: payload
      })

      this.recentTrackOrders = response.data

      return response.data
    }
  }
})
