import { pathOr, path, has } from '@/services/utility/object.utility'
import dayjs from 'dayjs'
import { checkbrowserOrDevice } from './storyblok.browser-device.utility'

export function prioritize (stories) {
  if (!stories || stories.length <= 1) {
    return stories
  }

  // Prioritize by Service Type
  stories = stories.sort((a, b) => a.content.service_type.length - b.content.service_type.length)

  const specialSort = (a, b, keys) => {
    if (!has(keys, a.content)) return 1
    else if (!has(keys, b.content)) return -1

    const aVal = pathOr('all', keys, a.content)
    const bVal = pathOr('all', keys, b.content)

    if (aVal.includes('all') && !bVal.includes('all')) return 1
    if (!aVal.includes('all') && bVal.includes('all')) return -1
    return bVal.length - aVal.length
  }

  // then by Store
  stories = stories.sort((a, b) => specialSort(a, b, ['store_codes', 'data']))

   // then by pod occasion
  stories = stories.sort((a, b) => specialSort(a, b, ['pods', 'occasion']))

   // then by pod sector
  stories = stories.sort((a, b) => specialSort(a, b, ['pods', 'sector']))

  // then by Device Type
  stories = stories.sort((a, b) => specialSort(a, b, ['browsers_devices']))

  return stories
}

export function formQuery (date, serviceType, storeId, pod) {
  // Get hour and add 0.1 so it is greater than hour
  const time = date.hour() + 0.1
  const occasion = pod?.occasion ?? ''
  const sector = pod?.sector ?? ''

  return {
    days: { any_in_array: date.day() },
    from_time: { 'lt_float': time },
    to_time: { 'gt_float': time },
    from_date: { 'lt_date': date.utc().format('YYYY-MM-DD HH:00') },
    to_date: { 'gt_date': date.utc().format('YYYY-MM-DD HH:00') },
    service_type: { any_in_array: serviceType },
    'store_codes.data': { any_in_array: `all,${storeId}` },
    'pods.occasion': { any_in_array: `all,${occasion}` },
    'pods.sector': { any_in_array: `all,${sector}` }
  }
}

export function addScheduleQueryPath (baseQuery, path = 'schedule.0') {
  return Object
    .entries(baseQuery)
    .reduce((query, [key, val]) => ({
      ...query,
      [`${path}.${key}`]: val
    }), {})
}

export function removeScheduleQueryPath (baseQuery, path = 'schedule.0') {
  return Object.entries(baseQuery)
    .reduce((query, [key, val]) => ({
      ...query,
      [key.replace(`${path}.`, '')]: val
    }), {})
}

/**
 * Applies custom filters to a story based on its schedule.
 * @param {Object} story Storyblok story
 * @param {string[]} schedulePath Path to schedule object from root story object
 * @returns true/false
 */
export function filterStory (story, schedulePath = ['content']) {
  let result = true

  const schedule = path(schedulePath, story)
  if (!schedule) {
    return result
  }

  // Filter by browser_device property
  result = checkbrowserOrDevice(schedule)
  return result
}

/**
 * Filter & sort Storyblok stories
 * @param {object[]} stories Storyblok stories array
 * @param {string[]} schedulePath
 * @returns Return filtered/sorted array
 */
export function filterStories (stories, schedulePath = ['content']) {
  const filteredStories = stories.filter(story => filterStory(story, schedulePath))
  return prioritize(filteredStories)
}

export function filterScheduledItem (story, query, schedulePath = ['content']) {
  const schedule = path(schedulePath, story)
  if (!query || !schedule) return true

  if (!filterStory(story, schedulePath)) {
    return false
  }

  return Object.entries(query).every(([key, val]) => {
    const itemVal = path(key.split('.'), schedule)
    if (itemVal === undefined) return true

    let [queryType, queryVal] = Object.entries(val)[0]
    const types = {
      all_in_array: () => !itemVal || (itemVal.every(val => val === queryVal.toString())),
      any_in_array: () => !itemVal || (itemVal.includes(queryVal.toString())),
      'lt_date': () => !itemVal || dayjs(itemVal).isBefore(queryVal),
      'gt_date': () => !itemVal || dayjs(itemVal).isAfter(queryVal),
      'lt_float': () => !itemVal || (Number(itemVal) < queryVal),
      'gt_float': () => !itemVal || (Number(itemVal) > queryVal)
    }

    // Handle array in string format
    if (queryType.includes('array') && queryVal.toString().includes(',')) {
      queryVal = queryVal.split(',')
      types.all_in_array = () => !itemVal || (itemVal.every(val => queryVal.includes(val)))
      types.any_in_array = () => !itemVal || (itemVal.some(val => queryVal.includes(val)))
    }

    const result = types[queryType]?.()
    return result
  })
}
