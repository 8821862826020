import { defineStore } from 'pinia'

export const useUpsellStore = defineStore('upsell', {
  state: () => ({
    cartUpsells: []
  }),

  getters: {

  },

  actions: {
    setCartUpsells (payload) {
      this.cartUpsells = payload
    }
  }
})
