function Recaptcha () {
  if (!(this instanceof Recaptcha)) {
    return new Recaptcha()
  }

  this.siteKey = null

  this.init = async (siteKeyV3) => {
    this.siteKey = siteKeyV3
    return new Promise(resolve => {
      const id = 'recaptcha_script_id'
      const el = document.getElementById(id)
      if (el) return resolve()
      const script = document.createElement('script')
      script.id = id
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKeyV3}`
      script.async = true
      script.defer = true

      script.addEventListener('load', waitForScriptToLoad(() => resolve(script)))

      document.head.appendChild(script)
    })
  }

  this.isReady = async (siteKeyV3 = this.siteKey) => {
    if (!window.grecaptcha) return this.init(siteKeyV3)
    else {
      return new Promise(resolve => window.grecaptcha.ready(() => resolve(true)))
    }
  }

  this.execute = async (siteKeyV3, action) => {
    if (await this.isReady(siteKeyV3)) {
      return window.grecaptcha.execute(siteKeyV3, { action })
    }
  }

  this.render = async (siteKeyV2, container, callback) => {
    if (await this.isReady()) {
      return window.grecaptcha.render(container, { sitekey: siteKeyV2, callback })
    }
  }

  this.reset = async () => {
    if (await this.isReady()) {
      try {
        return window.grecaptcha.reset()
      } catch (err) {
        console.error(err)
      }
    }
  }

  this.getResponse = async () => {
    if (await this.isReady()) {
      return window.grecaptcha.getResponse()
    }
  }
}

function waitForScriptToLoad (callback) {
  if (window.grecaptcha === undefined) {
    setTimeout(() => {
      waitForScriptToLoad(callback)
    }, 25)
  } else {
    window.grecaptcha.ready(() => callback())
  }
}

export default new Recaptcha()
