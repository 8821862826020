<script>
import { Remarkable } from 'remarkable'
import { h } from 'vue'

const md = new Remarkable()
md.set({ html: true })

const getSlotChildrenText = (children) => {
  return children.map(node => {
    if (!node.children || typeof node.children === 'string') {
      return node.children || ''
    } else if (Array.isArray(node.children)) {
      return getSlotChildrenText(node.children)
    } else if (typeof node.children === 'function') {
      return getSlotChildrenText(node.children())
    }
    return ''
  }).join('').trim()
}

export default {
  render () {
    const defaultSlot = this.$slots.default
    const text = defaultSlot ? getSlotChildrenText(defaultSlot()) : ''
    const innerHTML = md.render(text)
    return h('article', { innerHTML })
  }
}
</script>

<style lang="scss" scoped>
:deep(img) {
  width: 100%;
}
</style>
