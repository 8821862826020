import MarketingApi from '@/services/api/marketing.api'
import { CacheController } from '@/controllers/cache.controller'
import { config } from '@/config/global.config'
import { defineStore } from 'pinia'
import { upsell as defaults } from '@/stores/helpers/upsell.defaults'

import { useCacheStore } from '@/stores/cache'
import { useDiscoverStore } from '@/stores/discover'
import { useCartStore } from '@/stores/cart'

export const useMarketingStore = defineStore('marketing', {
  state: () => ({
    upsell: {
      popup: {
        active: false,
        type: '',
        upsell: {},
        basketItem: {},
        callback: () => {}
      },
      dismissals: 0,
      interactions: 0,
      redemptions: 0
    }
  }),

  getters: {
    popup: state => state.upsell.popup,
    upsellDismissals: state => state.upsell.dismissals,
    upsellRedemptions: state => state.upsell.redemptions,
    upsellsLocked: state => state.upsell.redemptions >= defaults.redemptionThreshold || (state.upsell.dismissals + state.upsell.interactions) >= defaults.dismissalThreshold
  },

  actions: {
    async getUpsells (payload) {
      const apiRoot = useDiscoverStore().uris.API_MARKETING

      const response = await MarketingApi.GetUpsells({
        apiRoot,
        payload: {
          storeCode: useCartStore().storeInfo.storeId,
          fulfilmentDateTime: useCartStore().basket.orderDueAt,
          fulfilmentType: useCartStore().basketServiceType,
          ...payload
        }
      })

      useCacheStore().setCache({
        expires_at: CacheController.getTimeInXMinutes(config.CACHE_DURATION_IN_MINUTES),
        cache: {
          upsells: response.data?.upsells,
          productId: payload.productId,
          trigger: payload.trigger
        },
        cacheType: 'upsells'
      })

      return response.data
    },
    toggleUpsellPopup (payload) {
      if (payload.type === '') {
        throw new Error('`type` of `popup` or `poster` must be provided')
      }

      if (payload.active === undefined || payload.active === null) {
        payload.active = true
      }

      if (payload.active) {
        this.upsell.popup.active = payload.active
        this.upsell.popup.type = payload.type
        this.upsell.popup.upsell = payload.upsell
        this.upsell.popup.basketItem = payload.basketItem
        this.upsell.popup.callback = payload.callback
      } else {
        this.upsell.popup.active = false
        this.upsell.popup.type = ''
        this.upsell.popup.upsell = {}
        this.upsell.popup.basketItem = {}
        this.upsell.popup.callback = () => {}
      }
    },
    incrementUpsellRedemptions () {
      this.upsell.redemptions = this.upsell.redemptions + 1
    },
    incrementUpsellInteractions () {
      this.upsell.interactions = this.upsell.interactions + 1
    },
    incrementUpsellDismissals () {
      this.upsell.dismissals = this.upsell.dismissals + 1
    },
    resetUpsellData () {
      this.upsell.popup.active = false
      this.upsell.popup.type = ''
      this.upsell.popup.upsell = {}
      this.upsell.popup.basketItem = {}
      this.upsell.popup.callback = () => {}
      this.upsell.dismissals = 0
      this.upsell.interactions = 0
      this.upsell.redemptions = 0
    }
  }
})
