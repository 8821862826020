import axios from 'axios'
// import store from '@/store/'
import router from '@/router'
import { config } from '@/config/global.config'
import { SessionController } from '@/controllers/session.controller'
import { UiController } from '@/controllers/ui.controller'
import { ContentController } from '@/controllers/content.controller'
import { TrackingController } from '@/controllers/tracking.controller'

import { useCartStore } from '@/stores/cart'
import { useDiscoverStore } from '@/stores/discover'
import { useCustomerStore } from '@/stores/customer'
import { useTrackingStore } from '@/stores/tracking'
import { useLocalisationStore } from '@/stores/localisation'

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const customerStore = useCustomerStore()

  const response = error?.response
  const status = response?.status?.toString()
  if (status) {
    if (status.charAt(0) === '4') {
      switch (Number(status)) {
        case 401:
          customerStore.checkIfAuthIsExpired()
          break
        case 403:
          handleLoginModal(error)
          break
        case 410:
          handleExpiredBasket(error)
          break
        case 412:
          handleInvalidOrderDateTime(error)
          break
        default:
          break
      }
    } else if (status.charAt(0) === '5') {
      handleInternalError(response)
    }
  }

  return Promise.reject(error)
})

const handleLoginModal = error => {
  const discoverStore = useDiscoverStore()
  const customerStore = useCustomerStore()

  const URIS = discoverStore.uris
  const errorUrl = error?.config?.url

  if (errorUrl.includes(URIS.API_CUSTOMER)) {
    customerStore.showLoginModal({
      email: customerStore.profile?.email
    })
  }
}

const handleInvalidOrderDateTime = error => {
  const cartStore = useCartStore()

  if (error.response.data.code === config.ERROR_CODE.INVALID_DATETIME && router.currentRoute.value.name === 'order') {
    const payload = {
      serviceType: cartStore.basketServiceType,
      storeId: cartStore.storeInfo?.storeId
    }

    SessionController.saveSession({ payload, validSession: false, silent: true, resetCache: true })
  }

  // check if error is below minimum delivery
  const minimumDeliveryRegex = /\b(\w+)\s+below\s+(\w+)\s+minimum\s+delivery\b/
  if (error.response.data.code === config.ERROR_CODE.INVALID_BASKET) {
    // show minimum spend modal
    if (minimumDeliveryRegex.test(error.response.data.message)) {
      const minimumSpend = cartStore.minimumSpend
      const serviceType = cartStore.basketServiceType
      const basketTotal = cartStore.basketTotal
      UiController.showMinimumSpendModal({
        active: true,
        minimumSpend,
        totalOrder: basketTotal,
        serviceType: serviceType.toLowerCase()
      })
    } else {
      window.location.replace('/offers')
    }
  }
}

const handleInternalError = error => {
  try {
    const discoverStore = useDiscoverStore()
    const trackingStore = useTrackingStore()
    const localisationStore = useLocalisationStore()

    const URIS = discoverStore.uris
    const errors = trackingStore.errors
    const errorUrl = error?.config?.url
    const existingError = errors.find(err => err?.url === errorUrl)

    TrackingController.trackApiError({ ...error?.data,  url: error?.config?.url })

    // Continue the order even if there are errors on getCustomerToken endpoint
    if (new RegExp(`${URIS.API_CART}.*customerToken`).test(errorUrl)) {
      return
    }

    // Cart, Store, and Product API Error Handler
    if (
      errorUrl.includes(URIS.API_CART) ||
      errorUrl.includes(URIS.API_STORE) ||
      errorUrl.includes(URIS.API_PRODUCT)
    ) {
      // Check if error had occurred before at the same endpoint
      if (existingError) {
        // Hides Disposition Selector in case Internal Error occured during selection
        localisationStore.resetDatetimeSelectorState()
        router.push({ name: 'fatal' })
      } else {
        window.location.reload(true)
      }
    }
  } catch (error) {
    console.log(error)
  }
}

const handleExpiredBasket = (error) => {
  const options = {
    displaySuccess: true
  }

  if (error.response.data.code === config.ERROR_CODE.ORDER_FAILED) {
    options.displaySuccess = false

    UiController.showSnackbar({
      active: true,
      message: ContentController.dictionaryToMessage('BASKET.BASKET_LOCKED_ERROR'),
      blocking: true,
      hasCloseIcon: false,
      autoClose: false,
      theme: 'danger',
      okayButton: {
        active: true,
        text: 'Okay',
        theme: 'success',
        callback: () => {
          UiController.showSnackbar({
            active: false
          })
          window.location.replace('/')
        }
      }
    })
  } else if (error.response.data.code === config.ERROR_CODE.EXPIRED_BASKET) {
    const discoverStore = useDiscoverStore()
    const cartStore = useCartStore()
    // If updating disposition of an expired basket, use disposition sent in the request instead of the basket disposition
    const BASE_URL = discoverStore.uris?.API_CART
    const basketId = cartStore.basketId
    if (error.config.method === 'patch' && error.config.url === `${BASE_URL}/api/v1/baskets/${basketId}`) {
      const disposition = cartStore.disposition
      SessionController.handleExpiredSession(options, disposition)
      return
    }
  }

  SessionController.handleExpiredSession(options)
}

export { handleInvalidOrderDateTime }
