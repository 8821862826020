import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { isWebView, isAndroid, isIOS } from '@/services/utility/user-agent.utility.js'

export const isCapacitorNativePlatform = () => {
  return Capacitor.isNativePlatform()
}

export const getCapacitorPlatform = () => {
  return Capacitor.getPlatform()
}

export const isOldAndroidApp = () => {
  return (isWebView() && isAndroid() && Capacitor.getPlatform() === 'web')
}

export const isOldiOSApp = () => {
  return (isWebView() && isIOS() && Capacitor.getPlatform() === 'web')
}

export const isAndroidApp = () => {
  return Capacitor.getPlatform() === 'android'
}

export const isiOsApp = () => {
  return Capacitor.getPlatform() === 'ios'
}

export const triggerHaptics = () => {
  if (Capacitor.isNativePlatform() || navigator.vibrate) {
    Haptics.impact({ style: ImpactStyle.Heavy })
  }
}
