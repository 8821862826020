import { getCapacitorPlatform } from '@/services/utility/capacitor.utility.js'

export function resizeImage (source, options) {
  const supportsWebP = window.Modernizr?.webp
  if (!source || !source.includes('http')) {
    return supportsWebP ? 'https://content.pizzahut.com.au/f/49069/500x280/a8f717f96c/placeholder-card-bg.webp' : 'https://content.pizzahut.com.au/f/49069/500x280/262941c4c5/placeholder-card-bg.jpg'
  }

  // Format Storyblok Images as WebP if supported
  if (supportsWebP) {
    options = options.map(option => option.includes('filters') ? option.replace(/format[^:]*/, 'format(webp)') : option)
  }

  const img = new URL(source)
  const params = !options
    ? ''
    : typeof options === 'string'
      ? `/${options}`
      : `/${options.map(option => option).join('/')}`

  return `${img.protocol}//content.pizzahut.com.au${img.pathname}/m${params}`
}

export function replaceImageDomain (source) {
  if (!source) return undefined

  // Regular expression to match URLs without protocol and subdomain
  const urlRegex = /(?:^|\s)(\/\/[^\s]+)/g

  const content = source
    // Replace all storyblok to pizzahut
    .replaceAll('a.storyblok.com', 'content.pizzahut.com.au')
    //  Add protocol on img src
    .replace(/<img\s+src=["'](\/\/[^"']+)["']/gi, '<img src="https:$1"')
    // Replace URLs without protocol with URLs containing 'http://'
    .replace(urlRegex, (match) => {
      const url = match.substring(2) // Removing the leading double slashes
      return url.startsWith('http://') || url.startsWith('https://') ? match : 'https:' + match
    })

  if (getCapacitorPlatform() === 'ios') {
    // Change to jpg. webp not working on ios
    return content.replaceAll('format(webp)', 'format(jpg)')
  }

  return content
}
