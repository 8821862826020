import axios from 'axios'

export default {
  GetOrderStatusByReference ({ apiRoot, orderReference }) {
    const endpoint = `${apiRoot}/api/v1/order/status/reference/${orderReference}`
    return axios.get(endpoint)
  },
  GetOrderStatusById ({ apiRoot, orderId }) {
    const endpoint = `${apiRoot}/api/v1/order/${orderId}/status`
    return axios.get(endpoint)
  },
  TrackOrderDetailsById ({ apiRoot, orderId, sessionId }) {
    const endpoint = `${apiRoot}/api/v1/order/tracker`
    return axios.post(endpoint, { orderId, sessionId })
  },
  GetRecentOrders ({ apiRoot, customerId, options }) {
    const endpoint = `${apiRoot}/api/v1/order/orderHistory/${customerId}`
    return axios.get(endpoint, options)
  },
  TrackOrdersByUserId ({ apiRoot, userId }) {
    const endpoint = `${apiRoot}/api/v1/order/trackOrders/${userId}?hourLimit=8`

    return axios.get(endpoint)
  }
}
