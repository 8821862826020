import StoreApi from '@/services/api/store.api'
import xmlParser from 'fast-xml-parser'
import { defineStore } from 'pinia'

import { useDiscoverStore } from '@/stores/discover'

export const useStoreStore = defineStore('store', {
  state: () => ({
    promiseTimeAutomation: {
      deliveryTimeMinutes: 0,
      pickupTimeMinutes: 0,
      averageDeliveryTimeMinutes: null,
      averagePickupTimeMinutes: null,
      allowPromiseTimeAutomation: false,
      isAlgoPromiseTimeEnabled: false
    }
  }),

  getters: {

  },

  actions: {
    async getStoreByCode (code) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.GetStoreByCode({ apiRoot, code })

      return response.data
    },

    async getStoreByName (name) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.GetStoreByName({ apiRoot, name })

      return response.data
    },

    async getStoresByPostcode (payload) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.GetStoresByPostcode({ apiRoot, payload })

      return response.data
    },

    async getStoresBySuburbName (payload) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.GetStoresBySuburbName({ apiRoot, payload })

      return response.data
    },

    async getStoresByGeoLocation (payload) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.GetStoresByGeolocation({ apiRoot, payload })

      return response.data
    },

    async getAddressesByQuery (query) {
      const apiRoot = useDiscoverStore().uris.API_GEO

      const response = await StoreApi.GetAddressesByQuery({ apiRoot, query })

      const list = xmlParser.parse(response.data, {
        parseNodeValue: false
      })['soap:Envelope']['soap:Body'].QASearchResult.QAPicklist

      return list.PicklistEntry
    },

    async getAddressByMoniker (moniker) {
      const apiRoot = useDiscoverStore().uris.API_GEO

      const response = await StoreApi.GetAddressByMoniker({ apiRoot, moniker })
      return xmlParser.parse(response.data, {
        parseNodeValue: false
      })
    },

    async getStoreList (includeComingSoon = false) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.GetStoreList({ apiRoot, includeComingSoon })

      return response.data
    },

    async submitContactForm (payload) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.SubmitContactForm({ apiRoot, payload })

      return response.data
    },

    async submitFranchiseeForm (payload) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.SubmitFranchiseeForm({ apiRoot, payload })

      return response.data
    },

    async submitCareersForm (payload) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.SubmitCareersForm({ apiRoot, payload })

      return response.data
    },

    async validateGeolocation (payload) {
      const apiRoot = useDiscoverStore().uris.API_STORE

      const response = await StoreApi.GetValidateGeolocation({ apiRoot, payload })

      return response
    },

    async setPromiseTimeAutomation (payload) {
      this.promiseTimeAutomation = payload
    }
  }
})
