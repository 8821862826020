import { defineStore } from 'pinia'

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({
    active: false
  }),

  getters: {

  },

  actions: {
    toggleSidebar (payload) {
      this.active = payload
    }
  }
})
