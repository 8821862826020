import dayjs from 'dayjs'

import { UiController } from '@/controllers/ui.controller'
import { TrackingController } from '@/controllers/tracking.controller'

import { isToday } from '@/services/utility/date.utility'
import { dictionary } from '@/config/dictionary.config'

import { getCoordinates } from '@/services/helpers/customer-info.helper'
import { ContentController } from '@/controllers/content.controller'

import { pick } from '@/services/utility/object.utility'
import { GA4_EVENT } from '@/config/constants'

import { useStoreStore } from '@/stores/store'
import { useDiscoverStore } from '@/stores/discover'
import { useLocalisationStore } from '@/stores/localisation'
import { useCartStore } from '@/stores/cart'

export const StoreController = {
  computeStoreSchedule: function ({ tradingDays, suburbSurcharges, store, storeTime }) {
    const promiseTimeAutomation = pick([
      'deliveryTimeMinutes',
      'pickupTimeMinutes',
      'allowPromiseTimeAutomation',
      'isAlgoPromiseTimeEnabled',
      'averageDeliveryTimeMinutes',
      'averagePickupTimeMinutes'
    ], store)

    useStoreStore().setPromiseTimeAutomation(promiseTimeAutomation)

    const isAlgoPromiseTime = promiseTimeAutomation.allowPromiseTimeAutomation && promiseTimeAutomation.isAlgoPromiseTimeEnabled
    const averageDeliveryTimeMinutes = promiseTimeAutomation.averageDeliveryTimeMinutes
    const averagePickupTimeMinutes = promiseTimeAutomation.averagePickupTimeMinutes

    return this.computeStoreTradingDays(
      tradingDays,
      suburbSurcharges,
      {
        delivery: isAlgoPromiseTime && averageDeliveryTimeMinutes ? averageDeliveryTimeMinutes : promiseTimeAutomation.deliveryTimeMinutes,
        pickup: isAlgoPromiseTime && averagePickupTimeMinutes ? averagePickupTimeMinutes : promiseTimeAutomation.pickupTimeMinutes
      },
      storeTime
    )
  },

  /**
   * Generates an array of trading days containing a nested level of trading hours.
   *
   * @return  {Array}  Array of trading days
   * [
   *   {
   *     displayText: 'Today'
   *     isInCurrentDay: true
   *     value: '2019-02-25T15:45:33.4253015+11:00',
   *     fulfilmentTimes: []
   *   }
   * ]
   */
  computeStoreTradingDays: function (days, surcharges, leadTimes, storeTime) {
    const daySet = []

    days.forEach(day => {
      const fulfilmentTimes = this.computeStoreTradingHours(day.storeTime, day.tradingHours, leadTimes, surcharges, storeTime)

      if (fulfilmentTimes.length) {
        daySet.push({
          displayText: day.displayDate,
          isInCurrentDay: isToday(day.storeTime),
          value: day.storeTime,
          fulfilmentTimes
        })
      }
    }, this)

    return daySet
  },

  /**
   * Generates trading hour slot(s) containing computed trading hours for that slot.
   *
   * @param   {String}  storeTime     Store time
   * @param   {Object}  tradingHours  Trading hours for day
   * @param   {Object}  leadTimes     Delivery/pickup lead times
   *
   * @return  {Array}                Array containing delivery/pickup objects which contain fulfilment times
   */
  computeStoreTradingHours: function (storeTime, tradingHours, leadTimes, surcharges, currentStoreTime) {
    const tradingHoursSlots = []

    tradingHours.forEach(slot => {
      const deliverySet = []
      const pickupSet = []

      const settings = {
        earliestDelivery: dayjs(this.convertToDatetimeString(storeTime, slot.earliestDelivery)).utcOffset(storeTime).second(0),
        earliestPickUp: dayjs(this.convertToDatetimeString(storeTime, slot.earliestPickUp)).utcOffset(storeTime).second(0),
        latestDelivery: dayjs(this.convertToDatetimeString(storeTime, slot.latestDelivery)).utcOffset(storeTime).second(0),
        latestPickUp: dayjs(this.convertToDatetimeString(storeTime, slot.latestPickUp)).utcOffset(storeTime).second(0)
      }
      let surchargeTime = useCartStore().surchargeTime

      if (!surchargeTime) {
        const address = useLocalisationStore().datetimeSelectorAddress
        const surcharge = surcharges?.find(surcharge => surcharge.suburbName === address?.city)
        surchargeTime = surcharge?.adjustmentTimeMinutes || 0
      }

      leadTimes.delivery += surchargeTime

      let deliveryCounter = settings.earliestDelivery
      let pickupCounter = settings.earliestPickUp
      const deliveryAdjustmentMinutes = useCartStore().deliveryAdjustmentMinutes

      while (deliveryCounter.isSameOrBefore(settings.latestDelivery)) {
        const isAsap = this.computeAsapStatus(deliveryCounter.format(), leadTimes.delivery, currentStoreTime)
        deliveryCounter = isAsap ? deliveryCounter.add(surchargeTime, 'minutes') : deliveryCounter
        const value = deliveryCounter.format()
        const displayText = this.computeHoursDisplayText(value, isAsap, leadTimes.delivery, deliveryAdjustmentMinutes, true)

        deliverySet.push({ displayText, isAsap, value })

        if (deliveryCounter.isSameOrAfter(settings.latestDelivery)) {
          break
        }

        deliveryCounter = deliveryCounter.add(this.computeCounterIncrement(deliveryCounter, settings.latestDelivery), 'm')
      }

      while (pickupCounter.isSameOrBefore(settings.latestPickUp)) {
        pickupSet.push({
          displayText: this.computeHoursDisplayText(pickupCounter.format(), this.computeAsapStatus(pickupCounter.format(), leadTimes.pickup, currentStoreTime), leadTimes.pickup, deliveryAdjustmentMinutes),
          isAsap: this.computeAsapStatus(pickupCounter.format(), leadTimes.pickup, currentStoreTime),
          value: pickupCounter.format()
        })

        if (pickupCounter.isSame(settings.latestPickUp)) {
          break
        }

        pickupCounter = pickupCounter.add(this.computeCounterIncrement(pickupCounter, settings.latestPickUp), 'm')
      }

      tradingHoursSlots.push({
        delivery: deliverySet,
        pickup: pickupSet
      })
    })

    return tradingHoursSlots
  },

  /**
   * Returns whether a given time is considered an ASAP time based on the lead time
   *
   * @return  {Boolean}  Boolean
   */
  computeAsapStatus: function (time, leadTime, storeTime) {
    const theTime = dayjs(time).format()
    return dayjs(theTime).utcOffset(theTime).isSameOrBefore(dayjs(storeTime).utcOffset(theTime).add(leadTime, 'minute'), 'm')
  },

  /**
   * Returns the incremental number required to build the fulfilemnt time object
   *
   * @return  {Number}  Number
   */
  computeCounterIncrement: function (now, latest) {
    if (dayjs.duration(latest.diff(now)).asMinutes() < 15) {
      return latest.minute() - now.minute()
    }

    const nowInMinutes = now.minute()

    if (
      nowInMinutes === 15 ||
      nowInMinutes === 30 ||
      nowInMinutes === 45 ||
      nowInMinutes === 0
    ) {
      return 15
    } else {
      if (nowInMinutes < 15) {
        return 15 - nowInMinutes
      } else if (nowInMinutes > 15 && nowInMinutes < 30) {
        return 30 - nowInMinutes
      } else if (nowInMinutes > 30 && nowInMinutes < 45) {
        return 45 - nowInMinutes
      } else {
        return 60 - nowInMinutes
      }
    }
  },

  /**
   * Returns human-friendly string for fulfilment time
   *
   * @return  {String}  String
   */
  computeHoursDisplayText: function (time, isAsap, fulfilmentTime, deliveryAdjustmentMinutes, isDelivery = false) {
    let timeAdjustments = 0
    if (isDelivery && deliveryAdjustmentMinutes < 0) {
      timeAdjustments = fulfilmentTime - Math.abs(deliveryAdjustmentMinutes)
    } else if (isDelivery) {
      timeAdjustments = fulfilmentTime + deliveryAdjustmentMinutes
    } else {
      timeAdjustments = fulfilmentTime
    }

    const fulfilmentSlotDelivery = useDiscoverStore().settings?.FULFILMENT_SLOT_DELIVERY
    const fulfilmentSlotPickup = useDiscoverStore().settings?.FULFILMENT_SLOT_PICKUP

    let formattedTime = dayjs(time).utcOffset(time).format('hh:mm A')

    if (isDelivery && fulfilmentSlotDelivery) {
      formattedTime = `${formattedTime} - ${dayjs(time).add(parseInt(fulfilmentSlotDelivery), 'minute').utcOffset(time).format('hh:mm A')}`
    } else if (!isDelivery && fulfilmentSlotPickup) {
      formattedTime = `${formattedTime} - ${dayjs(time).add(parseInt(fulfilmentSlotPickup), 'minute').utcOffset(time).format('hh:mm A')}`
    }

    return isAsap ? `ASAP (${timeAdjustments} mins)` : formattedTime
  },

  /**
   * Returns a momentJs friendly date
   *
   * @return  {String}  String
   */
  convertToDatetimeString: function (storeTime, time) {
    let day = 0
    let computedStoreTime = storeTime
    let computedTime = time

    if (time === null) {
      return null
    }

    // Handle cases where `time` has prefixed + day value e.g `1.00:29:59`
    // which represents the time the next day
    if (time.indexOf('.') === 1) {
      day = time.split('.')[0]
      computedStoreTime = dayjs(storeTime).utcOffset(storeTime).add(day, 'd').format()
      computedTime = time.split('.')[1]
    }

    return computedTime ? `${computedStoreTime.split('T')[0]}T${computedTime.split('.')[0]}+${computedStoreTime.split('+')[1]}` : null
  },

  getStore: async function ({ payload }) {
    if (payload.serviceType.toLowerCase() === 'delivery') {
      const coordinates = getCoordinates(payload)
      if (coordinates?.longitude && coordinates?.latitude) {
        return this.getStoreByCoordinates({ coordinates, silent: false })
      }
      console.error('Missing coordinates to find store', payload)
    } else if (payload.serviceType.toLowerCase() === 'pickup') {
      if (payload.storeId) {
        return useStoreStore().getStoreByCode(payload.storeId)
      }
      console.error('Missing storeId to find store', payload)
    }
  },

  getAddressByMoniker: async function ({ moniker }) {
    // Get Address By Moniker returned by Experian
    const address = await useStoreStore().getAddressByMoniker(moniker)

    // Format Result
    const formattedAddressLocation = this.formatAddressLocation(address['soap:Envelope']['soap:Body'].Address.QAAddress.AddressLine)
    const formattedAddress = formattedAddressLocation.formattedAddress
    const coordinates = formattedAddressLocation.coordinates

    return {
      address: `${formattedAddress.addressLine1} ${formattedAddress.addressLine2} ${formattedAddress.addressLine3}`.trim(),
      city: formattedAddress.locality,
      state: formattedAddress.state,
      postalCode: formattedAddress.postcode,
      ...coordinates
    }
  },

  getStoreByCoordinates: async function ({ coordinates, silent = true }) {
    // Error Handler
    const handleError = message => {
      if (!silent) {
        UiController.showSnackbar({
          blocking: true,
          active: true,
          message,
          theme: 'danger',
          autoClose: false,
          hasCloseIcon: false,
          okayButton: {
            active: true,
            text: 'Okay',
            theme: 'light'
          }
        })
        // GA4 tracking
        TrackingController.trackGA4CustomEvent(GA4_EVENT.LOCALISATION_ERROR, {
          error: {
            type: 'localisation',
            message
          }
        })
        throw message
      }
    }

    try {
      // Get Stores by Coordinates
      const response = await useStoreStore().getStoresByGeoLocation({
        ...coordinates,
        isPickup: false
      })

      // Handle Response
      if (!response.stores.length) {
        if (+(window.abtests['abtest-localisation-modal-postcode-errors'])) {
          useLocalisationStore().setShowPostCodeError()
        } else {
          handleError(ContentController.dictionaryToMessage('DISPOSITION.NO_STORES_FOUND_FOR_ADDRESS'))
        }
      } else if (!response.stores[0].isPOSOnline) {
        handleError(dictionary.STORE.POS.OFFLINE.text)
      } else {
        // Get Store Details of first store returned
        return useStoreStore().getStoreByCode(response.stores[0].code)
      }
    } catch (err) {
      handleError(ContentController.dictionaryToMessage('DISPOSITION.NO_STORES_FOUND_FOR_ADDRESS'))
    }
  },

  isValidCoordinates: async function ({ payload, silent = true }) {
    UiController.showSpinner({
      loading: true
    })
    let isValidCoordinate = false
    // Error Handler
    const handleError = message => {
      if (!silent) {
        UiController.showSnackbar({
          blocking: true,
          active: true,
          message,
          theme: 'danger',
          autoClose: false,
          hasCloseIcon: false,
          okayButton: {
            active: true,
            text: 'Okay',
            theme: 'light'
          }
        })
        // GA4 tracking
        TrackingController.trackGA4CustomEvent(GA4_EVENT.LOCALISATION_ERROR, {
          error: {
            type: 'localisation',
            message
          }
        })
        throw message
      }
    }

    try {
      // Check if user coordinate is a No Go zone
      isValidCoordinate = await useStoreStore().validateGeolocation({
        ...payload,
        isPickup: false
      })
    } catch (err) {
      if (err?.response?.status === 406) {
        handleError(ContentController.dictionaryToMessage('DISPOSITION.NO_STORES_FOUND_FOR_ADDRESS'))
      }
      isValidCoordinate = false
    } finally {
      UiController.showSpinner({
        loading: false
      })
    }
    return isValidCoordinate
  },

  isStoreAvailabilityASAP: function (firstAvailableTime, fulfilmentType) {
    return !!(firstAvailableTime && (firstAvailableTime[fulfilmentType.toLowerCase()]?.length && firstAvailableTime[fulfilmentType.toLowerCase()]?.[0]?.isAsap))
  },

  formatAddressLocation (address) {
    const formattedAddress = {}

    address.forEach(chunk => {
      formattedAddress[chunk.Label] = chunk.Line
    })

    const coordinates = {
      longitude: formattedAddress.addressLevelLongitude || formattedAddress.streetLevelLongitude,
      latitude: formattedAddress.addressLevelLatitude || formattedAddress.streetLevelLatitude
    }

    return { formattedAddress, coordinates }
  },

  validateOrderDueAt (orderDueAt, serviceType, storeSchedule) {
    const storeDate = storeSchedule.find(x => dayjs(x.value).utcOffset(orderDueAt).isSame(dayjs(orderDueAt).utcOffset(orderDueAt), 'day'))
    return storeDate && storeDate.fulfilmentTimes.some(tradingPeriod => tradingPeriod[serviceType])
      ? storeDate.fulfilmentTimes.some(tradingPeriod => tradingPeriod[serviceType].find(time => dayjs(time.value).utcOffset(orderDueAt).isSame(dayjs(orderDueAt).utcOffset(orderDueAt))))
      : false
  },

  getStoreTimetable (store) {
    let fields = [
      { key: 'day', label: '' },
      { key: 'pickup' },
      { key: 'dineIn' },
      { key: 'delivery' }
    ]

    const items = store.tradingDays.map(x => {
      let pickup = store.store.canPickup
        ? x.tradingHours.map(trading => {
          return trading.openingTime && trading.closingTime ? `${this.formatTime(trading.openingTime)} - ${this.formatTime(trading.closingTime)}` : ''
        })
        : []
      pickup = this.combineTimes(pickup)
      let delivery = store.store.canDeliver
        ? x.tradingHours.map(trading => {
          return trading.deliveryOpeningTime && trading.deliveryClosingTime ? `${this.formatTime(trading.deliveryOpeningTime)} - ${this.formatTime(trading.deliveryClosingTime)}` : ''
        })
        : []
      delivery = this.combineTimes(delivery)
      let dineIn = x.dineInHours.map(trading => {
        return trading.openingTime && trading.closingTime ? `${this.formatTime(trading.openingTime)} - ${this.formatTime(trading.closingTime)}` : ''
      })
      dineIn = this.combineTimes(dineIn)
      return {
        day: x.displayDate,
        ...(pickup?.length && { pickup }),
        ...(dineIn?.length && { dineIn }),
        ...(delivery?.length && { delivery })
      }
    })

    fields = fields.filter(field => {
      return items.some(item => !!(item[field.key]?.[0]))
    })

    return {
      fields,
      items
    }
  },

  formatTime (time) {
    const parsedTime = time.split('.').filter(str => str.includes(':'))[0]
    return dayjs(parsedTime, 'HH:mm:ss').format('LT')
  },

  combineTimes (times) {
    if (times?.length > 1) {
      const firstTimes = times[0].split(' - ')
      const secondTimes = times[1].split(' - ')
      if (firstTimes[1] === secondTimes[0]) {
        times = [`${firstTimes[0]} - ${secondTimes[1]}`]
      }
    }
    return times
  }
}
