import dayjs from 'dayjs'

export const CacheController = {
  isCacheExpired: function (expiry) {
    if (expiry === 0) {
      return false
    } else if (expiry === null) {
      return true
    }

    return dayjs(expiry).isSameOrBefore(dayjs())
  },

  getTimeInXMinutes: function (minutes) {
    return dayjs().add(minutes, 'minutes').format()
  }
}
