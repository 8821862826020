import StoryblokClient from 'storyblok-js-client'

declare global {
  interface Window {
    Storyblok: any;
  }
}

export function storyblokClient (token: string) {
  const Storyblok = new StoryblokClient({
    accessToken: token,
    cache: {
      clear: 'auto',
      type: 'memory'
    }
  })

  Storyblok.setComponentResolver((component, blok) => {
    return `<component blok='${JSON.stringify(blok)}' is="${component}"></component>`
  })

  window.Storyblok = Storyblok
}
