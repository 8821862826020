import {
  curry,
  compose,
  toPairs,
  reduce,
  defaultTo,
  map,
  equals
} from '@/services/utility/fp.utility'

import {
  isFunction,
  isArray,
  isObject,
  isInteger,
  isNil
} from '@/services/utility/predicate.utility'

import {
  nth,
  head,
  tail,
  remove,
  update
} from '@/services/utility/array.utility'

import dayjs from 'dayjs'

export const has = curry((prop, object) => object ? Object.prototype.hasOwnProperty.call(object, prop) : false)
/**
 * Transform an object according to an dictionary/map of transformations
 * @func
 * @curried
 * @param {Object} transformations - a key/value pair object of transformations
 * @param {Object} source - input object to be transformed
 * @return {Object} result
 * */
export const evolve = curry(function evolve (transformations, source) {
  const result = isArray(source) ? [] : {}

  for (const key in source) {
    const transformation = transformations[key]
    result[key] = isFunction(transformation)
      ? transformation(source[key])
      : transformation && isObject(transformation)
        ? evolve(transformation, source[key])
        : source[key]
  }

  return result
})

/**
 * Returns value at given object path if present
 * @func
 * @curried
 * @param {Array<String>} paths
 * @param {Object} store
 * */
export const path = curry((paths, store) => {
  let val = store
  let i = 0
  let p

  while (i < paths.length) {
    if (val === null) {
      return
    }

    p = paths[i]
    val = typeof p === 'number' ? nth(p, val) : val[p]
    i += 1
  }

  return val
})

/**
 * Assign a value to an object property
 * @func
 * @curried
 * @param {String} property
 * @param {Any} value
 * @param {Object} data
 * @return {Object} data - with updated values
 * */
export const assoc = curry((property, value, data) => ({ ...data, [property]: value }))

/**
 * Assign a value to an object path
 * @func
 * @curried
 * @param {Array<String>} path
 * @param {Any} value
 * @param {Object} data
 * @return {Object} data - modified copy
 * */
export const assocPath = curry(function assocPath (path, value, data) {
  if (path.length === 0) return value

  const i = head(path)

  if (path.length > 1) {
    const next = (!isNil(data) && has(i, data))
      ? data[i]
      : isInteger(path[1]) ? [] : {}
    value = assocPath(tail(path), value, next)
  }

  if (isInteger(i) && isArray(data)) {
    const arr = [].concat(data)
    arr[i] = value
    return arr
  } else {
    return assoc(i, value, data)
  }
})

/**
 * Remove a property from an object
 * @func
 * @curried
 * @param {String} property
 * @param {Object} data
 * @return {Object} updated data object
 * */
export const dissoc = curry((property, data) => {
  const result = {}
  // use for-in loop for deep
  // copy including prototype
  // properties
  for (const key in data) {
    result[key] = data[key]
  }

  delete result[property]
  return result
})

/**
 * Remove a path from an object
 * @func
 * @curried
 * @param {Array<String>} path
 * @param {Object} data
 * @return {Object} updatea data object
 * */
export const dissocPath = curry(function dissocPath (path, data) {
  const hd = head(path)
  switch (path.length) {
    case 0:
      return data
    case 1:
      return isInteger(hd) && isArray(data) ? remove(hd, 1, data) : dissoc(hd, data)
    default: {
      const tl = tail(path)

      if (isNil(data[hd])) {
        return data
      } else if (isInteger(hd) && isArray(data)) {
        return update(hd, dissocPath(tl, data[hd]), data)
      } else {
        return assoc(hd, dissocPath(tl, data[hd]), data)
      }
    }
  }
})

/**
 * Merge tow objects into a new one
 *
 * @func
 * @curried
 * @param {Object} a
 * @param {Object} b
 * @param {Object}
 * */
export const merge = curry((a, b) => Object.assign({}, a, b))

/**
 * Create object fom a list of properites on an existing object
 *
 * @func
 * @curried
 * @param {Array<String>} keys
 * @param {Object} data
 * @return {Object}
 * */
export const pick = curry((keys, data) => {
  const result = {}
  for (const key of keys) {
    result[key] = data[key]
  }

  return result
})

/**
 * Get value from object by property name
 *
 * @func
 * @curried
 * @param {String} property
 * @param {Object} data
 * @return {*} data[property]
 * */
export const prop = curry((key, data) => {
  return path([key], data)
})

export const hasPath = curry((paths, object) => {
  if (paths.length === 0 || isNil(object)) {
    return false
  }

  let val = object
  let i = 0

  while (i < paths.length) {
    if (!isNil(val) && has(paths[i], val)) {
      val = val[paths[i]]
      i += 1
    } else {
      return false
    }
  }

  return true
})

/**
 * Make copy of object with only truthy values
 *
 * @func
 * @curried
 * @param {Object} target object
 * @return {Object} transformed object
 * */
export const fromTruthy = compose(
  reduce((a, [k, v]) => v || typeof v === 'boolean' ? { ...a, [k]: v } : a, {}),
  toPairs
)

/**
 * Returns value at given object path if present
 * Returns a given default value if not present
 *
 * @func
 * @curried
 * @param {*} default value
 * @param {Array<String>} path
 * @return {*} path value | default value
 *
 * */
export const pathOr = curry((defaultValue, objPath, obj) => isNil(obj)
  ? defaultValue
  : defaultTo(defaultValue, path(objPath, obj))
)

/**
 * Takes a specification and a test and returns a boolean evalution
 * if all properties on the test satisfy the spec
 *
 * @func
 * @curried
 * @param {Object} specification object
 * @param {Object} test object
 * @return {Boolean}
 * */
export const where = curry((specObj, testObj) => {
  for (const prop in specObj) {
    if (has(prop, specObj) && !specObj[prop](testObj[prop])) {
      return false
    }
  }

  return true
})

/**
 * Test if a given object contains values that match an object of criteria
 *
 * @func
 * @curried
 * @param {Object} specObj - object to match
 * @param {Object} testObj - object to test
 * @return {Boolean}
 * */
export const whereEq = curry((specObj, testObj) => {
  return where(map(equals, specObj), testObj)
})

/**
 * Build FromData from Object
 *
 * @param {Object} form - form
 * @return {Object}
 * */
export const buildFormData = (form) => {
  const formData = new FormData()

  for (const [parentKey, parentValue] of Object.entries(form)) {
    const normalizedParentValue = parentValue === null ? "" : parentValue
    if (parentKey.toLowerCase().includes('date') && normalizedParentValue) {
      formData.append(parentKey, dayjs(normalizedParentValue).format('YYYY-MM-DD'))
    } else if (typeof normalizedParentValue === 'object') {
      for (const [childKey, childValue] of Object.entries(normalizedParentValue)) {
        const normalizedChildValue = childValue === null ? "" : childValue
        formData.append(`${parentKey}[${childKey}]`, normalizedChildValue)
      }
    } else {
      formData.append(parentKey, normalizedParentValue)
    }
  }

  return formData
}
