import CartApi from '@/services/api/cart.api'
import { BasketController } from '@/controllers/basket.controller'
import { setCrashlyticsUserIdAndBasketId } from '@/services/utility/crashlytics.utility'
import { addBasketSession } from '@/services/helpers/cart.helper.js'
import { capitalize } from '@/services/utility/string.utility'
import { cart as defaults } from '@/stores/helpers/cart.defaults'
import { defineStore } from 'pinia'
import { has } from '@/services/utility/object.utility'
import { safeNumber } from '@/services/utility/number.utility'
import { dictionary } from '@/config/dictionary.config'
import Recaptcha from '@/services/helpers/recaptcha.helper'
import { useCustomerStore } from '@/stores/customer'
import { useDiscoverStore } from '@/stores/discover'
import { useAttributionStore } from '@/stores/attribution.js'

export const useCartStore = defineStore('cart', {
  state: () => ({
    basket: structuredClone(defaults),
    auth3dsData: {},
    stepUp3dsData: {},
    hasOrdered: false,
    processOrderData: {
      processed: false
    },
    disposition: {},
    contactless: false,
    payIn4: false,
    showCouponModal: false,
    showCartDeliverPickupToggleModal: false,
    cartSwitchDeliveryData: {},
    cartSwitchPickupData: {},
    cartErrors: [],
    productEditedBeforeDispo: {},
    clearProductEditedBeforeDispo: false,
    flexApiToken: '',
    flexSaveCard: false,
    flexCardVerificationNumber: '',
    basketSessions: {},
    flexSaveCardSuccess: null
  }),

  getters: {
    basketId: state => state.basket.basketId,
    basketItems: state => state.basket.basketItems,
    groupedBasketItems: state => state.basket.groupedBasketItems,
    basketMessages: state => state.basket.messages,
    serviceCharges: state => state.basket.serviceCharges,
    validBasket: state => state.basket.basketId !== null,
    emptyBasket: state => state.basket.basketItems.concat(state.basket.groupedBasketItems).length === 0,
    freeBasket: state => state.basket.basketTotal === 0,
    basketTotal: state => state.basket.basketTotal,
    basketServiceChargesTotal: state => state.basket.serviceCharges
      ? state.basket.serviceCharges.reduce((sum, charge) => sum + charge.total, 0)
      : 0,
    basketDeliveryChargeTotal: state => state.basket.serviceCharges
      ? state.basket.serviceCharges.find(charge => charge.serviceChargeType === 'Delivery') !== undefined
        ? state.basket.serviceCharges.find(charge => charge.serviceChargeType === 'Delivery').total
        : 0
      : 0,
    basketSubTotal: state => state.basket.basketSubTotal,
    basketTotalItems: state => state.basket.basketItems.length || state.basket.groupedBasketItems.length
      ? state.basket.basketItems.concat(state.basket.groupedBasketItems).reduce((sum, items) => sum + items.quantity, 0)
      : 0,
    basketDiscount: state => state.basket.discount,
    totalSavings: state => state.basket.totalSavings,
    isDelivery: state => state.basket.serviceType.toLowerCase() === 'delivery',
    isPickup: state => state.basket.serviceType.toLowerCase() === 'pickup',
    isAsap: state => state.basket.isAsap,
    basketServiceType: state => state.basket.serviceType,
    customerInfo: state => state.basket.customerInfo,
    storeInfo: state => state.basket.storeInfo,
    paymentInfo: state => state.basket.paymentInfo,
    paymentOptions: state => state.basket.storeInfo.paymentOptions,
    basketItemById: state => basketItemId => state.basket.basketItems
      .find(item => item.basketItemId === basketItemId),
    groupedBasketItemById: state => groupedBasketItemId => state.basket.groupedBasketItems
      .find(item => item.groupedBasketItemId === groupedBasketItemId),
    ignoreMinSpend: state => !!(state.basket.groupedBasketItems.find(x => x.ignoreMinSpend)),
    minimumSpend: state => safeNumber(state.basket.storeInfo[`minimum${capitalize(state.basket.serviceType)}OrderValue`]),
    meetsMinimumSpend: (state) => state.validBasket && (state.ignoreMinSpend || (state.minimumSpend <= safeNumber(state.basket.basketTotal))),
    hasItems: state => (state.basket.basketItems.length || state.basket.groupedBasketItems.length),
    upsellActive: state => state.basket.upsellActive,
    upsellCart: (state) => state.validBasket ? state.basket.upsells?.cart?.cart : null,
    upsellPoster: (state) => state.validBasket ? state.basket.upsells.upsellPoster : null,
    upsellProduct: (state) => state.validBasket ? state.basket.upsells.upsellProduct : null,
    surchargeTime: state => state.basket?.serviceCharges?.find(charge => charge.serviceChargeType === 'Suburb')?.timeInMinutes || 0,
    deliveryAdjustmentMinutes: state => state.basket.storeInfo.pod?.deliveryAdjustmentMinutes || 0,
    shortAddress: state => state.basket.serviceType === 'Delivery'
      ? `${state.basket.customerInfo.address}, ${state.basket.customerInfo.city}`
      : '',
    fullAddress: state => state.basket.serviceType === 'Delivery'
      ? `${state.basket.customerInfo.address}, ${state.basket.customerInfo.city}, ${state.basket.customerInfo.postalCode}, ${state.basket.customerInfo.state}`
      : '',
    orderDueAt: state => state.basket.orderDueAt,
    isPayIn4: state => state.payIn4,
    maxAlcoholUnitCount: state => state.basket.maxAlcoholUnits,
    isContactless: state => state.basket.storeInfo.isContactless,
  },

  actions: {
    setHasOrdered (payload = true) {
      this.hasOrdered = payload
    },

    setContactless (payload = true) {
      this.contactless = payload
    },

    setBasket (payload) {
      this.basket = {
        ...defaults,
        ...payload
      }
      setCrashlyticsUserIdAndBasketId()
    },

    setBasketSessions (payload) {
      const _date = new Date()
      const daym = 24 * 60 * 60 * 1000
      for (const _prop in this.basketSessions) {
        const _created = new Date(this.basketSessions[_prop].created)
        const dif = _date - _created
        if (dif >= daym) {
          delete this.basketSessions[_prop]
        }
      }
      this.basketSessions = {
        ...this.basketSessions,
        ...payload
      }
    },

    async createBasket (disposition) {
      const appType = useAttributionStore().appType
      const appVersion = useAttributionStore().appVersion
      disposition = {
        ...disposition,
        appType,
        appVersion
      }
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        // this.basketPayload = disposition

        const response = await CartApi.CreateBasket({ apiRoot, disposition })
        const basketSessions = addBasketSession(response.data.basketId, response.data.sessionId)

        this.setBasketSessions(basketSessions)

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },
    async getBasket (payload) {
      if (!has('handleError', payload)) {
        payload.handleError = true
      }

      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.GetBasketById({ apiRoot, basketId: payload.basketId })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        if (payload.handleError) {
          return BasketController.onCartApiCallError(error)
        }
      }
    },

    async updateBasket (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        // this.basketPayload = payload.basket

        const response = await CartApi.UpdateBasket({ apiRoot, payload })
        const basketSessions = addBasketSession(response.data.basketId, response.data.sessionId)

        this.setBasketSessions(basketSessions)

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async deleteBasket (basketId) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.DeleteBasket({ apiRoot, basketId })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async expireBasket (basketId) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.ExpireBasket({ apiRoot, basketId })

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async clearBasket () {
      this.setBasket({})
    },

    async addBasketItem (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      payload = {
        basketItem: payload,
        basketId
      }

      try {
        const response = await CartApi.AddBasketItem({ apiRoot, payload })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async deleteBasketItem (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.DeleteBasketItem({ apiRoot, payload })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async updateBasketItem (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.UpdateBasketItem({ apiRoot, payload })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async updateBasketItems (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.UpdateBasketItems({ apiRoot, payload })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async swapBasketItemParts (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.SwapBasketItemParts({ apiRoot, payload })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async addGroupedBasketItem (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.AddGroupedBasketItem({ apiRoot, payload })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async updateGroupedBasketItem (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.UpdateGroupedBasketItem({ apiRoot, payload })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async deleteGroupedBasketItem (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.DeleteGroupedBasketItem({ apiRoot, payload })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async validateBasket (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const response = await CartApi.ValidateBasket({
          apiRoot,
          basketId: payload
        })

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async validateOrder (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      try {
        const response = await CartApi.ValidateOrder({
          apiRoot,
          basketId,
          payload
        })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async orderBasket (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      const response = await CartApi.OrderBasket({
        apiRoot,
        apiEndpoint: payload.apiEndpoint,
        basketId,
        payload: payload.orderInfo
      })

      // Reset data
      this.processOrderData = { processed: false }

      if (response.data.origin) {
        this.auth3dsData = {
          authData: {
            redirectUrl: response.data.result.deviceDataCollectionURL,
            reasonCode: response.data.result.reasonCode,
            referenceID: response.data.result.referenceID,
            accessToken: response.data.result.accessToken,
            origin: response.data.origin
          },
          orderInfo: payload.orderInfo,
          use3DS: true
        }
      }

      if (response.status === 302) {
        return BasketController.authenticateCard()
      }

      return response.data
    },

    async applyVoucher ({ voucherCode, payload }) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basket = this.basket

      try {
        const response = await CartApi.ApplyVoucher({
          apiRoot,
          basketId: basket.basketId,
          voucherCode,
          payload
        })

        this.setBasket(response.data.basket)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },

    async removeVoucher (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      const response = await CartApi.RemoveVoucher({
        apiRoot,
        basketId: payload.basketId,
        groupedBasketItemId: payload.groupedBasketItemId
      })

      this.setBasket(response.data.basket)

      return response.data
    },

    async removeLevelCartVoucher (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART

      const response = await CartApi.RemoveCartVoucher({
        apiRoot,
        basketId: payload.basketId,
        voucherCode: payload.voucherCode
      })

      this.setBasket(response.data.basket)

      return response.data
    },

    async initialiseVco (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const { callid } = payload
      const response = await CartApi.InitialiseVco({
        apiRoot,
        basketId,
        payload: {
          callid
        }
      })

      this.setBasket(response.data)

      return response.data
    },
    async initialiseFlex () {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const response = await CartApi.InitialiseFlex({
        apiRoot,
        basketId
      })

      return response.data
    },
    async initialiseFlexApi () {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const response = await CartApi.InitialiseFlexApi({
        apiRoot,
        basketId
      })

      return response.data
    },
    async deleteFlexApiToken (token) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const response = await CartApi.DeleteFlexApiToken({
        apiRoot,
        basketId,
        token
      })

      // Clear the Flex API Token
      this.flexApiToken = ''

      return response.data
    },
    async initialiseZipPay (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const response = await CartApi.InitialiseZipPay({
        apiRoot,
        basketId,
        payload
      })

      return response.data
    },
    async verifyFlex (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const response = await CartApi.VerifyFlex({
        apiRoot,
        basketId,
        payload
      })

      return response.data
    },
    async initialiseApplePay (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      const response = await CartApi.InitialiseApplePay({
        apiRoot,
        basketId,
        payload
      })

      return response.data
    },
    async completeApplePay (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      const response = await CartApi.CompleteApplePay({
        apiRoot,
        basketId,
        payload
      })

      return response.data
    },
    async initialisePayPal () {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      const response = await CartApi.InitialisePayPal({
        apiRoot,
        basketId
      })

      return response.data
    },
    async completePayPal (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      const response = await CartApi.completePayPal({
        apiRoot,
        basketId,
        payload
      })

      return response.data
    },
    async setPaymentInfo (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const response = await CartApi.UpdatePayment({
        apiRoot,
        basketId,
        payload
      })

      this.setBasket(response.data)

      return response.data
    },
    async addPreviousOrder (orderBasketId) {
      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const options = {
          headers: useCustomerStore().authHeader
        }

        const disposition = {
          serviceType: this.basket.serviceType,
          orderDueAt: this.basket.orderDueAt,
          isAsap: this.isAsap,
          storeId: this.storeInfo.storeId,
          address: this.customerInfo.address,
          city: this.customerInfo.city,
          state: this.customerInfo.state,
          postalCode: this.customerInfo.postalCode
        }

        const response = await CartApi.AddPreviousOrder({
          apiRoot,
          basketId: orderBasketId,
          customerId: useCustomerStore().customerId,
          disposition,
          options
        })

        const basketSessions = addBasketSession(response.data.basketId, response.data.sessionId)

        this.setBasketSessions(basketSessions)
        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },
    async copyBasket (orderBasketId) {



      const apiRoot = useDiscoverStore().uris.API_CART

      try {
        const disposition = {
          serviceType: this.basket.serviceType,
          orderDueAt: this.basket.orderDueAt,
          isAsap: this.isAsap,
          storeId: this.storeInfo.storeId,
          address: this.customerInfo.address,
          city: this.customerInfo.city,
          state: this.customerInfo.state,
          postalCode: this.customerInfo.postalCode,
          customerId: useCustomerStore().customerId
        }

        const response = await CartApi.CopyBasket({
          apiRoot,
          basketId: orderBasketId,
          disposition
        })

        this.setBasket(response.data)

        return response.data
      } catch (error) {
        return BasketController.onCartApiCallError(error)
      }
    },
    setCustomerInfo (payload) {
      this.basket.customerInfo = {
        ...this.basket.customerInfo,
        ...payload
      }
    },
    setProcessOrderData (payload) {
      this.processOrderData = payload
    },
    async getCustomerToken (payload) {
      const discoverSettings = useDiscoverStore().settings
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      await Recaptcha.init(discoverSettings.GRECAPTCHA)
      const tokenV3 = await Recaptcha.execute(discoverSettings.GRECAPTCHA, dictionary.RECAPTCHA.ACTION.CUSTOMER_TOKEN)

      const response = await CartApi.GetCustomerToken({
        apiRoot,
        basketId,
        payload: {
          ...payload,
          tokenV3
        }
      })

      return response.data
    },
    async payerAuthEnroll (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const referenceID = this.auth3dsData.authData.referenceID

      const response = await CartApi.PayerAuthEnroll({
        apiRoot,
        basketId,
        payload,
        referenceID
      })

      this.stepUp3dsData = response.data

      return response.data
    },
    async clear3dsData () {
      this.auth3dsData = {}
    },
    async clearStepUp3dsData () {
      this.stepUp3dsData = {}
    },
    async notifyOrderError (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      try {
        const response = await CartApi.NotifyOrderError({
          apiRoot,
          basketId,
          payload
        })

        return response.data
      } catch (error) {
        return null
      }
    },
    setPayIn4 (payload = false) {
      this.payIn4 = payload
    },
    handleShowCouponModal () {
      this.showCouponModal = true
    },

    handleDismissCouponModal () {
      this.showCouponModal = false
    },
    handleShowCartDeliverPickupToggleModal () {
      this.showCartDeliverPickupToggleModal = true
    },
    handleDismissCartDeliverPickupToggleModal () {
      this.showCartDeliverPickupToggleModal = false
    },
    setCartSwitchDeliveryData (payload) {
      this.cartSwitchDeliveryData = payload
    },
    setCartSwitchPickupData (payload) {
      this.cartSwitchPickupData = payload
    },
    async getAfterpayOptions () {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId

      const response = await CartApi.GetAfterpayOptions({
        apiRoot,
        basketId
      })

      return response.data
    },
    async notifyAfterpay (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = payload?.basketId

      const response = await CartApi.NotifyAfterpay({
        apiRoot,
        basketId,
        payload
      })

      this.setBasket(response.data)

      return response.data
    },
    async notifyZipPay (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = payload?.basketId

      const response = await CartApi.NotifyZipPay({
        apiRoot,
        basketId
      })

      this.setBasket(response.data)

      return response.data
    },
    setCartErrors(payload = {}) {
      this.cartErrors = payload
    },
    setProductEditedBeforeDispo (payload = {}) {
      this.productEditedBeforeDispo = payload
    },
    setClearProductEditedBeforeDispo (payload = {}) {
      this.clearProductEditedBeforeDispo = payload
    },
    setFlexApiToken (payload = '') {
      this.flexApiToken = payload
    },
    setFlexSaveCard (payload = false) {
      this.flexSaveCard = payload
    },
    setFlexCardVerificationNumber (payload = '') {
      this.flexCardVerificationNumber = payload
    },
    setFlexSaveCardSuccess (payload = null) {
      this.flexSaveCardSuccess = payload
    },
    async initialiseClickToPay (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const response = await CartApi.InitialiseClickToPay({
        apiRoot,
        basketId,
        payload
      })

      return response.data
    },
    async completeClickToPay (payload) {
      const apiRoot = useDiscoverStore().uris.API_CART
      const basketId = this.basket.basketId
      const response = await CartApi.CompleteClickToPay({
        apiRoot,
        basketId,
        payload
      })
      return response.data
    }
  }
})
