import { randomHexString } from '@/services/utility/random.utility.js'

function initSDK (sdkUrl, config) {
  return new Promise((resolve) => {
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(config.clientId) // config.clientId
      resolve(window.amazon)
    }
    const scriptId = 'amazon-login-sdk'
    const el = document.getElementById(scriptId)
    if (!el) {
      const script = document.createElement('script')
      script.setAttribute('src', sdkUrl)
      script.setAttribute('async', true)
      script.setAttribute('defer', 'defer')
      script.setAttribute('id', scriptId)
      document.getElementsByTagName('head')[0].appendChild(script)
    } else {
      resolve()
    }
  })
}

function Auth () {
  if (!(this instanceof Auth)) {
    return new Auth()
  }

  this.load = (sdkUrl, config) => {
    return new Promise((resolve, reject) => {
      initSDK(sdkUrl, config)
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
    })
  }

  this.authorized = (config) => {
    return new Promise((resolve) => {
      const options = {
        scope: 'profile',
        scope_data: {
          profile: { essential: false }
        },
        pkce: true,
        popup: config.usePopup,
        state: encodeURIComponent(`security_token=${randomHexString(12)}&url=${window.location.href}`)
      }

      window.amazon.Login.authorize(options, config.redirectURI)
      resolve()
    })
  }

  this.logout = () => {
    window.amazon.Login.logout()
  }
}

export default new Auth()
