import { GA4_EVENT } from '@/config/constants'
import { SERVICE_TYPE } from '@/config/constants.js'
import { pickupMinutes, deliveryMinutes } from '@/services/helpers/fulfillmentTime.helper'

import { useCartStore } from '@/stores/cart'
import { useLocalisationStore } from '@/stores/localisation'

export const getLastPageProduct = product => {
  try {
    const pages = window && window.dataLayer ? window.dataLayer.filter(x => x.event === 'virtualPageView' && x.virtualPageView !== window.location.pathname) : []
    const category = product ? product.categories.find(category => category.isNavigation && category.isHomePage) : null

    const route = pages.length && !pages[pages.length - 1].virtualPageView.match(/^\/+[?]*/)
      ? pages[pages.length - 1].virtualPageView
      : category
        ? { name: 'menu-category', params: { category: category.code.toLowerCase() } }
        : { name: 'offers' }

    return route
  } catch (err) {
    console.log(err)
    return { name: 'offers' }
  }
}

export const getLastPage = ({ includeCurrentPage = false, excludeRegex = null } = { includeCurrentPage: false, excludeRegex: null }) => {
  if (window.dataLayer?.length) {
    let screenEvents = window.dataLayer.filter(x => x.event === GA4_EVENT.PAGE_VIEW).reverse()
    if (!includeCurrentPage) {
      screenEvents = screenEvents.filter(x => x.path.split(/[?#]/)[0] !== window.location.pathname)
    }
    if (excludeRegex) {
      screenEvents = screenEvents.filter(x => !excludeRegex.test(x.path))
    }
    return screenEvents[0]?.path
  }
}

export const checkLocaliseQueryParams = async (localiseParam) => {
  const basket = useCartStore().basket
  const hasStoreId = basket?.storeInfo?.storeId
  const isPickup = useCartStore().isPickup
  const isDelivery = useCartStore().isDelivery
  const isAsap = useCartStore().isAsap
  const additionalTime = useCartStore().surchargeTime
  const deliveryAdjustmentMinutes = useCartStore().deliveryAdjustmentMinutes

  if (!localiseParam) {
    return
  }

  if (hasStoreId) {
    if (localiseParam === SERVICE_TYPE.DELIVERY && isPickup) {
      await useCartStore().handleShowCartDeliverPickupToggleModal()
      await useCartStore().setCartSwitchPickupData({ storeId: basket?.storeInfo?.storeId, isAsap, pickupMinutes: pickupMinutes(), orderDueAt: basket.orderDueAt })
    }

    if (localiseParam === SERVICE_TYPE.PICKUP && isDelivery) {
      await useCartStore().handleShowCartDeliverPickupToggleModal()
      await useCartStore().setCartSwitchDeliveryData({ ...basket?.customerInfo, storeId: basket?.storeInfo?.storeId, additionalTime, deliveryAdjustmentMinutes, isAsap, deliveryMinutes: deliveryMinutes(), orderDueAt: basket.orderDueAt })
    }
  } else {
    await useLocalisationStore.setLocalisationModal({ active: true })
    const tabIndex = localiseParam === SERVICE_TYPE.DELIVERY ? 0 : 1
    await useLocalisationStore.setLocatorState({
      tabIndex,
      editMode: true,
      showValidationMessage: true
    })
  }
}

export const removeQueryParam = (query) => {
  const urlParam = new URLSearchParams(window.location.search)
  const queryParam = urlParam.get(query)?.toLowerCase()
  if (queryParam) {
    const urlWithoutParams = window.location.href.split('?')[0] // Get the URL without query params
    history.replaceState({}, document.title, urlWithoutParams)// Update the URL without reloading
  }
}
