// import store from '@/store/'
import { toValue, toCartLines } from '@/services/helpers/tracking.helper'
import { formatEventFirebaseEcommerceData } from '../services/utility/tracking.utility'
import { GA4_EVENT } from '@/config/constants'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { Capacitor } from '@capacitor/core'

import { useTrackingStore } from '@/stores/tracking'
import { useCustomerStore } from '@/stores/customer'
import { useCartStore } from '@/stores/cart'
import { useUserStore } from '@/stores/user'

export const TrackingController = {
  /**
   * Returns the screen, user and cart props.
   * Saves these values to pinia if required by caller.
   */
  trackView ({ view, save = false, logScreenEvent = true }) {
    const screen = this.getTrackingView(view)
    const payload = {
      screen: {
        ...screen,
        path: view.path
      },
      ...this.trackUser(),
      ...this.trackCart(),
      ...this.trackPlatform()
    }

    if (save) {
      useTrackingStore().setTracking(payload)
    }

    if (logScreenEvent) {
      this.trackEvent({
        event: 'screen.load.auto.dataLayer.load',
        ...payload
      })
    }

    return payload
  },

  /**
   * Returns the user object based on pinia values.
   */
  trackUser () {
    return {
      user: {
        status: useCustomerStore().isAuth ? 'logged-in' : 'logged-out',
        location: {
          zipcode: useCartStore().isPickup ? useCartStore().storeInfo?.postCode : useCartStore().customerInfo?.postalCode
        },
        customer: {
          id: useCustomerStore().isAuth ? useCustomerStore().customerId : null,
          status: useCustomerStore().isAuth && useCartStore().hasOrdered
            ? 'repeat'
            : useCustomerStore().isAuth
              ? 'new'
              : 'not-set'
        },
        device: {
          typeRollup: (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1) ? 'mobile' : 'desktop'
        }
      }
    }
  },

  getTrackingView (view) {
    const screen = {}
    switch (view.name) {
      case 'landing':
        screen.name = 'homepage.home'
        screen.type = 'homepage'
        break

      case 'offers':
        screen.name = 'product-list.deals'
        screen.type = 'product-list'
        break

      case 'product-redirect':
        screen.name = `product-details.${view.params?.type}.${view.params?.slug}`
        screen.type = 'product-details'
        break

      case 'product':
        screen.name = `product-details.${view.params.type}.${view.params.slug}`
        screen.type = 'product-details'
        break

      case 'meal':
        screen.name = `product-details.deals.multi-item.${view.params.slug}`
        screen.type = 'product-details'
        break

      case 'menu-category': // @TODOGTM - Add subcategories and product slugs too
        screen.name = `product-list.${view.params?.category}`
        screen.type = 'product-list'
        break

      case 'checkout':
        screen.name = 'checkout.details'
        screen.type = 'checkout'
        break

      case 'order':
        screen.name = 'checkout.confirmation'
        screen.type = 'checkout'
        break

      case 'account':
        screen.name = 'account.profile'
        screen.type = 'account'
        break

      case 'login':
        screen.name = 'account.login'
        screen.type = 'account'
        break

      case 'register':
        screen.name = 'account.register'
        screen.type = 'account'
        break

      case 'forgot-password':
        screen.name = 'account.forgot-password'
        screen.type = 'account'
        break

      case 'reset-password':
        screen.name = 'account.reset-password'
        screen.type = 'account'
        break

      case 'track-order':
        screen.name = 'account.track-order'
        screen.type = 'account'
        break

      case 'stores':
        screen.name = 'hut-page.stores-list'
        screen.type = 'hut-page'
        break

      case 'store':
        screen.name = 'hut-page.store'
        screen.type = 'hut-page'
        break

      case 'blog':
        screen.name = 'blog-post-list.blog'
        screen.type = 'blog-post-list'
        break

      case 'blog-post':
        screen.name = `blog-post.${view.params.slug}`
        screen.type = 'blog-post'
        break

      case 'cms-content':
        screen.name = `general-info.${view.params.slug}`
        screen.type = 'general-info'
        break

      case 'contact':
        screen.name = 'support-help.contact'
        screen.type = 'support-help'
        break

      case 'careers':
        screen.name = 'general-info.careers'
        screen.type = 'general-info'
        break

      case 'not-found':
        screen.name = 'error.404'
        screen.type = 'error'
        break
      case 'fatal':
        screen.name = 'error.fatal'
        screen.type = 'error'
        break
        // @TODOGTM Add missing cases here (content pages, blog, error pages etc)
      default:
        screen.name = view.name
        screen.type = view.name
        break
    }
    return screen
  },

  getBasicTrackingParams (view) {
    const screen = this.getTrackingView(view)
    let loggedInUser = useUserStore().hasUserId ? useUserStore().userId : null
    if (!loggedInUser || loggedInUser === '00000000-0000-0000-0000-000000000000') {
      loggedInUser = undefined
    }
    return {
      hut_id: useCartStore().storeInfo?.storeId || null,
      user_id: loggedInUser,
      disposition: useCartStore().isPickup ? 'collection' : useCartStore().isDelivery ? 'delivery' : null,
      loyalty_member: 'n',
      customer_type: useCustomerStore().isAuth && useCartStore().hasOrdered
          ? 'existing'
          : useCustomerStore().isAuth
              ? 'new'
              : '',
      screen_name: screen.name,
      localized: useCartStore().storeInfo?.postCode || useCartStore().customerInfo?.postalCode ? 'y' : 'n',
      content_group: screen.type,
      basket_id: useCartStore().basketId
    }
  },

  /**
   * Returns the list of items in the basket for ga4 tracking purpose
   * @param view
   * @returns {*[]}
   */
  getBasketTrackingItems () {
    // @GTM DATALAYER GA4
    const products = useCartStore().basketItems?.map((item, index) => {
      return {
        item_id: item.productId,
        item_name: item.name,
        item_variant: item.isCustomisable ? item.basketItemParts[0].sizeName : '',
        coupon: '',
        currency: 'AUD',
        discount: '',
        index,
        item_category: item.productType,
        item_list_id: 'cart',
        item_list_name: 'cart',
        quantity: item.quantity,
        price: item.totalPrice,
        location_id: useCartStore().storeInfo?.storeId
      }
    })

    const meals = useCartStore().groupedBasketItems?.map((item, index) => {
      return {
        item_id: item.productId,
        item_name: item.name,
        coupon: item.voucherCode,
        item_variant: item.isCustomisable ? item.basketItemParts[0].sizeName : '',
        currency: 'AUD',
        discount: '',
        index,
        item_category: item.productType,
        item_list_id: 'cart',
        item_list_name: 'cart',
        quantity: item.quantity,
        price: item.totalPrice,
        location_id: useCartStore().storeInfo?.storeId
      }
    })
    return [...products, ...meals]
  },

  /**
   * Returns the platform object based on pinia values.
   */
  trackPlatform () {
    return {
      platform: {
        type: 'website',
        name: 'pha',
        environment: import.meta.env.PROD ? 'production' : 'development',
        localeRegion: 'apac',
        localeCountry: 'au',
        localeLanguage: 'en',
        currency: 'aud'
      }
    }
  },

  /**
   * Pushes an event to the events array in the PHA_DV object. Saves to state.
   *
   * @return  {[type]}  [return description]
   */
  trackEvent (event) {
    useTrackingStore().setEvent(event)
  },

  /**
   * Separate method to identify legacy events
   * @param {string} event - Name of event
   * @param {*} data - Tracking data
   */
  async trackLegacyEvent (event, data = {}) {
    window.dataLayer.push({ event, ...data })
    if (Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.logEvent({
        name: event,
        params: formatEventFirebaseEcommerceData(data)
      })
    }
  },

  /**
   * Pushes an event to the events array in the dataLayer.
   *
   * @return null
   */
  async trackGA4Event (event, view, data = {}) {
    // Support legacy PHA_DL tracking
    this.reinvoke({ view, logScreenEvent: false })

    const params = {
      ...this.getBasicTrackingParams(view),
      ...data
    }

    window.dataLayer.push({ ecommerce: null }) // this is required so old ecommerce data will not interfere with new data
    window.dataLayer.push({ event, ...params })

    if (Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.logEvent({
        name: event,
        params: formatEventFirebaseEcommerceData(params)
      })
    }
  },

  /**
   * Track custom GA4 event
   * @param {string} event - Name of event
   * @param {*} data - Tracking Data
   */
  async trackGA4CustomEvent (event, data = {}) {
    window.dataLayer.push({ event, ...data })
    if (Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.logEvent({
        name: event,
        params: data
      })
    }
  },

  async trackGA4Screen (view) {
    const screen = this.getTrackingView(view)
    await this.trackGA4Event(GA4_EVENT.PAGE_VIEW, view, { path: view.fullPath })
    if (Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.setScreenName({
        screenName: screen.name
      })
    }
  },

  /**
   * Returns the cart object based on pinia values.
   */
  trackCart () {
    if (!useCartStore().validBasket) {
      return { cart: {} }
    }

    const basket = useCartStore().basket
    const deliveryCharge = useCartStore().basketDeliveryChargeTotal

    return {
      cart: {
        id: basket.basketId,
        currency: 'aud',
        checkoutStep: 1,
        shipping: {
          value: toValue(deliveryCharge),
          type: basket.serviceType,
          storeId: basket.storeInfo.storeId,
          deliveryTime: new Date(basket.orderDueAt).getTime()
        },
        value: toValue(basket.basketTotal),
        lines: toCartLines([...basket.basketItems, ...basket.groupedBasketItems])
      }
    }
  },

  trackApiError (error) {
    const { status, data } = error
    useTrackingStore().setError(error)

    // GA4 tracking
    this.trackGA4CustomEvent(GA4_EVENT.API_ERROR, {
      platform: {
        ...this.trackPlatform(),
        error: {
          status,
          type: data?.code,
          message: data?.message
        }
      }
    })
  },

  trackUtm (query) {
    // Parse UTM tracking values from query and store them
    Object.entries(query).forEach(([key, val]) => {
      if (key.match(/utm_.*/)) {
        window.sessionStorage.setItem(key, val)
      }
    })
  },

  getUtm () {
    const getVal = key => window.sessionStorage.getItem(`utm_${key}`)
    return {
      utmSource: getVal('source'),
      utmCampaign: getVal('campaign'),
      utmMedium: getVal('medium'),
      utmTerm: getVal('term'),
      utmContent: getVal('content')
    }
  },

  /**
   * Revinvokes the state by saving new values.
   * This is required for actions that do not yield a route change (e.g add to cart)
   * but it's vital to keep the data up-to-date.
   */
  reinvoke ({ view, save = true, logScreenEvent = true }) {
    this.trackView({
      view,
      save,
      logScreenEvent
    })
  },

  /**
   * Injects a script tag into the page containing the GTM source.
   *
   * @@@ WARNING @@@
   * THIS IS THE ORIGINAL SCRIPT SUPPLIED BY GTM.
   * DO NOT REMOVE.
   * DO NOT UPDATE.
   * @@@@@@@@@@@@@@@
   *
   * @return  {[type]}  [return description]
   */
  installGTM: function (w, d, s, l, i) {
    /* eslint-disable */
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });

    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';

    j.async = true;
    j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
    /* eslint-disable */
  },
}
