import {
  library
} from '@fortawesome/fontawesome-svg-core'

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'

import { faMapMarkerAlt as faMapMarkerAltSolid } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
import { faTimesCircle as faTimesCircleSolid } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faChevronLeft as faChevronLeftSolid } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight as faChevronRightSolid } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faUserCircle as faUserCircleSolid } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import { faStoreAlt as faStoreAltSolid } from '@fortawesome/pro-solid-svg-icons/faStoreAlt'
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons/faEye'
import { faEyeSlash as faEyeSlashSolid } from '@fortawesome/pro-solid-svg-icons/faEyeSlash'
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faShoppingCart as faShoppingCartSolid } from '@fortawesome/pro-solid-svg-icons/faShoppingCart'
import { faCreditCardFront as faCreditCardFrontSolid } from '@fortawesome/pro-solid-svg-icons/faCreditCardFront'
import { faMotorcycle } from '@fortawesome/pro-solid-svg-icons/faMotorcycle'
import { faTag as faTagSolid } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faInfoCircle as faInfoCircleSolid } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faTimes as faTimesSolid } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faLock as faLockSolid } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faDebug as faDebugSolid } from '@fortawesome/pro-solid-svg-icons/faDebug'
import { faChevronCircleRight as faChevronCircleRightSolid } from '@fortawesome/pro-solid-svg-icons/faChevronCircleRight'
import { faAngleUp as faAngleUpSolid } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faAngleDown as faAngleDownSolid } from '@fortawesome/pro-solid-svg-icons/faAngleDown'

import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle'
import { faPlusCircle as fasPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird'
import { faSpinnerThird as faSpinnerThirdDuo } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird'
import { faChevronLeft as faChevronLeftLight } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight as faChevronRightLight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faTimesCircle as faTimesCircleLight } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faExclamationTriangle as faExclamationTriangleLight } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faMoneyCheckEditAlt as faMoneyCheckEditAltLight } from '@fortawesome/pro-light-svg-icons/faMoneyCheckEditAlt'
import { faUnlockAlt as faUnlockAltLight } from '@fortawesome/pro-light-svg-icons/faUnlockAlt'
import { faHistory as faHistoryLight } from '@fortawesome/pro-light-svg-icons/faHistory'
import { faIdCard as faIdCardLight } from '@fortawesome/pro-light-svg-icons/faIdCard'
import { faTrashAlt as faTrashAltLight } from '@fortawesome/pro-light-svg-icons/faTrashAlt'
import { faTags as faTagsLight } from '@fortawesome/pro-light-svg-icons/faTags'
import { faCog as faCogLight } from '@fortawesome/pro-light-svg-icons/faCog'

import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { faMinus as fasMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'

import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons/faShoppingCart'
import { faShoppingBasket } from '@fortawesome/pro-regular-svg-icons/faShoppingBasket'
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons/faUserCircle'
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'
import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons/faArrowCircleRight'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faSearchPlus } from '@fortawesome/pro-regular-svg-icons/faSearchPlus'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faLocation } from '@fortawesome/pro-regular-svg-icons/faLocation'
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt'
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt'
import { faExclamationTriangle as faExclamationTriangleRegular } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign'
import { faStoreAlt as faStoreAltRegular } from '@fortawesome/pro-regular-svg-icons/faStoreAlt'
import { faStar as faStarRegular } from '@fortawesome/pro-regular-svg-icons/faStar'
import { faClipboard as faClipboardRegular } from '@fortawesome/pro-regular-svg-icons/faClipboard'
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faInfoCircle as falInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faQuestionCircle as falQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faExclamationCircle as faExclamationCircleRegular } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faWifiSlash as faWifiSlashRegular } from '@fortawesome/pro-regular-svg-icons/faWifiSlash'
import { faLocationArrow } from '@fortawesome/pro-regular-svg-icons/faLocationArrow'
import { faSlidersH as falSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH'
import { faFingerprint } from '@fortawesome/pro-regular-svg-icons/faFingerprint'
import { faUserUnlock } from '@fortawesome/pro-regular-svg-icons/faUserUnlock'

import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(
  faCreditCardFrontSolid,
  faMapMarkerAltSolid,
  faShoppingCart,
  faShoppingCartSolid,
  faShoppingBasket,
  faPlusCircle,
  fasPlusCircle,
  faMinusCircle,
  faUserCircleSolid,
  faUserCircle,
  faBars,
  faSpinnerThird,
  faPencil,
  faArrowCircleLeft,
  faArrowCircleRight,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSearchPlus,
  faQuestionCircle,
  faPlus,
  faMinus,
  faTimesCircle,
  faTimesCircleSolid,
  faTimesCircleLight,
  faTimesSolid,
  faTimes,
  faClock,
  faCheckCircle,
  faChevronLeftLight,
  faChevronRightLight,
  faChevronLeftSolid,
  faChevronRightSolid,
  faFacebook,
  faTwitter,
  faLocation,
  faLockAlt,
  faMapMarkerAlt,
  faExclamationTriangleLight,
  faExclamationTriangleRegular,
  faDollarSign,
  faStoreAltRegular,
  faStoreAltSolid,
  faEyeSolid,
  faEyeSlashSolid,
  faStarRegular,
  faStarSolid,
  faMoneyCheckEditAltLight,
  faUnlockAltLight,
  faHistoryLight,
  faIdCardLight,
  faMotorcycle,
  faTagSolid,
  faInfoCircleSolid,
  faLockSolid,
  faTrashAltLight,
  faDebugSolid,
  faClipboardRegular,
  faEnvelopeRegular,
  faChevronCircleRightSolid,
  faAngleUpSolid,
  faAngleDownSolid,
  faTagsLight,
  fasPlus,
  fasMinus,
  falTimes,
  falInfoCircle,
  falQuestionCircle,
  fasExclamationTriangle,
  faSpinnerThirdDuo,
  faExclamationCircleRegular,
  fasTrash,
  faWifiSlashRegular,
  faLocationArrow,
  falSlidersH,
  faCogLight,
  faFingerprint,
  faUserUnlock
)

export default {
  install: (app) => {
    app.component('FontAwesomeIcon', FontAwesomeIcon)
  }
}
