import { createApp, h } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Fatal from './Fatal.vue'
import sentryFullStoryPlugin from './vendor/SentryFullStory'

export function shutdown ({ reason }) {
  const app = createApp({
    render () {
      return h(Fatal, {
        failure: reason
      })
    }
  })
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/shared/Fatal.vue')
      }
    ]
  })
  app.use(router)
  app.use(sentryFullStoryPlugin)
  app.mount('#fatal-root')
}