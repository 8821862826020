const DEFAULT_CURRENCY_OPTIONS = {
  style: 'currency',
  currency: 'AUD',
  currencyDisplay: 'narrowSymbol'
}

const DEFAULT_LOCALE = 'au-AU'

export function toCurrency (num, options = DEFAULT_CURRENCY_OPTIONS) {
  if (isNaN(parseFloat(num))) {
    return num
  }

  const formatter = new Intl.NumberFormat(DEFAULT_LOCALE, options)

  return formatter.format(num)
}
