import { defaultTo, compose, map, fromPairs, toPairs } from '@/services/utility/fp.utility'
import { split, join, toLowerCase } from '@/services/utility/string.utility'

/**
 * Parse a query string into an object literal
 *
 * @func
 * @param {String}
 * @return {Object}
 * */
export const parseQueryString = compose(
  fromPairs,
  map(split('=')),
  split('&')
)

/**
 * Make a query string from an object literal
 *
 * @func
 * @param {Object}
 * @return {String}
 * */
export const makeQueryString = compose(
  join('&'),
  map(join('=')),
  toPairs
)

/**
 * Normalize an expected string value with a default
 *
 * @param {*}
 * @return {String}
 * */
export const normalizeQueryParam = compose(
  toLowerCase,
  defaultTo('')
)

/**
 * Get search query parameters from the URL
 *
 * @param {String} queryString
 * @return {Object}
 */
export const getURLSearchQueryParams = () => {
  const urlQueryStrings = new URLSearchParams(window.location.search)
  const query = Object.fromEntries(urlQueryStrings.entries())
  return query
}
