import dayjs from 'dayjs'
import { head } from '@/services/utility/array.utility'
import { split } from '@/services/utility/string.utility'
import { curry, compose } from '@/services/utility/fp.utility'
import { between } from '@/services/utility/number.utility'
import { SERVICE_TYPE } from '@/config/constants.js'

import { useDiscoverStore } from '@/stores/discover'

export const isToday = payload => dayjs(payload).isSame(dayjs().startOf('day'), 'd')

export const isTomorrow = payload => dayjs(payload).isSame(dayjs().add(1, 'day').startOf('day'), 'd')

// @TODO Review the shite below - might become useless
export const convertVisitDateToIsoDate = payload => {
  return (new Date(new Date(payload))).toISOString()
}

export const _isToday = payload => {
  return new Date().toDateString() === new Date(payload).toDateString()
}

export const _isTomorrow = payload => {
  const date = new Date()
  const tomorrow = date.setDate(date.getDate() + 1)

  return new Date(tomorrow).toDateString() === new Date(payload).toDateString()
}

export const getDaySection = payload => {
  const now = dayjs(payload).utcOffset(payload).hour()
  const afternoonStart = 12
  const eveningStart = 17

  if (now >= afternoonStart && now <= eveningStart) {
    return 'afternoon'
  } else if (now >= eveningStart) {
    return 'evening'
  }

  return 'morning'
}

export const displayReadableDispositionDate = (orderDueAt, order, miniDisplay = false, showExactTime = false) => {
  const fulfillmentTime = dayjs(orderDueAt).utcOffset(orderDueAt)
  const referenceDate = dayjs().utcOffset(orderDueAt, true)
  const formats = {
    sameDay: function () {
      if (miniDisplay) return 'h:mmA'
      const timeOfDay = getDaySection(this.format())
      return timeOfDay === 'afternoon'
        ? '[Today at] h:mmA'
        : timeOfDay === 'evening'
          ? '[Tonight at] h:mmA'
          : '[This morning at] h:mmA'
    },
    nextDay: function () {
      return miniDisplay
        ? 'ddd h:mmA'
        : `[Tomorrow ${getDaySection(this.format())} at] h:mmA`
    },
    nextWeek: miniDisplay ? 'ddd h:mmA' : 'dddd DD MMMM h:mmA',
    sameElse: miniDisplay ? 'ddd h:mmA' : 'dddd DD MMMM h:mmA'
  }

  const serviceType = order.serviceType.toLowerCase()
  const fulfilmentSlotDelivery = useDiscoverStore().settings.FULFILMENT_SLOT_DELIVERY
  const fulfilmentSlotPickup = useDiscoverStore().settings.FULFILMENT_SLOT_PICKUP

  let formattedTime = fulfillmentTime.calendar(referenceDate, formats)

  if (showExactTime) {
    return formattedTime
  }

  if (serviceType === SERVICE_TYPE.DELIVERY && fulfilmentSlotDelivery) {
    formattedTime = `${formattedTime} - ${dayjs(orderDueAt).add(parseInt(fulfilmentSlotDelivery), 'minute').utcOffset(orderDueAt).format('hh:mm A')}`
  } else if (serviceType === SERVICE_TYPE.PICKUP && fulfilmentSlotPickup) {
    formattedTime = `${formattedTime} - ${dayjs(orderDueAt).add(parseInt(fulfilmentSlotPickup), 'minute').utcOffset(orderDueAt).format('hh:mm A')}`
  }

  return formattedTime
}

export const hoursFromNow = numberOfHours => {
  const date = new Date()
  date.setHours(date.getHours() + numberOfHours)
  return date.toISOString()
}

export const yearsFromNow = numberOfYears => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + numberOfYears)
  return date.toISOString()
}

export const hoursToMilliseconds = hours => {
  return hours * 3600000
}

export const isExpired = (expiration, comparison) => {
  expiration = expiration ? new Date(expiration) : new Date()
  comparison = comparison ? new Date(comparison) : new Date()

  return expiration.toISOString() <= comparison.toISOString()
}

export const currentYear = () => {
  const now = new Date()
  return now.getFullYear()
}

export const getYearfromISO = compose(
  head,
  split('-')
)

/**
 * Evaluate if a given year value is between the current year and a given number of years
 *
 * @func
 * @curried
 * @param {Number} numberofYears - number of years from current year
 * @param {Number} year - year to evaluate
 * @return {Boolean}
 * */
export const withinYears = curry((numberofYears, year) => {
  const min = currentYear() - 1
  const max = getYearfromISO(yearsFromNow(numberofYears))
  return between(min, max, year)
})

export const sortDate = (array, field, order = 'asc') => {
  return array.sort(function (a, b) {
    if (order === 'asc') {
      return new Date(a[field]) - new Date(b[field])
    }

    return new Date(b[field]) - new Date(a[field])
  })
}
