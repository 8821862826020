import * as Sentry from '@sentry/vue'

export function sentryMessage (message, extra) {
  Sentry.withScope(scope => {
    if (extra) {
      for (const key in extra) {
        scope.setExtra(key, extra[key])
      }
    }

    scope.setLevel('info')
    Sentry.captureMessage(message)
    /*
    if (!skipLogging && (!env.SENTRY_URL || env.NODE_ENV === env.Environments.Test)) {
      logger.warn(message, extra)
    }
    */
  })
}

export function sentryException (err, extra) {
  Sentry.withScope(scope => {
    if (extra) {
      for (const key in extra) {
        scope.setExtra(key, extra[key])
      }
    }

    scope.setLevel('error')
    Sentry.captureException(err)
    /*
    if (!skipLogging && (!env.SENTRY_URL || env.NODE_ENV === env.Environments.Test)) {
      logger.error(err.message, extra)
    }
    */
  })
}

export function setUser (user) {
  Sentry.setUser(user)
}

export function setTags (tags) {
  Object.entries(tags).forEach(([key, val]) => {
    Sentry.setTag(key, val)
  })
}

export function addBreadcrumb (payload) {
  Sentry.addBreadcrumb({
    ...payload,
    level: 'info'
  })
}
