import { FullStory } from '@fullstory/browser'
import { getAppVersion } from './attributions.helper'
import { getCapacitorPlatform } from '@/services/utility/capacitor.utility.js'

export const setProperties = async (properties = {}) => {
  FullStory('setProperties', {
    type: 'page',
    properties: {
      appVersion: await getAppVersion(),
      appType: getCapacitorPlatform(),
      ...properties
    }
  })
}
