import * as Sentry from '@sentry/vue'
import { FullStory } from '@fullstory/browser'
import { fullStoryIntegration } from '@sentry/fullstory'

const environment = import.meta.env.VITE_ENV.startsWith('bus') ? 'development' : import.meta.env.VITE_ENV

export default {
  install: (app) => {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment,
      release: import.meta.env.VITE_VERSION,
      // debug: true,
      integrations: [
        ...(import.meta.env.DEV ? [Sentry.breadcrumbsIntegration({ console: false })] : []),
        fullStoryIntegration(
          import.meta.env.VITE_SENTRY_ORG_SLUG,
          {
            client: FullStory
          }
        )
        // Disable for now the Performace and Replay
        // new Sentry.BrowserTracing({
        //   routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        // }),
        // new Sentry.Replay()
      ],
      // // Set tracesSampleRate to 1.0 to capture 100%
      // // of transactions for performance monitoring.
      // // We recommend adjusting this value in production
      // tracesSampleRate: 1.0,

      // // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // // Capture Replay for 10% of all sessions,
    // // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
        // Network error
        'Network Error',
        'Network request failed',
        'Failed to fetch',
        'Timeout',
        'NetworkError: Resource blocked by content blocker',
        'NetworkError: Load failed',
        'NetworkError:  A network error occurred.',
        // User browser client errors (invalid login credentials,expired voucher)
        'Error: Request failed with status code 400',
        'Error: Request failed with status code 404',
        'Error: Request failed with status code 412',
        'Error: Request failed with status code 502',
        // Non error exception by sentry
        'Error: Non-Error exception captured with keys: details, error',
        'Non-Error promise rejection captured',
        'Event: Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
        'ErrorEvent: Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
        // Error inside IFrame (3rd party code)
        'ReferenceError: LogRocket is not defined',
        'ReferenceError: Can\'t find variable: LogRocket',
        'ReferenceError: Can\'t find variable: fbq',
        'TypeError: Load failed',
        // Lower android version
        'Error: Invalid storage instance given',
        'ReferenceError: fbq is not defined',
        'ReferenceError: ire is not defined',
        /evaluating 'arLines.length'/i
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
      ]
    })
  }
}
