import axios from 'axios'

export default {
  Register ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/register`, data)
  },
  Login ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/token`, data)
  },
  OAuthLogin ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/oauth`, data)
  },
  ZipToken ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/ziptoken`, data)
  },
  RefreshToken ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/token/refresh`, data)
  },
  RevokeToken ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/token/revoke`, data)
  },
  VerifyRecaptchaToken ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/recaptcha`, data)
  },
  RequestPasswordResetToken ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/password/forgotten`, data)
  },
  VerifyPasswordResetToken ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/password/validatetoken`, data)
  },
  ResetPassword ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/password/resetpassword`, data)
  },
  GetProfile ({ apiRoot, customerId, options }) {
    return axios.get(`${apiRoot}/api/v1/customer/${customerId}`, options)
  },
  UpdateProfile ({ apiRoot, customerId, data, options }) {
    return axios.patch(`${apiRoot}/api/v1/customer/${customerId}`, data, options)
  },
  DeleteProfile ({ apiRoot, customerId, options }) {
    return axios.delete(`${apiRoot}/api/v1/customer/${customerId}`, options)
  },
  UpdatePassword ({ apiRoot, customerId, data, options }) {
    return axios.post(`${apiRoot}/api/v1/customer/${customerId}/password`, data, options)
  },
  SaveCreditCard ({ apiRoot, customerId, data, options }) {
    return axios.post(`${apiRoot}/api/v1/customer/${customerId}/card`, data, options)
  },
  RemoveCreditCard ({ apiRoot, customerId, cardId, options }) {
    return axios.delete(`${apiRoot}/api/v1/customer/${customerId}/card/${cardId}`, options)
  },
  UpdateCardStatus ({ apiRoot, customerId, cardId, data, options }) {
    return axios.patch(`${apiRoot}/api/v1/customer/${customerId}/card/${cardId}/status`, data, options)
  },
  UpdateDefaultCard ({ apiRoot, customerId, cardId, data, options }) {
    return axios.patch(`${apiRoot}/api/v1/customer/${customerId}/card/${cardId}/default`, data, options)
  },
  GetCreditCard ({ apiRoot, customerId, cardId, options }) {
    return axios.get(`${apiRoot}/api/v1/customer/${customerId}/card/${cardId}`, options)
  },
  UpdateServiceType ({ apiRoot, customerId, data, options }) {
    return axios.post(`${apiRoot}/api/v1/customer/${customerId}/disposition`, data, options)
  },
  UpdatePickupStore ({ apiRoot, customerId, data, options }) {
    return axios.post(`${apiRoot}/api/v1/customer/${customerId}/store`, data, options)
  },
  RemovePickupStore ({ apiRoot, customerId, options }) {
    return axios.delete(`${apiRoot}/api/v1/customer/${customerId}/store`, options)
  },
  UpdateDeliveryAddress ({ apiRoot, customerId, data, options }) {
    return axios.post(`${apiRoot}/api/v1/customer/${customerId}/address`, data, options)
  },
  RemoveDeliveryAddress ({ apiRoot, customerId, options }) {
    return axios.delete(`${apiRoot}/api/v1/customer/${customerId}/address`, options)
  },
  SendVerifyEmail ({ apiRoot, data }) {
    return axios.post(`${apiRoot}/api/v1/customer/verifyemail/send`, data)
  },
  VerifyEmail ({ apiRoot, data }) {
    return axios.patch(`${apiRoot}/api/v1/customer/verifyemail/update`, data)
  },
  GetVouchers ({ apiRoot, customerId, options }) {
    return axios.get(`${apiRoot}/api/v1/customer/${customerId}/vouchers`, options)
  }
}
