import axios from 'axios'
import dayjs from 'dayjs'
import { makeQueryString } from '@/services/utility/query-parameters.utility'

// @TODO remove double encoding when AWS .NET bug is fixed
// https://github.com/aws/aws-lambda-dotnet/issues/415

export default {
  GetCategories ({ apiRoot, payload }) {
    const params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })

    return axios.get(`${apiRoot}/api/v1/Product/categories?${params}`)
  },

  GetProducts ({ apiRoot, payload }) {
    const params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })

    return axios.get(`${apiRoot}/api/v1/Product/products?${params}`)
  },

  GetProductBySlug ({ apiRoot, slug, payload }) {
    const params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })

    return axios.get(`${apiRoot}/api/v1/Product/products/slug/${slug}?${params}`)
  },

  GetProductsByProductId ({ apiRoot, productId, payload }) {
    const params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })

    return axios.get(`${apiRoot}/api/v1/product/products/${productId}?${params}`)
  },

  GetProductsByProductIds ({ apiRoot, payload, productIds }) {
    let params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })
    params += productIds.map(id => `&productId=${id}`).join('')

    return axios.get(`${apiRoot}/api/v1/product/productIds/list?${params}`)
  },

  GetSurchargesByProductId ({ apiRoot, payload }) {
    const params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })

    return axios.get(`${apiRoot}/api/v1/Product/surcharges?${params}`)
  },

  GetMealBySlug ({ apiRoot, payload }) {
    const params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })

    return axios.get(`${apiRoot}/api/v1/Product/meals/slug/${encodeURI(payload.slug)}/short?${params}`)
  },

  GetMealById ({ apiRoot, mealId, payload }) {
    const params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })

    return axios.get(`${apiRoot}/api/v1/Product/meals/${mealId}?${params}`)
  },

  GetNavigationItems ({ apiRoot, payload }) {
    const params = makeQueryString({
      ...payload,
      fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
    })

    return axios.get(`${apiRoot}/api/v1/Navigation?${params}`)
  }
}
