const iconPath = 'img/icons/bottom-nav/'
export const bottomNavDefaults = [
  {
    name: 'home',
    to: '/',
    icons: {
      home: iconPath + 'home.svg',
      'home-active': iconPath + 'home-active.svg'
    }
  },
  {
    name: 'menu',
    to: '/menu/pizza',
    icons: {
      menu: iconPath + 'menu.svg',
      'menu-active': iconPath + 'menu-active.svg'
    }
  },
  {
    name: 'offers',
    to: '/offers',
    icons: {
      offers: iconPath + 'offers.svg',
      'offers-active': iconPath + 'offers-active.svg'
    }
  },
  {
    name: 'cart',
    disabled: false,
    icons: {
      cart: iconPath + 'cart.svg',
      'cart-active': iconPath + 'cart-active.svg'
    }
  },
  {
    name: 'account',
    to: '/account',
    icons: {
      account: iconPath + 'account.svg',
      'account-active': iconPath + 'account-active.svg'
    }
  }
]
