import { isString } from '@/services/utility/predicate.utility'
import { curry } from '@/services/utility/fp.utility'

export const reduceValidValues = payload => {
  const reduceFn = (result, [key, value]) => {
    if (value && value.length) {
      result[key] = value
    }

    return result
  }

  return Array.from(Object.entries(payload)).reduce(reduceFn, {})
}

export const flattenProducts = products => products.reduce((products, category) => {
  products = products.concat(category.products.map(p => ({ ...p, primaryCategory: category.primaryCategory })))
  return products
}, [])

/**
 * Return the "nth" index of a list
 * Supports both postive and negative indexes
 *
 * @func
 * @curried
 * @param {Number} offset - index from 0 (e.g. if xs.length === 3 then xs[-1] === xs[2])
 * @param {Array|String} store - list to operate on
 * @return {*}
 * */
export const nth = curry((offset, store) => {
  const i = offset < 0 ? store.length + offset : offset
  return store[i]
})

/**
 * Return a copy of a list with indexes removed
 *
 * @func
 * @curried
 * @param {Number} start - index to start removing items from
 * @param {Number} count - number of indexes to remove from start
 * @param {Array} data - list to operate on
 * @return {Array<*>}
 * */
export const remove = curry((start, count, data) => {
  const result = [...data]
  result.splice(start, count)
  return result
})

/**
 * Return a copy of the first item (i.e. the "head') of a list (i.e. array or string)
 *
 * @func
 * @curried
 * @param {Array|String} xs
 * @return {*}
 * */
export const head = xs => xs[0]

/**
 * Return a copy of the last item from a list (i.e. array or string)
 *
 * @func
 * @curried
 * @param {Array|String} xs
 * @return
 * */
export const last = xs => isString(xs) ? xs.split('').reverse()[0] : xs.reverse()[0]

/**
 * Return a copy of the "tail" (i.e. all indexes but the "head") in a list (i.e. array or string)
 *
 * @func
 * @curried
 * @param {Array|String} xs
 * @return
 * */
export const tail = xs => xs.slice(1)

/**
 * Return a copy of the last pushed item  in a list (i.e. array or string)
 *
 * @func
 * @curried
 * @param {Array|String} xs
 * @return {*}
 * */
export const pop = xs => head(xs.reverse())

/**
 * Evaluate if a given value is in a list
 *
 * @func
 * @curried
 * @param {*} val - value to check for
 * @param {Array} xs - list to operate on
 * @return {Boolean}
 * */
export const includes = curry((val, xs) => xs.includes(val))

/**
 * Given a pair of values, return the one with the higher lexigraphical value
 *
 * @func
 * @curried
 * @param {*} a - first value to compare
 * @param {*} b - second value to compare
 * @return {*} (a | b)
 * */
export const max = curry((a, b) => b > a ? b : a)

/**
 * Return a copy of a list with a given index updated with a given value
 *
 * @func
 * @curried
 * @param {Number} index - index to update
 * @param {*} value - value to update
 * @param {Array} data - list to operate on
 * @return {Array}
 * */
export const update = curry((index, value, data) => {
  const i = index < 0 ? data.length + index : index
  return data[i]
    ? [
      ...Array.from(data).slice(0, i),
      value,
      ...Array.from(data).slice(i + 1)
    ]
    : data
})

export const groupBy = (path, array) => array.reduce((objectsByKeyValue, obj) => {
  const value = path.reduce((tempObj, key) => tempObj?.[key], obj)
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
  return objectsByKeyValue
}, {})

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value)

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export const filterPlainArray = (array, filters) => {
  const filterKeys = Object.keys(filters)
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores an empty filter
      if (!filters[key].length) return true
      return filters[key].find(filter => getValue(filter) === getValue(item[key]))
    })
  })
}

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
 export const filterPlainArrayWithOR = (array, filters) => {
  const filterKeys = Object.keys(filters)
  return array.filter(item => {
    // filters using the (OR) operator
    return filterKeys.some(key => {
      // ignores an empty filter
      if (!filters[key].length) return true
      return filters[key].find(filter => getValue(filter) === getValue(item[key]))
    })
  })
}
