import { dictionary } from '@/config/dictionary.config'

export const metaGetter = (selector) => {
  return (selector === 'title')
    ? document.title
    : document.querySelector(`meta[name="${selector}"]`).content
}

export const metaDefaults = {
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk}`
      : dictionary.META.TITLE.text
  },
  title: dictionary.META.TITLE.text,
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: dictionary.META.DESCRIPTION.text
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: dictionary.META.KEYWORDS.text
    },
    {
      vmid: 'og:title',
      name: 'og:title',
      content: dictionary.META.TITLE.text
    },
    {
      vmid: 'og:description',
      name: 'og:description',
      content: dictionary.META.DESCRIPTION.text
    },
    {
      vmid: 'og:image',
      name: 'og:image',
      content: dictionary.META.IMAGE.url
    },
    {
      vmid: 'og:site_name',
      name: 'og:site_name',
      content: dictionary.META.SITENAME.text
    },
    {
      vmid: 'twitter:title',
      name: 'twitter:title',
      content: dictionary.META.TITLE.text
    },
    {
      vmid: 'twitter:description',
      name: 'twitter:description',
      content: dictionary.META.DESCRIPTION.text
    },
    {
      vmid: 'twitter:site',
      name: 'twitter:site',
      content: dictionary.META.USER.text
    },
    {
      name: 'apple-itunes-app',
      content: dictionary.META.IOS_APP.text
    }
  ]
}

export const setMeta = (name, content, affiliates = ['og', 'twitter']) => {
  const tags = [''].concat(affiliates)
  const setName = (tag, name) => tag ? `${tag}:${name}` : name

  return tags.map(tag => ({ vmid: setName(tag, name), name: setName(tag, name), content }))
}
