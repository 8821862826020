import { compose } from '@/services/utility/fp.utility'
import { path, pick } from '@/services/utility/object.utility'
import { GPay } from '@pizzahutau/capacitor-google-pay'
import { ApplePay } from '@pizzahutau/capacitor-plugin-apple-pay'
import { PAYMENT_DETAILS } from '@/config/constants.js'
import { PROVIDER } from '@/config/provider.config.js'
import { isBlacklistedUserAgent } from '@/services/utility/user-agent.utility.js'
import { isBlacklistedIRPIDParam } from '@/services/utility/irpid.utility.js'
import { isiOsApp, isCapacitorNativePlatform } from '@/services/utility/capacitor.utility.js'

import { useCartStore } from '@/stores/cart'
import { useCustomerStore } from '@/stores/customer'
import { useDiscoverStore } from '@/stores/discover'

/**
 * Grab flag values regarding payment options from basket
 *
 * @func
 * @param {Object} basket
 * @return {Object} paymentOptions
 * */
const getPaymentFlags = compose(
  pick([
    'canAcceptOnlineAmexCheckout', // Amex
    'canAcceptOnlineCreditCards', // Wespac
    'canAcceptOnlineVisaCheckout', // Cybersource/VCO
    'canAcceptOnlineOrders' // Cash
  ]),
  path(['storeInfo'])
)

export const paymentComponents = {
  SAVED_FLEX: {
    id: 'saved-cc-flex',
    title: 'My Saved Card',
    paymentType: 'CreditCard',
    meta: '',
    component: 'PaymentMethodSavedCreditCard',
    button: 'CheckoutButton',
    apiEndpoint: 'completeFlex',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: false,
    maxOrderCharge: 0
  },
  FLEX: {
    id: 'flex',
    title: 'Credit Card',
    paymentType: 'CreditCard',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/54x38/3fac60b81c/mastercard.png" class="me-2" alt="MasterCard" style="width: 40%; min-width: 25px;"><img src="https://content.pizzahut.com.au/f/49069/63x40/1ce100b9fd/visa.png" alt="Visa" style="width: 40%; min-width: 25px;">',
    component: 'PaymentMethodCreditCard',
    button: 'CheckoutButton',
    apiEndpoint: 'completeFlex',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: false,
    maxOrderCharge: 0
  },
  VISA: {
    id: 'visacheckout',
    title: 'Click to pay',
    paymentType: 'VCO',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/49x31/a4aaa11747/vco-logo.png" alt="Click to pay payment icon">',
    component: 'PaymentMethodVisaCheckout',
    button: 'CheckoutButton',
    apiEndpoint: 'completeVCO',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: false,
    maxOrderCharge: 0
  },
  CASH: {
    id: 'cash',
    title: 'Cash',
    paymentType: 'Cash',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/48x48/13dac9ecb4/cash.png" alt="Cash" width="40px" />',
    component: 'PaymentMethodCash',
    button: 'CheckoutButton',
    apiEndpoint: 'order',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: true,
    maxOrderCharge: 0
  },
  MOBILE_EFTPOS: {
    id: 'mobileeftpos',
    title: 'Mobile EFTPOS',
    paymentType: 'MobileEftpos',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/370x262/6f7c7ff9b1/eftpos.png" alt="Mobile Eftpos" width="50px" />',
    component: 'PaymentMethodMobileEftpos',
    button: 'CheckoutButton',
    apiEndpoint: 'order',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: true,
    maxOrderCharge: 0
  },
  APPLE_PAY: {
    id: 'applepay',
    title: 'Apple Pay',
    paymentType: 'ApplePay',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/x/a60e57539c/applepay.svg" alt="Apple Pay" width="50px" />',
    component: '',
    button: 'ApplePayButton',
    apiEndpoint: 'completeApplePay',
    requiresCustomerInfo: false,
    requiredCustomerInfo: [],
    isPaymentValidByDefault: true,
    maxOrderCharge: 0
  },
  PAYPAL: {
    id: 'paypal',
    title: 'PayPal',
    paymentType: 'PayPal',
    meta: '<img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" alt="PayPal" />',
    component: '',
    button: 'PayPalButton',
    apiEndpoint: 'completePayPal',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: true,
    maxOrderCharge: 0
  },
  GOOGLE_PAY: {
    id: 'googlepay',
    title: 'Google Pay',
    paymentType: 'GooglePay',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/72x46/6138807c67/googlepay.png" alt="Google Pay" width="50px" />',
    component: '',
    button: 'GooglePayButton',
    apiEndpoint: 'completeGooglePay',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: true,
    maxOrderCharge: 0
  },
  ZIP: {
    id: 'zippay',
    title: 'Zip',
    paymentType: 'ZipPay',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/333x152/4a4c0e4c80/zip.png" alt="Zip" width="55px" />',
    component: 'PaymentMethodZipPay',
    button: 'ZipPayButton',
    apiEndpoint: 'initialiseZipPay',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: true,
    maxOrderCharge: 0
  },
  FREE: {
    id: 'free',
    title: 'FREE - No need to pay! This one is on us!',
    paymentType: 'Cash',
    meta: '',
    component: 'PaymentMethodFree',
    button: 'CheckoutButton',
    apiEndpoint: 'order',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: true,
    maxOrderCharge: 0
  },
  AFTERPAY: {
    id: 'afterpay',
    title: 'Afterpay',
    paymentType: 'Afterpay',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/x/0ae00e8285/afterpay.svg" alt="Afterpay" width="100px" />',
    component: 'PaymentMethodAfterpay',
    button: 'AfterpayButton',
    apiEndpoint: 'initialiseAfterpay',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: true,
    maxOrderCharge: 0
  },
  CLICK_TO_PAY: {
    id: 'clicktopay',
    title: 'Click to Pay',
    paymentType: 'ClickToPay',
    meta: '<img src="https://content.pizzahut.com.au/f/49069/49x31/a4aaa11747/vco-logo.png" alt="Click to pay payment icon">',
    component: 'PaymentMethodClickToPay',
    button: 'CheckoutButton',
    apiEndpoint: 'completeClickToPay',
    requiresCustomerInfo: true,
    requiredCustomerInfo: ['firstName', 'lastName', 'mobileNumber', 'email'],
    isPaymentValidByDefault: false,
    maxOrderCharge: 0
  },
}

/**
 * Compile a list of accepted payment methods from basket
 *
 * @func
 * @param {Object} basket
 * @return {Array<Object>} list of accepted payment method objects
 * */
export async function computePaymentMethods (basket) {
  const paymentFlags = getPaymentFlags(basket)
  const isDelivery = useCartStore().isDelivery

  if (!basket?.storeInfo?.paymentOptions?.length) {
    return []
  }

  if (basket.basketTotal === 0) {
    return [paymentComponents.FREE]
  }

  // Filter Inactive Payment Options, sort by sort order, and map to components
  let paymentOptions = basket.storeInfo.paymentOptions
    .filter(x => x.status === 'Active')
    .sort((a, b) => a.sortOrder - b.sortOrder)
    // structuredClone to clone deep to avoid mutating variable and pinia state
    .reduce((arr, option) => {
      const paymentComponent = Object.values(paymentComponents).find(x => x.id === option.paymentOption.toLowerCase())
      if (paymentComponent) {
        arr.push(structuredClone(paymentComponent))
      }
      return arr
    }, [])

  // Assign the max order charge
  paymentOptions.forEach((payment, index) => {
    paymentOptions[index].maxOrderCharge = basket.storeInfo.paymentOptions.find(option => option.paymentOption.toLowerCase() === payment.id).maxOrderCharge
  })

  // Format Flex Meta if store accepts Amex
  if (basket.storeInfo.canAcceptOnlineAmexCheckout) {
    const flexComponent = paymentOptions.find(x => x.id === paymentComponents.FLEX.id)
    if (flexComponent) {
      flexComponent.meta = `<img src="https://content.pizzahut.com.au/f/49069/100x60/8f011be217/amex.png" class="me-1" alt="Amex" style="width: 20%; min-width: 25px;">
        <img src="https://content.pizzahut.com.au/f/49069/54x38/3fac60b81c/mastercard.png" class="me-1" alt="MasterCard" style="width: 20%; min-width: 25px;">
        <img src="https://content.pizzahut.com.au/f/49069/63x40/1ce100b9fd/visa.png" alt="Visa" style="width: 20%; min-width: 25px;">`
    }
  }

  const cashComponent = paymentOptions.find(x => x.id === paymentComponents.CASH.id)
  // Format Cash Title depending on service type
  if (cashComponent) {
    cashComponent.title = isDelivery ? 'Cash' : 'Pay in Store'
  }

  // Remove Cash if user agent is black listed
  if (isBlacklistedUserAgent() || isBlacklistedIRPIDParam()) {
    paymentOptions = paymentOptions.filter(x => x.id !== paymentComponents.CASH.id)
  }

  // Remove Mobile Eftpos if not delivery
  if (!isDelivery) {
    paymentOptions = paymentOptions.filter(x => x.id !== paymentComponents.MOBILE_EFTPOS.id)
  }

  // Remove Click to Pay if on the app
  if (isCapacitorNativePlatform()) {
    paymentOptions = paymentOptions.filter(x => x.id !== paymentComponents.CLICK_TO_PAY.id)
  }

  // Remove Apple Pay if not supported by the browser
  if (!await supportsApplePay()) {
    paymentOptions = paymentOptions.filter(x => x.id !== paymentComponents.APPLE_PAY.id)
  }

  // Remove Google Pay if not supported by the browser
  if (!await supportsGooglePay(useDiscoverStore().settings.GOOGLE_MERCHANT_ID, basket.storeInfo)) {
    paymentOptions = paymentOptions.filter(x => x.id !== paymentComponents.GOOGLE_PAY.id)
  }

  // Put on the top of the list if logged in with the provider
  const authProvider = useCustomerStore().provider
  if (authProvider) {
    paymentOptions.sort(
      (a, b) =>
        (b.id.includes(authProvider.toLowerCase())) -
        (a.id.includes(authProvider.toLowerCase())
      )
    )
  }

  // Add Saved Card option if store supports FLEX and customer has a default card
  const card = useCustomerStore().defaultCard
  const flexOption = basket.storeInfo.paymentOptions.find((options) => {
    return options.paymentOption === 'Flex'
  })

  if (card && getSupportedPayment(basket.storeInfo, paymentComponents.FLEX.id)) {
    paymentOptions.unshift({
      ...paymentComponents.SAVED_FLEX,
      maxOrderCharge: flexOption.maxOrderCharge || 0,
      meta: `<p>${card.displayName}</p>`
    })
  }

  return paymentFlags.canAcceptOnlineOrders ? paymentOptions : []
}

export const getCardType = cardType => {
  switch (cardType) {
    case '001':
      return {
        id: 'Visa',
        img: 'https://content.pizzahut.com.au/f/49069/63x40/1ce100b9fd/visa.png',
        cardBrandName: 'Visa',
        code: { name: 'CVV', length: 3 }
      }
    case '002':
      return {
        id: 'Mastercard',
        img: 'https://content.pizzahut.com.au/f/49069/54x38/3fac60b81c/mastercard.png',
        cardBrandName: 'MasterCard',
        code: { name: 'CVC', length: 3 }
      }
    case '003':
      return {
        id: 'Amex',
        img: 'https://content.pizzahut.com.au/f/49069/100x60/8f011be217/amex.png',
        cardBrandName: 'American Express',
        code: { name: 'CID', length: 4 }
      }
    case '063':
      return {
          id: 'ZipPay',
          cardBrandName: 'Zip Pay'
        }
    default:
      return {
        id: 'CreditCard',
        img: '',
        cardBrandName: '',
        code: { name: 'Security Code', length: undefined }
      }
  }
}

export const getAccountType = cards => {
  let cardType = PAYMENT_DETAILS.ZIP_PAYMENT_TYPES.ZIP_PAY
  if (cards) {
    const cardsZip = cards.filter(x => x.default && x.displayName.toLowerCase().includes(PROVIDER.ZIP.toLowerCase()))
    if (cardsZip.length > 0) {
      cardType = cardsZip[0].displayName
    }
  }
  return cardType
}

export const getPaymentEndpoint = paymentType => {
  return Object.values(paymentComponents).find(component => component.paymentType === paymentType)?.apiEndpoint
}

export const getPaymentById = paymentId => {
  return Object.values(paymentComponents).find(component => component.id === paymentId.toLowerCase())
}

export const getSupportedCards = storeInfo => {
  const supportedCards = ['masterCard', 'visa']
  if (storeInfo?.canAcceptOnlineAmexCheckout) {
    supportedCards.push('amex')
  }
  return supportedCards
}

export const getSupportedPayment = (storeInfo, paymentId) => {
  const payment = storeInfo?.paymentOptions?.find(x => x.paymentOption.toLowerCase() === paymentId && x.status === 'Active')
  return payment
}

export const canMakeApplePayPayments = async () => {
  if (isiOsApp()) {
    try {
      const result = await ApplePay.canMakePayments({
        networks: getSupportedCards(useCartStore().storeInfo),
        capabilities: ['capability3DS']
      })

      return result.canMakePayments
    } catch (error) {
      console.log(error)
      return false
    }
  }

  return window.ApplePaySession ? window.ApplePaySession?.canMakePayments() : false
}

export const supportsApplePay = async (storeInfo) => {
  try {
    const validSession = await canMakeApplePayPayments()
    if (storeInfo) {
      const applePayOption = storeInfo?.paymentOptions?.find(x => x.paymentOption === 'ApplePay' && x.status === 'Active')
      return Boolean(validSession && applePayOption)
    }
    return Boolean(validSession)
  } catch (err) {
    console.error(err)
    return false
  }
}

export const supportsGooglePay = async (merchantId, storeInfo, requiresAdditionalInfo) => {
  try {
    if (!getSupportedPayment(storeInfo, paymentComponents.GOOGLE_PAY.id) && import.meta.env.VITE_ENV === 'production') {
      return false
    }

    const gPayOptions = {
      isProduction: import.meta.env.VITE_ENV === 'production',
      googleMerchantId: merchantId,
      storeMerchantId: storeInfo.merchantId,
      storeName: storeInfo.name,
      requiresAdditionalInfo,
      supportedCards: getSupportedCards(storeInfo)
    }

    await GPay.load(gPayOptions)
    const response = await GPay.isReadyToPay(gPayOptions)

    return response?.result
  } catch (err) {
    return false
  }
}

export const getCardTypeValue = cardName => {
  const cardTypes = {
    visa: '001',
    mastercard: '002',
    amex: '003'
  }

  return cardTypes[cardName] || 'unknown'
}

export const getCreditCardName = number => {
  const cards = {
    amex: /^3[47][0-9]{13}$/,
    dankort: /^(5019)\d+$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    interpayment: /^(636)\d+$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    mastercard: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/
  }

  for (const key in cards) {
    if (cards[key].test(number)) {
      return key
    }
  }

  return 'unknown'
}

export const creditCardExpiryFormatter = (value) => {
  const len = value.length
  const input = value[len - 1]
  const expiryRegex = /^[0-9]{2}\/[0-9]{2,4}$/

  if (input && !/\d/.test(input)) {
    return value.substr(0, len - 1)
  } else if (expiryRegex.test(value) && !input) {
    return value.substr(0, 3) + value.substr(value.length - 2, 2)
  }

  switch (len) {
    case 1:
      return input > 1 ? `0${value}/` : value
    case 2: {
      const firstVal = Number(value[0])
      return input > 2 && firstVal === 1 ? `0${firstVal}/${input}` : value + '/'
    }
    default:
      return len > 5 ? value.substr(0, 5) : value
  }
}

export const creditCardFormatter = value => {
  const pureNumber = value.split(' ').join('')
  const pureNumberArray = pureNumber.split('')
  const formattedNumberArray = []

  for (let i = 0; i < pureNumberArray.length; i++) {
    if (!pureNumberArray[i].match('^[0-9]*$')) {
      return
    }

    if (i !== 0 && i % 4 === 0) {
      formattedNumberArray.push(' ')
    }

    formattedNumberArray.push(pureNumberArray[i])
  }

  return formattedNumberArray.join('')
}
