import { defineStore } from 'pinia'
import DiscoverApi from '@/services/api/discover.api'
import type { DiscoverResponse } from '@/types/dto/discover'

type DiscoverState = {
  configuration: Partial<DiscoverResponse>
}

export const useDiscoverStore = defineStore('discover', {
  state: (): DiscoverState => ({
    configuration: {}
  }),

  getters: {
    flags: (state) => state.configuration.flags,
    settings: (state) => state.configuration.settings,
    uris: (state) => state.configuration.uris
  },

  actions: {
    async getConfiguration () {
      const response = await DiscoverApi.getConfiguration()
      this.configuration = response.data
    },
    setConfiguration (payload: DiscoverResponse) {
      this.configuration = payload
    }
  }
})
