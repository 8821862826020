export const profileDefaults = {
  firstName: '',
  lastName: '',
  optinSms: false,
  optinEmail: false,
  optinSupercarsFan: false,
  acceptTerms: false,
  attributes: []
}

export const jsonWebTokenDefaults = {
  access_token: '',
  token_type: '',
  expires_in: 0,
  refresh_token: ''
}
