import axios from 'axios'

export default {
  EmailOptOut ({ apiRoot, userId }) {
    return axios.post(`${apiRoot}/api/v1/user/${userId}/optin/email`, { optinEmail: false })
  },
  EmailOptOutByEmail ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/user/optout/email`, { ...payload, optinEmail: false })
  },
  SMSOptOutShort ({ apiRoot, shortUserId }) {
    return axios.post(`${apiRoot}/api/v1/user/shortUserId/${shortUserId}/optin/sms`, { optinSms: false })
  },
  SupercarsFanOptOutShort ({ apiRoot, shortUserId }) {
    return axios.post(`${apiRoot}/api/v1/user/shortUserId/${shortUserId}/optin/supercars`, { optinSupercarsFan: false })
  },
  GetByShortId ({ apiRoot, shortUserId }) {
    return axios.get(`${apiRoot}/api/v1/user/shortUserId/${shortUserId}`)
  },
  GetEntry ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/user/getEntry`, payload)
  },
  SMSOptOutMobileNumber ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/user/optout/sms`, payload)
  },
  CampaignForm ({ apiRoot, payload }) {
    return axios.post(`${apiRoot}/api/v1/user/campaign`, payload)
  }
}
