import { SERVICE_TYPE } from '@/config/constants.js'

import { useCartStore } from '@/stores/cart'
import { useStoreStore } from '@/stores/store'

export const pickupMinutes = () => {
  const cartSwitchPickupData = useCartStore().cartSwitchPickupData
  const isDelivery = useCartStore().isDelivery
  const isAsap = useCartStore().isAsap
  const isPickup = useCartStore().isPickup

  if (
    !cartSwitchPickupData.pickupMinutes &&
    isDelivery &&
    isAsap &&
    !cartSwitchPickupData?.storeId
  ) {
    return `${fulfilmentTimeMinutes(SERVICE_TYPE.PICKUP)} mins`
  } else if (isPickup && isAsap) {
    return `${fulfilmentTimeMinutes(SERVICE_TYPE.PICKUP)} mins`
  } else if (cartSwitchPickupData.pickupMinutes) {
    return cartSwitchPickupData.pickupMinutes
  } else {
    return ''
  }
}

export const deliveryMinutes = () => {
  const basket = useCartStore().basket
  const cartSwitchDeliveryData = useCartStore().cartSwitchDeliveryData
  const isPickup = useCartStore().isPickup
  const isAsap = useCartStore().isAsap
  const isDelivery = useCartStore().isDelivery

  // show blank minutes if delivery address is not set yet or the customer change the pickup store location
  if (!basket.customerInfo.address && !cartSwitchDeliveryData.address) {
    return ''
  } else if (
    !cartSwitchDeliveryData.deliveryMinutes &&
    isPickup &&
    isAsap &&
    !cartSwitchDeliveryData?.storeId
  ) {
    return `${
      fulfilmentTimeMinutes(SERVICE_TYPE.DELIVERY) + computedAdditionalTime()
    } mins`
  } else if (isDelivery && isAsap) {
    return `${
      fulfilmentTimeMinutes(SERVICE_TYPE.DELIVERY) + computedAdditionalTime()
    } mins`
  } else if (cartSwitchDeliveryData.deliveryMinutes) {
    return cartSwitchDeliveryData.deliveryMinutes
  } else {
    return ''
  }
}

export const fulfilmentTimeMinutes = (serviceType) => {
  const promiseTimeAutomation = useStoreStore().promiseTimeAutomation

  const isAlgoPromiseTime =
    promiseTimeAutomation.allowPromiseTimeAutomation &&
    promiseTimeAutomation.isAlgoPromiseTimeEnabled &&
    promiseTimeAutomation[`average${serviceType}TimeMinutes`]

  let averageTimeMinutes =
    promiseTimeAutomation[`average${serviceType}TimeMinutes`]
  let timeMinutes =
    promiseTimeAutomation[`${serviceType}TimeMinutes`]

  if (serviceType === SERVICE_TYPE.DELIVERY) {
    averageTimeMinutes += computedDeliveryAdjustmentMinutes()
    timeMinutes += computedDeliveryAdjustmentMinutes()
  }
  return isAlgoPromiseTime && averageTimeMinutes
    ? averageTimeMinutes
    : timeMinutes
}

const computedDeliveryAdjustmentMinutes = () => {
  const cartSwitchDeliveryData = useCartStore().cartSwitchDeliveryData
  const deliveryAdjustmentMinutes =
    useCartStore().deliveryAdjustmentMinutes

  return (
    deliveryAdjustmentMinutes ||
    Number(cartSwitchDeliveryData.deliveryAdjustmentMinutes)
  )
}

export const computedAdditionalTime = () => {
  const additionalTime = useCartStore().surchargeTime
  const cartSwitchDeliveryData = useCartStore().cartSwitchDeliveryData
  return additionalTime || Number(cartSwitchDeliveryData.additionalTime)
}
