import { dictionary } from '@/config/dictionary.config'
import { UiController } from '@/controllers/ui.controller'
import { StoreController } from '@/controllers/store.controller'
import { BasketController } from '@/controllers/basket.controller'
import { TrackingController } from '@/controllers/tracking.controller'
import { create as createBasketPayload, getCoordinates } from '@/services/helpers/customer-info.helper'
import { addBreadcrumb, setTags } from '@/services/utility/sentry.utility.js'
import { fromTruthy, merge, pick } from '@/services/utility/object.utility'
import dayjs from 'dayjs'
import { GA4_EVENT } from '@/config/constants.js'

import { useCartStore } from '@/stores/cart'
import { useCustomerStore } from '@/stores/customer'
import { useLocalisationStore } from '@/stores/localisation'
import { useStoreStore } from '@/stores/store'

export const SessionController = {
  async validateSession (resetCache) {
    try {
      const basketId = useCartStore().basketId
      const response = await useCartStore().getBasket({ basketId })
      const validSession = response !== undefined
      await this.saveSession({ validSession, resetCache })
    } catch (error) {
      if (error.response.status !== 410) {
        await this.saveSession({ validSession: false, resetCache })
      }
    }
    // Check logged in users and verify their session is still valid.
    if (useCustomerStore().isAuth && useCustomerStore().customerId) {
      useCustomerStore().getProfile()
    }
  },

  async saveSession ({ payload, validSession, resetCache = true, silent = true }) {
    let result = false
    try {
      addBreadcrumb({
        category: 'session',
        message: 'Saving Session'
      })

      const stateDisposition = fromTruthy(useLocalisationStore().disposition)
      if (Object.keys(stateDisposition).length) {
        validSession = false
      }
      payload = merge(payload, stateDisposition)
      useLocalisationStore().resetDispositionState()

      const basket = useCartStore().basket
      const basketPayload = createBasketPayload(basket)

      payload = { ...basketPayload, ...payload }
      // Remove orderDueAt for ASAP order
      if (payload.isAsap) {
        payload.orderDueAt = ''
      }

      // Remove delivery details for pickup order
      if (payload.serviceType.toLowerCase() === 'pickup') {
        payload = pick(['isAsap', 'serviceType', 'orderDueAt', 'storeId'], payload)
      }

      // NOTE: Possibility to check for difference in payload vs basket here to reduce api calls.
      const store = await StoreController.getStore({ payload })
      if (!store) {
        return BasketController.clearSession(true, true)
      }

      payload.storeId = store.store.code

      // Ensure orderDueAt is in same timezone as store E.g. QR code orderDueAt tz might vary
      if (payload.orderDueAt) {
        payload.orderDueAt = dayjs(payload.orderDueAt).utcOffset(store.store.timeZoneOffsetHours).format()
      }

      if (!validSession) {
        useCartStore().setContactless(false)
      }

      setTags({
        storeCode: store.store.code
      })

      const computedSchedule = StoreController.computeStoreSchedule(store)

      if (!payload.isAsap && StoreController.validateOrderDueAt(payload.orderDueAt, payload.serviceType.toLowerCase(), computedSchedule)) {
        await BasketController.saveSession({
          silent,
          validSession,
          resetCache,
          payload
        })
        result = true
      } else if (payload.isAsap && StoreController.isStoreAvailabilityASAP(computedSchedule[0].fulfilmentTimes[0], payload.serviceType)) {
        await BasketController.saveSession({
          silent,
          validSession,
          resetCache,
          payload: {
            ...payload,
            orderDueAt: dayjs().utcOffset(store.store.timeZoneOffsetHours).add(1, 'm').format()
          }
        })
        result = true
      } else {
        addBreadcrumb({
          category: 'session',
          message: 'Saving Session - Show Datetime Selector'
        })
        await useLocalisationStore().setDatetimeSelectorState({
          active: true,
          showWarning: true,
          validSession,
          store: store.store,
          computedSchedule,
          fulfilmentType: payload.serviceType,
          disableNotifications: silent,
          address: {
            address: payload.address,
            city: payload.city,
            state: payload.state,
            postalCode: payload.postalCode,
            longitude: payload.longitude,
            latitude: payload.latitude
          }
        })
        UiController.showSpinner({ loading: false })
      }
      addBreadcrumb({
        category: 'session',
        message: 'Saved Session'
      })
      return result
    } catch (err) {
      console.error(err)
      BasketController.clearSession(true, true)
    }
  },
  /**
   * Invoked when a session (store.state.cart.basket) is expired
   * or is similarly "invalid" in some way.
   *
   * Dispatches an action to the `ui` module to trigger an alert
   * alerting the user their session was successfully reset if
   * `displaySuccess` is true.
   *
   * Use cases for `displaySuccess` being false are when some other
   * UiController method is executed to handle user feedback.
   */
  async handleExpiredSession ({ displaySuccess } = { displaySuccess: true }, disposition) {
    this.resetSession(disposition)
      .then(() => {
        if (displaySuccess) {
          this.onResetExpiredSessionSuccess()
        }
      })
      .catch(() => this.onResetExpiredSessionError())
  },

  /**
   * Invoked when an expired session is required to be reset.
   *
   * Dispatch store action to request a fresh basket from API.
   */
  async resetSession (payload) {
    UiController.showSpinner({ loading: true })

    const basket = useCartStore().basket

    if (!basket?.basketId) {
      return BasketController.clearSession(true, true)
    }

    try {
      payload ??= BasketController.getPayloadWithCustomerDisposition() || createBasketPayload(basket)
      if (payload.serviceType.toLowerCase() === 'delivery') {
        const coordinates = getCoordinates(payload)
        if (!coordinates.latitude || !coordinates.longitude) {
          const query = `${basket.customerInfo.address} ${basket.customerInfo.city} ${basket.customerInfo.state} ${basket.customerInfo.postalCode}`
          const addressList = await useStoreStore().getAddressesByQuery(query)
          const address = await StoreController.getAddressByMoniker(addressList[0]?.Moniker || addressList.Moniker)
          if (!address) {
            return BasketController.clearSession(true, true)
          }
          payload = { ...payload, ...address }
        }
      }

      if (await this.saveSession({ payload, validSession: false })) {
        if (payload?.storeId) {
          TrackingController.trackGA4CustomEvent(GA4_EVENT.PRE_LOCALISED, {
            store: payload.storeId
          })
        }
      }
    } catch (err) {
      console.log(err)
      return BasketController.clearSession(true, true)
    }
  },

  /**
   * Invoked on `resetExpiredSession` success.
   *
   * Dispatches an action to the `snackbar` module showing
   * the user a snackbar notification.
   */
  onResetExpiredSessionSuccess: function () {
    /*
    UiController.showSnackbar({
      active: true,
      message: dictionary.SESSION.SESSION_RESET_SUCCESS.text,
      theme: 'success'
    })
    */
  },

  /**
   * Invoked on `resetExpiredSession` error.
   *
   * Dispatches an action to the `snackbar` module showing
   * the user a snackbar notification.
   */
  onResetExpiredSessionError: function () {
    UiController.showSnackbar({
      active: true,
      message: dictionary.SESSION.SESSION_RESET_ERROR.text,
      theme: 'danger'
    })
  }
}
