function installClient (sdkUrl) {
  return new Promise((resolve) => {
    const scriptId = 'auth2_script_id'
    const el = document.getElementById(scriptId)
    if (!el) {
      const script = document.createElement('script')
      script.setAttribute('src', sdkUrl)
      script.setAttribute('async', true)
      script.setAttribute('defer', 'defer')
      script.setAttribute('id', scriptId)
      script.onreadystatechange = script.onload = function () {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(function () {
            resolve()
          }, 500)
        }
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    } else {
      resolve()
    }
  })
}

function initClient (config) {
  return new Promise((resolve, reject) => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init(config)
        .then(() => {
          resolve(window.gapi)
        }).catch((error) => {
          reject(error)
        })
    })
  })
}

// hasAuth timeout - 1000000ms = 1000 seconds
const timeout = 1000000

function Auth () {
  if (!(this instanceof Auth)) {
    return new Auth()
  }

  this.GoogleAuth = null
  this.isAuthorized = false
  this.isInit = false
  this.prompt = null

  this.hasAuth = () => {
    const start = Date.now()
    const waitForAuth = (resolve, reject) => {
      if (this.GoogleAuth) {
        resolve()
      } else if (timeout && (Date.now() - start) >= timeout) {
        reject(new Error('GoogleAuth is not initialised'))
      } else {
        setTimeout(waitForAuth.bind(this, resolve, reject), 100)
      }
    }
    return new Promise(waitForAuth)
  }

  this.load = (sdkUrl, config, prompt) => {
    return new Promise((resolve, reject) => {
      installClient(sdkUrl)
      .then(() => {
        return initClient(config)
      })
      .then((gapi) => {
        this.GoogleAuth = gapi.auth2.getAuthInstance()
        this.isInit = true
        this.prompt = prompt
        this.isAuthorized = this.GoogleAuth.isSignedIn.get()
        resolve()
      }).catch((error) => {
        console.error(error)
        reject(error)
      })
    })
  }

  this.signIn = (successCallback, errorCallback) => {
    return new Promise((resolve, reject) => {
      this.hasAuth()
        .then(() => {
          this.GoogleAuth.signIn()
          .then(googleUser => {
            if (typeof successCallback === 'function') successCallback(googleUser)
            this.isAuthorized = this.GoogleAuth.isSignedIn.get()
            resolve(googleUser)
          })
          .catch(error => {
            if (typeof errorCallback === 'function') errorCallback(error)
            reject(error)
          })
        })
        .catch(err => {
          if (typeof errorCallback === 'function') errorCallback(false)
          reject(err)
        })
    })
  }

  this.getAuthCode = (successCallback, errorCallback) => {
    return new Promise((resolve, reject) => {
      this.hasAuth()
        .then(() => {
          this.GoogleAuth.grantOfflineAccess({ prompt: this.prompt })
          .then(function (resp) {
            if (typeof successCallback === 'function') successCallback(resp.code)
            resolve(resp.code)
          })
          .catch(function (error) {
            if (typeof errorCallback === 'function') errorCallback(error)
            reject(error)
          })
        })
        .catch(err => {
          if (typeof errorCallback === 'function') errorCallback(false)
          reject(err)
        })
    })
  }

  this.signOut = (successCallback, errorCallback) => {
    return new Promise((resolve, reject) => {
      this.hasAuth()
        .then(() => {
          this.GoogleAuth.signOut()
          .then(() => {
            if (typeof successCallback === 'function') successCallback()
            this.isAuthorized = false
            resolve(true)
          })
          .catch(error => {
            if (typeof errorCallback === 'function') errorCallback(error)
            reject(error)
          })
        })
        .catch(err => {
          if (typeof errorCallback === 'function') errorCallback(false)
          reject(err)
        })
    })
  }

  this.attachClickHandler = (container, options, onSuccess, onFailure) => {
    return new Promise((resolve, reject) => {
      this.hasAuth()
        .then(() => {
          this.GoogleAuth.attachClickHandler(container, options, onSuccess, onFailure)
          resolve()
        })
        .catch(reject)
    })
  }

  this.getUser = () => {
    return new Promise((resolve, reject) => {
      this.hasAuth()
        .then(() => {
          const user = this.GoogleAuth.currentUser.get()
          resolve(user)
        })
        .catch(reject)
    })
  }

  this.getToken = async () => {
    return new Promise((resolve, reject) => {
      this.getUser().then(user => {
        const response = user.getAuthResponse(true)
        resolve(response?.id_token)
      })
      .catch(err => reject(err))
    })
  }
}

export default new Auth()
