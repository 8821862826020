import {
  Card,
  initialize,
  subscribeToContentCardsUpdates,
  logContentCardImpressions as brazeLogContentCardImpressions,
  logCardClick as brazeLogCardClick,
  changeUser as brazeChangeUser,
  requestImmediateDataFlush as brazeRequestImmediateDataFlush,
  requestContentCardsRefresh as brazeRequestContentCardsRefresh,
  getCachedContentCards as brazeGetCachedContentCards,
  getUser,
  openSession
} from '@braze/web-sdk'
import { isTruthyUUID } from './uuid.utility'
import { useContentCardsStore } from '@/stores/content-cards'

export const initBrazeWeb = (apiKey, sdkUrl) => {
  initialize(apiKey, {
    baseUrl: sdkUrl,
    enableLogging: import.meta.env.DEV,
    minimumIntervalBetweenTriggerActionsInSeconds: 0,
    manageServiceWorkerExternally: true,
    allowUserSuppliedJavascript: true
  })

  // A callback function can be registered to subscribe for updates when cards are refreshed
  // https://www.braze.com/docs/developer_guide/platform_integration_guides/web/content_cards/customization/custom_ui#listening-for-card-updates
  subscribeToContentCardsUpdates((updates) => {
    if (updates.cards.length >= 1) {
      // Get and store the cards
      useContentCardsStore().setContentCards(
        updates.cards
      )
    }
  })

  openSession()

  window.brazeWeb = {
    getUser,
    changeUser
  }
}

/**
 * https://www.braze.com/docs/help/help_articles/content_cards/empty_content_card_feed
*/
export const requestImmediateDataFlush = () => {
  brazeRequestImmediateDataFlush()
}

/**
 * https://www.braze.com/docs/developer_guide/platform_integration_guides/web/content_cards/refreshing_the_feed#refreshing-content-cards
 */
export const requestContentCardsRefresh = (successCallback, errorCallback) => {
  brazeRequestContentCardsRefresh(successCallback, errorCallback)
}

export const getCachedContentCards = () => {
  const cards = brazeGetCachedContentCards()?.cards
  if (cards.length >= 1) {
    useContentCardsStore().setContentCards(
      cards
    )
  }
}

export const changeUser = (userId) => {
  if (!isTruthyUUID(userId)) {
    return
  }

  brazeChangeUser(userId)
}

export const logContentCardImpressions = (card) => {
  brazeLogContentCardImpressions([Object.assign(new Card(), card)], true)
}

export const logCardClick = (card) => {
  brazeLogCardClick(Object.assign(new Card(), card), true)
}
