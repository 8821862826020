export const localisationDefaults = {
  datetimeSelector: {
    active: false,
    store: {},
    schedule: [],
    fulfilmentType: '',
    address: {},
    currentlyTrading: false,
    showWarning: true,
    disableCloseOption: true,
    validSession: null,
    disableNotifications: false,
    message: ''
  },
  locator: {
    editMode: false,
    tabIndex: 0,
    showValidationMessage: false
  },
  disposition: {
    orderDueAt: '',
    isAsap: undefined
  },
  localisationModal: {
    active: false,
    noBackdrop: false,
    noEsc: false
  }
}
