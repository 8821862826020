// Home rolled versions of commonly used lodash/fp
// utilities  and pointfree helpers and predicates
import { isObject, isNil } from '@/services/utility/predicate.utility'

/**
 * A function that takes a value and returns that value
 * Useful for complex function composition that includes branching
 *
 * @func
 * @param {*} x
 * @return {*} x
 * */
export const identity = x => x

/**
 * Curry a function
 *
 * @func
 * @param {Function} fn
 * @param {Number} arity - number of arguments
 * @return {Function}
 * */
export function curry (fn, arity = fn.length) {
  return function _curry (...args) {
    if (args.length < arity) {
      return _curry.bind(null, ...args)
    }
    return fn(...args)
  }
}

/**
 * Compose a list of functions into a "pipeline" function
 *
 * @func
 * @curried
 * @param {Array<Function>} fns
 * @param {Array<*>} args
 * @return {Function}
 * */
export const compose = (...fns) => (...args) => fns.reduceRight((res, fn) => [fn(...res)], args)[0]

/**
 * Point free map
 * Supports both Arrays and Objects
 *
 * @func
 * @curried
 * @param {Function} fn - mapper function
 * @param {Array|Object} data - data to map over
 * @return {Array|Object}
 * */
export const map = curry((fn, data) => isObject(data)
  ? fromPairs(toPairs(data).map(([k, v]) => [k, fn(v)]))
  : data.map(fn)
)

/**
 * Point free reduce
 *
 * @func
 * @curried
 * @param {Function} fn
 * @param {*} initial - initial value of expected type (e.g. {}, [], 0, '', etc)
 * @param {Array} data - list to reduce
 * @return {*}
 * */
export const reduce = curry((fn, initial, data) => data.reduce(fn, initial))

/**
 * Point free filter
 * Supports both Arrays and Objects
 *
 * @func
 * @curried
 * @param {Function} pred - function that returns a boolean
 * @param {Array|Object} data - data to filter
 * @return {Array|Object}
 * */
export const filter = curry((pred, data) => isObject(data)
  ? fromPairs(toPairs(data).filter(([, v]) => pred(v)))
  : data.filter(pred)
)

/**
 * Point free find
 * Supports both Arrays and Objects
 *
 * @func
 * @curried
 * @param {Function} pred - function that returns a boolean
 * @param {Array|Object} data - data to find value in
 * @return {*} value that matches predicate
 * */
export const find = curry((pred, data) => isObject(data)
  ? fromPairs(toPairs(data).find(([, v]) => pred(v)))
  : data.find(pred)
)

/**
 * Set default value
 * Useful for function composition
 *
 * @func
 * @curried
 * @param {*} defVal - default value to return if val is falsy
 * @param {*} val - value to return if truthy
 * @return {*}
 *
 * @example defaultTo('foobar', undefined) // -> 'foobar'
 * @example defaultTo('foobar', null) // -> 'foobar'
 * @example defaultTo('foobar', false) // -> false
 * @example deafultTo('foobar', 0) // -> 0
 * @example defaultTo('foobar', '') // -> ''
 * */
export const defaultTo = curry((defVal, val) => isNil(val) ? defVal : val)

/**
 * Return object entries as [key, value] pairs
 * Point free object entries
 *
 * @func
 * @curried
 * @param {Object} obj
 * @return {Array<Array<*>>}
 * */
export const toPairs = curry(obj => Object.entries(obj))

/**
 * Return an object literal from [key, value] entry pairs
 *
 * @func
 * @curried
 * @param {Array<Array<*>>} pairs
 * @return {Object}
 * */
export const fromPairs = curry(pairs => pairs.reduce((a, [k, v]) => { a[k] = v; return a }, {}))

/**
 * Execute a given function on the returned values from a list of functions
 * Useful for function composition that requires branching
 *
 * @func
 * @curried
 * @param {Function} after - function to execute on list of return values
 * @param {Array<Function>} fns - functions to map on a given value
 * @return {*}
 *
 * @example converge(concat, [toUpper, toLower], "Yodel") // -> "YODELyodel"
 * @example converge(divide, [sum, length], [1, 2, 3, 4, 5, 6, 7]) // -> calculate the average 4
 * */
export const converge = curry((after, fns) =>
  curry((...args) =>
    after(...map(fn => fn(...args), fns))
  )
)

/**
 * Point free equality check
 * Useful for function composition
 *
 * @param {*} a - first value to compare
 * @param {*} b - second value to compare
 * @return {Boolean}
 * */
export const equals = curry((a, b) => a === b)

/**
 * Run a given function on the return value within a composition
 * Useful for debugging function composition pipelines
 *
 * @param {Function} fn - function to run on return value
 * @return {*} value - return the value for the next function in the pipeline
 *
 * @example compose(someFunc, tap(console.log), toUpperCase)('foobar') // -> logs 'FOOBAR' before executing someFunc('FOOBAR')
 * */
export const tap = curry((fn, val) => {
  fn(val)
  return val
})

/**
* Creates a new list out of the two supplied by applying the function to each
* equally-positioned pair in the lists. The returned list is truncated to the
* length of the shorter of the two input lists.
*
* @func
* @curried
* @param {Function} fn The function used to combine the two elements into one value.
* @param {Array} list1 The first array to consider.
* @param {Array} list2 The second array to consider.
* @return {Array} The list made by combining same-indexed elements of `list1` and `list2`
*         using `fn`.
* @example
*
*      const f = (x, y) => {
*        // ...
*      };
*      R.zipWith(f, [1, 2, 3], ['a', 'b', 'c']);
*      //=> [f(1, 'a'), f(2, 'b'), f(3, 'c')]
 */

export const zipWith = curry((fn, a, b) => {
  const rv = []
  let idx = 0
  const len = Math.min(a.length, b.length)
  while (idx < len) {
    rv[idx] = fn(a[idx], b[idx])
    idx += 1
  }
  return rv
})
