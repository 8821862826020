export const passwordCriteria = [
  {
    label: 'One lowercase character',
    regex: /[a-z]+/,
    type: 'positive'
  },
  {
    label: '3 or more identical characters (e.g. aaa)',
    regex: /([a-zA-Z0-9])\1\1/,
    type: 'negative'
  },
  {
    label: 'One uppercase character',
    regex: /[A-Z]+/,
    type: 'positive'
  },
  {
    label: '3 or more consecutive characters (e.g. abc)',
    regex: /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+/,
    type: 'negative'
  },
  {
    label: 'One number',
    regex: /[0-9]/,
    type: 'positive'
  },
  {
    label: '8 characters minimum',
    regex: /^.{8,}$/,
    type: 'positive'
  }
]

export const SCOPE = {
  READ_PROFILE: 'openid',
  WRITE_PROFILE: 'write:profile',
  READ_CARD: 'read:card',
  WRITE_CARD: 'write:card',
  OFFLINE_ACCESS: 'offline_access'
}
