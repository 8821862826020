import dayjs from 'dayjs'
import { defineStore } from 'pinia'

import { useMarketingStore } from '@/stores/marketing'

export const useCacheStore = defineStore('cache', {
  state: () => ({
    last_cache_check: null,
    categories: {
      expires_at: null,
      cache: null
    },
    products: [],
    snippets: {},
    offers: {
      expires_at: null,
      cache: null
    },
    coupons: {
      expires_at: null,
      cache: null
    },
    banner: {
      expires_at: null,
      cache: null
    },
    banner_secondary: {
      expires_at: null,
      cache: null
    },
    banner_sub: {
      expires_at: null,
      cache: null
    },
    spinner: {
      expires_at: null,
      cache: null
    },
    footer: {
      expires_at: null,
      cache: null
    },
    nav_items: {
      expires_at: null,
      cache: null
    },
    prizes: {
      expires_at: null,
      cache: null
    },
    upsells: {
      expires_at: null,
      cache: null
    },
    splash_screen: {
      expires_at: null,
      cache: null
    },
    dictionary: {
      expires_at: null,
      cache: null
    },
    bottom_nav: {
      expires_at: null,
      cache: null
    },
    filter_icons: {
      expires_at: null,
      cache: null
    },
    empty_cart_state: {
      expires_at: null,
      cache: null
    },
    menu_categories: {}
  }),

  getters: {

  },

  actions: {
    setCache (payload) {
      switch (payload.cacheType) {
        case 'products': {
          const products = JSON.parse(JSON.stringify(this.products))
          const foundProducts = products.find(product => product.category.toLowerCase() === payload.category.toLowerCase())

          if (foundProducts) {
            foundProducts.cache = payload.cache
            foundProducts.expires_at = payload.expires_at
            foundProducts.filters = payload.filters
          } else {
            products.push({
              expires_at: payload.expires_at,
              cache: payload.cache,
              filters: payload.filters,
              category: payload.category.toLowerCase()
            })
          }

          this.products = products
          break
        }

        case 'menu_categories': {
          this.menu_categories = {
            ...this.menu_categories,
            [payload.path]: {
              expires_at: payload.expires_at,
              cache: payload.cache
            }
          }
          break
        }

        case 'snippets': {
          let snippets = JSON.parse(JSON.stringify(this.snippets))
          snippets = {
            ...snippets,
            [payload.path]: {
              expires_at: payload.expires_at,
              cache: payload.cache
            }
          }

          this.snippets = snippets
          break
        }
        case 'upsells': {
          const upsells = JSON.parse(JSON.stringify(this.upsells))
          upsells.expires_at = payload.expires_at
          upsells.cache = payload.cache.upsells.reduce((upsells, upsell) => {
            const { productId, trigger } = payload.cache
            const cachedUpsell = upsells.cache?.find(u => u.id === upsell.id)
            const requiredProducts = cachedUpsell?.requiredProducts
              ? cachedUpsell.requiredProducts.includes(productId)
                ? cachedUpsell.requiredProducts
                : [...cachedUpsell.requiredProducts, productId]
              : [productId]
            const triggers = cachedUpsell?.triggers
              ? cachedUpsell.triggers.includes(trigger)
                ? cachedUpsell.triggers
                : [...cachedUpsell.triggers, trigger]
              : [trigger]

            return [...upsells, {
              ...upsell,
              requiredProducts,
              triggers
            }]
          }, [])

          this.upsells = upsells
          break
        }
        default:
          this[payload.cacheType] = {
            expires_at: payload.expires_at,
            cache: payload.cache
          }

        break
      }
    },

    setLastCacheCheck (payload = dayjs().format()) {
      this.last_cache_check = payload
    },

    invalidateCache () {
      this.categories = { ...this.categories, expires_at: null }
      this.offers = { ...this.offers, expires_at: null }
      this.banner = { ...this.banner, expires_at: null }
      this.banner_secondary = { ...this.banner_secondary, expires_at: null }
      this.banner_sub = { ...this.banner_sub, expires_at: null }
      this.spinner = { ...this.spinner, expires_at: null }
      this.footer = { ...this.footer, expires_at: null }
      this.nav_items = { ...this.nav_items, expires_at: null }
      this.prizes = { ...this.prizes, expires_at: null }
      this.upsells = { ...this.upsells, expires_at: null }
      this.splash_screen = { ...this.splash_screen, expires_at: null }
      this.dictionary = { ...this.dictionary, expires_at: null }
      this.bottom_nav = { ...this.bottom_nav, expires_at: null }
      this.filter_icons = { ...this.filter_icons, expires_at: null }

      this.products = this.products.map(product => {
        return {
          ...product,
          expires_at: null
        }
      })

      const invalidateContentWithPath = (content) => {
        for (const [path, contentItem] of Object.entries(content)) {
          content = {
            ...content,
            ...{
              [path]: {
                expires_at: null,
                cache: contentItem.cache
              }
            }
          }
        }

        return content
      }

      this.snippets = invalidateContentWithPath(this.snippets)
      this.menu_categories = invalidateContentWithPath(this.menu_categories)

      this.last_cache_check = dayjs().format()

      useMarketingStore().resetUpsellData()
    },

    /**
     * Products are dynamic, so we're not able to init the state with empty/null values.
     * This mutation achieves that for us when categories are fetched from the Product API.
     */
    initProductsCache (payload) {
     this.products = payload.map(category => ({
        category: category.code.toLowerCase(),
        cache: [],
        filters: [],
      }))
    }
  }
})
