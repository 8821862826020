export const uiAlertDefaults = {
  ref: '',
  active: false,
  alertType: 'confirm', // Current types: alert, confirm
  headerBgVariant: 'danger',
  title: 'Warning',
  okTitle: 'Confirm',
  cancelTitle: 'Cancel',
  okVariant: 'danger',
  cancelVariant: 'light',
  bodyText: 'Are you sure?',
  okCallback: null,
  cancelCallback: null
}

export const uiSnackbarDefaults = {
  active: false,
  message: '',
  theme: 'success',
  blocking: false,
  hasCloseIcon: true,
  autoClose: true,
  autoCloseDuration: 4000,
  okayButton: {
    active: false,
    text: 'Okay',
    theme: 'success',
    callback: null
  },
  cancelButton: {
    active: false,
    text: 'Cancel',
    theme: 'danger',
    callback: null
  }
}

export const uiSpinnerDefaults = {
  loading: false,
  message: '',
  blocking: true,
  transparent: true,
  dark: false,
  src: '/img/loading.gif'
}

export const uiMinimumSpendModalDefaults = {
  active: false,
  orderMinimum: '',
  minimumSpend: '',
  totalOrder: '',
  serviceType: ''
}
