import { defineStore } from 'pinia'
import { CacheController } from '@/controllers/cache.controller'

export const useContentCardsStore = defineStore('content-cards', {
  state: () => ({
    cards: []
  }),

  getters: {
    getCards: (state) => state.cards.filter(state => !CacheController.isCacheExpired(state.expiresAt)),
    getCard: (state) => (id) => state.cards.find(card => card.id === id && !CacheController.isCacheExpired(card.expiresAt)),
    getSnippets: (state) => state.cards.filter(state => state.extras?.card_type === 'snippet'),
    getHpHero (state) {
      return state.cards
        .filter(state =>
          state.extras?.site_section === 'hp_hero' &&
          state.extras?.card_type === 'full_width_hero' &&
          !CacheController.isCacheExpired(state.expiresAt)
        )
        .map(state => ({ ...state, ...state.extras }))
        .sort((a, b) => new Date(b.updated) - new Date(a.updated))
        .shift() ?? {}
    },
    getHpSecondary (state) {
      return state.cards
        .filter(state =>
          state.extras?.site_section === 'hp_secondary' &&
          state.extras?.card_type === 'secondary_hero' &&
          !CacheController.isCacheExpired(state.expiresAt)
        )
        .map(state => ({ ...state, ...state.extras }))
        .sort((a, b) => new Date(b.updated) - new Date(a.updated))
        .shift() ?? {}
    },
    getHpTodayOffer (state) {
      return state.cards
        .filter(state =>
          state.extras?.site_section === 'hp_today_offer' &&
          state.extras?.card_type === 'offer_card' &&
          !CacheController.isCacheExpired(state.expiresAt)
        )
        .map(state => ({
          ...state,
          // Need to have initial values to prevent error
          price_pickup: state.extras?.price_pickup ?? '',
          price_pickup_strikethrough: state.extras?.price_pickup_strikethrough ?? '',
          price_pickup_title: state.extras?.price_pickup_title ?? '',
          price_delivery: state.extras?.price_delivery ?? '',
          price_delivery_strikethrough: state.extras?.price_delivery_strikethrough ?? '',
          price_delivery_title: state.extras?.price_delivery_title ?? '',
          sub_title_left: state.extras?.sub_title_left ?? '',
          sub_title_right: state.extras?.sub_title_right ?? '',
          desktop_image: state.imageUrl ?? '//content.pizzahut.com.au/f/49069/500x280/262941c4c5/placeholder-card-bg.jpg',

          // Boolean values
          ...(state.extras?.exclude_homepage && { exclude_homepage: JSON.parse(state.extras?.exclude_homepage) }),
          ...(state.extras?.image_only && { image_only: JSON.parse(state.extras?.image_only) }),
          ...(state.extras?.subtitle_separator && { subtitle_separator: JSON.parse(state.extras?.subtitle_separator) }),
          ...(state.extras?.todays_offer && { todays_offer: JSON.parse(state.extras?.todays_offer) }),
          url: { url: state.url ? state.url : '/offers' }
        }))
        .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    },
    getHpDeals (state) {
      return state.cards
        .filter(state =>
          state.extras?.site_section === 'hp_deals' &&
          state.extras?.card_type === 'offer_card' &&
          !CacheController.isCacheExpired(state.expiresAt)
        )
        .map(state => ({
          ...state,
          // Need to have initial values to prevent error
          price_pickup: state.extras?.price_pickup ?? '',
          price_pickup_strikethrough: state.extras?.price_pickup_strikethrough ?? '',
          price_pickup_title: state.extras?.price_pickup_title ?? '',
          price_delivery: state.extras?.price_delivery ?? '',
          price_delivery_strikethrough: state.extras?.price_delivery_strikethrough ?? '',
          price_delivery_title: state.extras?.price_delivery_title ?? '',
          sub_title_left: state.extras?.sub_title_left ?? '',
          sub_title_right: state.extras?.sub_title_right ?? '',
          desktop_image: state.imageUrl ?? '//content.pizzahut.com.au/f/49069/500x280/262941c4c5/placeholder-card-bg.jpg',

          // Boolean values
          ...(state.extras?.exclude_homepage && { exclude_homepage: JSON.parse(state.extras?.exclude_homepage) }),
          ...(state.extras?.image_only && { image_only: JSON.parse(state.extras?.image_only) }),
          ...(state.extras?.subtitle_separator && { subtitle_separator: JSON.parse(state.extras?.subtitle_separator) }),
          ...(state.extras?.todays_offer && { todays_offer: JSON.parse(state.extras?.todays_offer) }),
          url: { url: state.url ? state.url : '/offers' }
        }))
        .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    },
    getOffersExclusiveDeals (state) {
      return state.cards
        .filter(state =>
          state.extras?.site_section === 'op_exclusive_deals' &&
          state.extras?.card_type === 'offer_card' &&
          !CacheController.isCacheExpired(state.expiresAt)
        )
        .map(state => ({
          ...state,
          // Need to have initial values to prevent error
          price_pickup: state.extras?.price_pickup ?? '',
          price_pickup_strikethrough: state.extras?.price_pickup_strikethrough ?? '',
          price_pickup_title: state.extras?.price_pickup_title ?? '',
          price_delivery: state.extras?.price_delivery ?? '',
          price_delivery_strikethrough: state.extras?.price_delivery_strikethrough ?? '',
          price_delivery_title: state.extras?.price_delivery_title ?? '',
          sub_title_left: state.extras?.sub_title_left ?? '',
          sub_title_right: state.extras?.sub_title_right ?? '',
          desktop_image: state.imageUrl ?? '//content.pizzahut.com.au/f/49069/500x280/262941c4c5/placeholder-card-bg.jpg',

          // Boolean values
          ...(state.extras?.exclude_homepage && { exclude_homepage: JSON.parse(state.extras?.exclude_homepage) }),
          ...(state.extras?.image_only && { image_only: JSON.parse(state.extras?.image_only) }),
          ...(state.extras?.subtitle_separator && { subtitle_separator: JSON.parse(state.extras?.subtitle_separator) }),
          ...(state.extras?.todays_offer && { todays_offer: JSON.parse(state.extras?.todays_offer) }),
          url: { url: state.url ? state.url : '/offers' }
        }))
      .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    },
    getOffersExclusiveProducts (state) {
      return state.cards
        .filter(state =>
            state.extras?.site_section === 'op_exclusive_products' &&
            state.extras?.card_type === 'product_card' &&
            !CacheController.isCacheExpired(state.expiresAt)
          )
        .map(state => ({ ...state, ...state.extras }))
        .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    },
    getCouponsExclusiveDeals (state) {
      return state.cards
        .filter(state =>
          state.extras?.site_section === 'cp_exclusive_deals' &&
          state.extras?.card_type === 'offer_card' &&
          !CacheController.isCacheExpired(state.expiresAt)
        )
        .map(state => ({
          ...state,
          // Need to have initial values to prevent error
          price_pickup: state.extras?.price_pickup ?? '',
          price_pickup_strikethrough: state.extras?.price_pickup_strikethrough ?? '',
          price_pickup_title: state.extras?.price_pickup_title ?? '',
          price_delivery: state.extras?.price_delivery ?? '',
          price_delivery_strikethrough: state.extras?.price_delivery_strikethrough ?? '',
          price_delivery_title: state.extras?.price_delivery_title ?? '',
          sub_title_left: state.extras?.sub_title_left ?? '',
          sub_title_right: state.extras?.sub_title_right ?? '',
          desktop_image: state.imageUrl ?? '//content.pizzahut.com.au/f/49069/500x280/262941c4c5/placeholder-card-bg.jpg',

          // Boolean values
          ...(state.extras?.exclude_homepage && { exclude_homepage: JSON.parse(state.extras?.exclude_homepage) }),
          ...(state.extras?.image_only && { image_only: JSON.parse(state.extras?.image_only) }),
          ...(state.extras?.subtitle_separator && { subtitle_separator: JSON.parse(state.extras?.subtitle_separator) }),
          ...(state.extras?.todays_offer && { todays_offer: JSON.parse(state.extras?.todays_offer) }),
          url: { url: state.url ? state.url : '/offers' }
        }))
      .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    },
    getCouponsExclusiveProducts (state) {
      return state.cards
        .filter(state =>
          state.extras?.site_section === 'cp_exclusive_products' &&
          state.extras?.card_type === 'product_card' &&
          !CacheController.isCacheExpired(state.expiresAt)
        )
        .map(state => ({ ...state, ...state.extras }))
        .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    },
    getUpsellProducts (state) {
      return state.cards
        .filter(state =>
            state.extras?.site_section === 'cs_cart_upsell' &&
            state.extras?.card_type === 'product_card' &&
            !CacheController.isCacheExpired(state.expiresAt)
          )
        .map(state => ({ ...state, ...state.extras }))
        .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    },
    getCheckoutUpsellProducts (state) {
      return state.cards
        .filter(state =>
            state.extras?.site_section === 'cp_checkout_upsell' &&
            state.extras?.card_type === 'product_card' &&
            !CacheController.isCacheExpired(state.expiresAt)
          )
        .map(state => ({ ...state, ...state.extras }))
        .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    }
  },

  actions: {
    setContentCards (cards) {
      this.cards = Object.freeze(cards)
    }
  }
})
